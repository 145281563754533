import styled from "styled-components";

export const TitleContainer = styled.div`
    display:flex;
    align-items:center;
    width:100%;
    margin-bottom:40px;
    gap:1rem;
    @media (max-width: 1023px) {
  justify-content:center
  }

`

export const Imagen = styled.img`
    width:48px
`

export const Title = styled.h2`
    font-family: Mulish;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 48px */
    color:#0D3564;
    margin:0
`