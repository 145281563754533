import React from 'react';
//styles
import { Container, Title } from './Terms.Styles';
//components
import { ReuseTerms } from '../../components/ReuseTerms/ReuseTerms';

export default function Terms() {
  return (
    <Container>
      <Title>Términos y condiciones</Title>
      <ReuseTerms
        title='AVISO LEGAL'
        term='Al ingresar y utilizar este sitio web, cuyo nombre de dominio es www.ballenamx.com, 
        usted (el usuario) está aceptando los términos y las condiciones abajo descritos y declara
         expresamente su aceptación, utilizando para tal efecto medios electrónicos, en términos de 
         lo dispuesto en el artículo 1803 del Código Civil Federal. Si el usuario no acepta en forma 
         absoluta los presentes términos y condiciones, deberá abstenerse de usar y ver la página 
         www.ballenamx.com como “ballenamx.com” o “ballenamx”. La simple utilización o acceso al 
         sitio web mencionado, o bien a cualquiera de las subpáginas y ligas contenidas en el mismo, 
         le otorga la condición de usuario (en adelante referido como el “usuario” o los “usuarios”)
          e implica la aceptación, plena e incondicional, de todas y cada una de las condiciones 
          generales y particulares incluidas en estos términos de uso en la versión publicada en 
          el momento en que el usuario acceda al sitio web, independientemente de si registra 
          algún dato o no. En cualquier momento se podrán modificar los presentes términos de empleo, 
          siendo exclusiva responsabilidad del usuario asegurarse de tomar conocimiento de tales 
          modificaciones. El usuario solo podrá imprimir y/o copiar cualquier información contenida 
          o publicada en ballenamx.com exclusivamente para su empleo personal, no comercial.
           En caso de ser persona moral, se estará a lo dispuesto por el artículo 148, fracción IV 
           de la Ley Federal del Derecho de Autor. La reimpresión, publicación, distribución, 
           asignación, sublicencia, venta, reproducción electrónica o por cualquier otro medio de 
           cualquier información, documento o gráfico de o que aparezca en ballenamx.com en todo o
            en parte, para cualquier empleo distinto al personal, le está expresamente prohibido 
            al usuario. Las informaciones, conceptos y opiniones publicadas en ballenamx.com son 
            propiedad de www.ballenamx.com Ballenamx.com no se hace responsable de las consecuencias
             que se puedan derivar del empleo de la página incluido en la página de internet, 
             en el entendido de que es bajo el propio riesgo y responsabilidad del usuario el empleo 
             y seguimiento a dichos consejos, comentarios, procedimientos, manuales de instalación, 
             diagramas, tips, etc., que se den en los espacios referidos. Ballenamx.com se reserva 
             el derecho de bloquear el acceso o remover, en forma parcial o total, toda información,
              comunicación o material que a su exclusivo juicio pueda resultar: (i) abusivo, 
              difamatorio u obsceno, (ii) fraudulento, artificioso o engañoso, (iii) violatorio de 
              derechos de autor, marcas o cualquier derecho de propiedad intelectual de un tercero,
               (iv) ofensivo o (v) de cualquier forma contravenga lo establecido en estos términos y 
               condiciones. Si el usuario desea obtener mayor información de un tema en específico 
               proveído por ballenamx.com o los proveedores, el usuario deberá consultarlo directamente 
               con cada uno de ellos, según corresponda y/o con un especialista en la materia. 
               No está permitida ninguna acción o uso de dispositivos, robots, software u otro medio 
               tendiente como crawaler para interferir tanto en las actividades y operatoria de ballenamx.com,
                cuentas o bases de datos de ballenamx.com. Cualquier intromisión, tentativa o actividad 
                violatoria o contraria a las leyes de derecho de propiedad intelectual y/o a las prohibiciones 
                estipuladas en este contrato, harán posible a su responsable de las acciones legales pertinentes,
                 y a las sanciones previstas por este acuerdo, así como lo hará responsable de indemnizar los 
                 daños ocasionados. Ballenamx.com.— Refacciones Digitales S de RL de CV:  Av. Tláhuac 128, 
                 Santa Isabela Industrial, Iztapalapa CP 09820 CDMX'
      />

      <ReuseTerms
        title='DISPONIBILIDAD'
        term='Ballenamx.com informa a los usuarios que el número de unidades disponibles en cada uno de 
      los productos para su venta es de carácter estimativo. En ningún caso, ballenamx.com pondrá a 
      la venta de forma intencionada más unidades de las que se tienen en los almacenes. Sin embargo, 
      por el alto grado logístico y por causas difícilmente controlables por ballenamx.com, tales como 
      errores humanos o incidencias en los sistemas informáticos, es posible que la cantidad ofrecida 
      varíe. Para el supuesto caso en que el producto no esté disponible después de haberse realizado 
      el pago, el usuario será informado por teléfono a través de unos de nuestros agentes y un correo 
      electrónico, infamando las causas de la cancelación de la compra y ofreciendo alternativas de 
      solución.'
      />

      <ReuseTerms
        title='ENVÍOS Y ENTREGAS'
        term='Ballenamx.com se compromete a entregar el producto en perfecto estado en la dirección 
      que el usuario nos proporcione al momento de registrar la compra. Con el fin de optimizar la 
      entrega, agradecemos al usuario, indique una dirección en la cual la compra pueda ser entregada
       dentro de un horario laboral habitual, es decir, entre las 9:00 h y las 21:00 h, de lunes a 
       viernes y sábados de 9:00 h a 13:00 h (dependiendo el código postal). Debido a la gran diversidad 
       de productos que vendemos en nuestro portal, es probable que una compra se divida en varias sub 
       compras, es decir, el cliente podrá recibir uno o más paquetes de diferentes centros de distribución 
       hasta completar su compra. En caso de ser así, será informado por correo electrónico y se proporcionará 
       una guía de seguimiento para cada paquete. Tome en cuenta que las refacciones y autopartes varían 
       de diferentes tamaños y suelen ser más frágiles de lo habitual, en conjunto con nuestros aliados 
       logísticos hacemos el mejor esfuerzo para entregar el producto perfecto estado en tiempo y forma. 
       El plazo de entrega para servicios terrestre es de dos a cinco días hábiles. Tome en cuenta que 
       existen localidades remotas conocidas como reexpedición donde las paqueterías solo entran en días 
       específicos. Estos plazos son promedio, por lo tanto, son solo una estimación y no una garantía de 
       cumplimiento, por ello es posible que los plazos varíen por razones logísticas o de caso fortuito o 
       fuerza mayor.  Las entregas se consideran efectuadas a partir del momento en el cual la empresa de 
       transportes pone el producto en el domicilio del usuario y no necesariamente entregado a la persona 
       que realizó la compra. Este proceso se materializa a través del sistema de control utilizado por la 
       compañía de transportes. En el mismo correo electrónico en el que se informe al usuario su confirmación 
       de compra, será proporcionado su código de rastreo junto con el tipo de servicio, compañía 
       prestadora del servicio. Si al momento de la entrega el usuario se encuentra ausente, el transportista 
       dejará un comprobante indicando cómo proceder para concretar la nueva entrega. Por lo general, se ejecuta 
       un nuevo intento de entrega al día siguiente. Al tercer intento lo mandan a una sucursal para que el cliente 
       lo recoja en un lapso no mayor a 10 días hábiles. Después de ese lapso, si el paquete no es reclamado por el 
       usuario, será regresado a nuestro almacén y generaremos un reembolso. Si el motivo por el cual el paquete no ha 
       sido entregado es por extravío, la compañía de transportes iniciará una investigación, en estos casos haremos 
       un reembolso. Diligencia de entrega. El usuario bajo su propia y exclusiva responsabilidad deberá de comprobar 
       el buen estado del paquete ante el transportista por cuenta de ballenamx.com, de existir alguna anomalía en paquete 
       sugerimos abrirlo y hacer una revisión física para corroborar que el producto (s) se encuentra en buen estado, en 
       caso de presentar algún daño deberá de rechazar el paquete. En este caso se realizará un reembolso. Si una vez revisado 
       el producto, el usuario detectara cualquier incidencia como golpes, rotura, indicios de haber sido abierto o cualquier 
       desperfecto causado por el envío y el transportista ya no está presente, es necesario ingresar a “mis compras” o “mi cuenta” 
       y ejecutar una devolución (motivo – producto dañado por paquetería).'
      />
      <ReuseTerms
        title='GARANTÍAS'
        term='Ballenamx.com garantiza el buen funcionamiento de todos sus productos. Cada producto especifica su tiempo de garantía 
      correspondiente en la sección de especificaciones. En todos los casos, es decir, todas las ventas de productos, se incluye 
      nota de remisión y/o factura. Sin excepción de persona y género deberá de presentarse dicha nota o factura para cualquier 
      aclaración o reclamación. La nota de venta o factura no deberá de presentar daño y tendrá que ser legible. Algunos productos 
      incluyen número de serie, el cual no deberá ser violado y tendrá que coincidir con su nota o factura, de lo contrario no 
      procederá la garantía. Si la garantía proviene del interior de la República, Los envíos de ida y vuelta serán absorbidos por 
      ballenamx.com de su domicilio a nuestras instalaciones, el envío de regreso correrá por nuestra cuenta siempre y cuando la 
      garantía proceda, de lo contrario el cliente tendrá que absorber $100.00 Mxn por concepto de reenvío, si el error es de nosotros, 
      los envíos de ida y vuelta serán absorbidos por másrefacciones.mx. Para que una garantía pueda proceder, el producto no deberá 
      presentar daños externos e internos por una mala instalación como: rastros de agua, humedad, aceite, grasa, cortes o mutilaciones, 
      cables trozados, empalmes, rastros de adhesivos, modificaciones u otro tipo de daño causado por mal uso y/o Instalación.'
      />

      <ReuseTerms
        title='REEMBOLSOS'
        term='Si la compra consiste en un solo producto, el reembolso será total de forma automática, pero si la compra consiste en más de 
      dos productos, se contactará al usuario para ofrecer alternativas. Si por alguna razón no se contacta al usuario, se emitirá un 
      reembolso total después de 48 horas.'
      />

      <ReuseTerms
        title='Pago con Tarjeta de Crédito'
        term='Todos los pagos recibidos por medio de tarjeta de crédito a través de nuestro motor de pago serán reembolsados por el mismo 
      medio y será reflejado en la tarjeta del usuario.'
      />

      <ReuseTerms
        title='Mercado Pago'
        term='Mercado Pago será a través de la cuenta que el usuario creó al momento de realizar el pago. En caso de ejecutar un reembolso será 
      a la misma cuenta del usuario siempre y cuando el pago haya sido por tarjeta de crédito o débito. En el caso de pagos en efectivo en OXXO, 
      7-ELEVEN, TELECOMM, BBVA, CITIBANAMEX, SANTANDER y TRANSFERENCIA BANCARIA, el dinero será reembolsado a su cuenta de Mercado Pago, para que 
      el usuario pueda disponer del dinero, es necesario hacer un retiro dentro de la plataforma de Mercado Pago hacia su cuenta de banco.'
      />

      <ReuseTerms
        title='PayPal'
        term='Todos los pagos recibidos por medio de PayPal serán a través de la cuenta que el usuario creo al momento de realizar el pago y con 
      tarjeta que asocio a su cuenta. En caso de ejecutar un reembolso será a la misma cuenta del usuario siempre y cuando el usuario NO haya 
      utilizado un cupón de descuento, en caso contrario se hará un envío de dinero a favor de la cuenta de usuario en PayPal, para que el usuario 
      pueda disponer del dinero es necesario hacer un retiro dentro de la plataforma de PayPal hacia su cuenta de banco.'
      />

      <ReuseTerms title='Política de Devolución' />
      <ReuseTerms
        title='COMPRAS'
        term='Ballenamx.com es una plataforma e-commerce en donde el usuario puede comprar y pagar cualquier producto o servicio que se ofrezca por 
      medio de distintas secciones. Ser un usuario ballenamx.com, tiene grandes beneficios como mayor rapidez en las compras, acceso a promociones 
      especiales, así como ver un historial de compras. Es de suma importancia y es responsabilidad del usuario leer las características de los productos.'
      />
      <ReuseTerms
        title='PAGOS'
        term='Ponemos a su disposición distintos medios de pago.'
      />

      <ReuseTerms
        title='PayPal:'
        term='Tarjeta de Crédito: Visa, Mastercard, American Express Y Discover 
     Tarjeta de Débito: Banamex, Banco Azteca, BBVA, Banorte, HSBC, Inbursa, Santander'
      />

      <ReuseTerms
        title='Mercado Pago:'
        term={`Tarjeta de Crédito: Visa, Mastercard Y American Express 
     Tarjeta de Débito: Banamex, IXE, BBVA, Banorte, HSBC, Santander 
     Efectivo: OXXO, 7-ELEVEN, TELECOMM, BBVA, CITIBANAMEX Y SANTANDER 
     Transferencia: BBVA, CITIBANAMEX, SANTANDER, BANORTE Y HSBC 
     Tarjeta Mercado Pago`}
      />

      <ReuseTerms
        title='Pagos en Efectivo'
        term={`Banamex 
     Bancomer 
     OXXO`}
      />

      <ReuseTerms
        title='FACTURACIÓN'
        term={`Todos los precios publicados son netos e incluyen IVA, Para solicitar una factura es necesario que la compra esté pagada y el usuario tendrá que ingresar a “mis compras” para introducir sus datos fiscales.  En caso de que alguno de los datos estén incorrectos, le pedimos se comunique con nosotros para poder solicitar el cambio de sus datos de facturación al teléfono 01 (55)  XXXX XXXX (lada sin costo) de lunes a viernes de 9 a 18 horas.  
     Para solicitar una factura deberá realizarse durante el mes vigente de su compra. Si la compra se ejecutó el último día del mes esta podrá ser solicitada hasta 2 días posteriores al mes anterior, En caso de caer en días no laborables la factura saldrá con fecha del mes siguiente. 
     *El costo del envío también se factura.`}
      />
    </Container>
  );
}
