/*eslint-disable no-unused-vars */
import React, { useState } from "react";
//styles
import {
  MainContainer,
  ButtonSeg,
  Container,
  Imagen,
  InputContaier,
  InputLabel,
  InputSeguridad,
  Title,
  TitleContainer,
  ButtonCambiar,
  ButtonContainer,
  IconUserEye,
  WarningContainer,
  WarningText,
  ContainerContrasena,
  ImagenArrow,
} from "./Seguridad.styles";
import { url } from "../../../api";
//assets
const seguridad = `${url} seguridad.svg`;
const EyeInput = `${url} EyeInput.svg`;
const eyeClosed = `${url} eyeClosed.svg`;
const UpArrow = `${url} UpArrow.svg`;
const downArrow = `${url} downArrow.svg`;
export default function Seguridad() {
  const isMobile = window.innerWidth <= 768;

  const [eyeOpen, setEyeOpen] = useState(false);
  const [eyeOpenConfirm, setEyeOpenConfirm] = useState(false);
  const [hideArrow, setHideArrow] = useState(true);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [errorNewPassword, setErrorNewPassword] = useState(false);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);

  const validatePassword = (password) => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/;
    return regex.test(password);
  };

  const handleNewPasswordChange = (e) => {
    const inputValue = e.target.value;
    setNewPassword(inputValue);

    if (!validatePassword(inputValue)) {
      setErrorNewPassword(false);
    } else {
      setErrorNewPassword(true);
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const inputValue = e.target.value;
    setConfirmPassword(inputValue);

    if (inputValue !== newPassword) {
      setErrorConfirmPassword(false);
    } else {
      setErrorConfirmPassword(true);
    }
  };

  return (
    <MainContainer>
      <TitleContainer>
        <Imagen src={seguridad} alt="" />
        <Title>Seguridad</Title>
        <ImagenArrow
          src={hideArrow ? UpArrow : downArrow}
          alt=""
          onClick={() => setHideArrow(!hideArrow)}
        />
      </TitleContainer>

      {hideArrow ? (
        <>
          <ContainerContrasena>
            <Container>
              <InputContaier>
                <InputLabel>Constraseña temporal</InputLabel>
                <InputSeguridad placeholder="Ingresa tu código" />
              </InputContaier>

              {isMobile ? (
                <InputContaier>
                  <ButtonSeg>Validar código</ButtonSeg>
                </InputContaier>
              ) : null}

              {isMobile ? null : (
                <InputContaier>
                  <InputLabel>Nueva contraseña</InputLabel>
                  <InputSeguridad
                    onChange={handleNewPasswordChange}
                    type={eyeOpen ? "text" : "password"}
                    placeholder="Nueva contraseña"
                  />
                  <IconUserEye
                    src={eyeOpen ? EyeInput : eyeClosed}
                    alt="Email Icon"
                    onClick={() => setEyeOpen(!eyeOpen)}
                  />
                </InputContaier>
              )}
            </Container>
            <Container>
              {isMobile ? null : (
                <InputContaier>
                  <ButtonSeg>Validar código</ButtonSeg>
                </InputContaier>
              )}

              {isMobile ? (
                <InputContaier>
                  <InputLabel>Nueva contraseña</InputLabel>
                  <InputSeguridad
                    onChange={handleNewPasswordChange}
                    type={eyeOpen ? "text" : "password"}
                    placeholder="Nueva contraseña"
                  />
                  <IconUserEye
                    src={eyeOpen ? EyeInput : eyeClosed}
                    alt="Email Icon"
                    onClick={() => setEyeOpen(!eyeOpen)}
                  />
                </InputContaier>
              ) : null}

              <InputContaier>
                <InputLabel>Confirma la contraseña</InputLabel>
                <InputSeguridad
                  onChange={handleConfirmPasswordChange}
                  type={eyeOpenConfirm ? "text" : "password"}
                  placeholder="Nueva contraseña"
                />
                <IconUserEye
                  src={eyeOpenConfirm ? EyeInput : eyeClosed}
                  alt="Email Icon"
                  onClick={() => setEyeOpenConfirm(!eyeOpenConfirm)}
                />
              </InputContaier>
            </Container>
          </ContainerContrasena>
          <WarningContainer>
            <input type="radio" checked={errorNewPassword} />
            <WarningText>
              Tu contraseña debe tener al menos 8 caracteres.
            </WarningText>
          </WarningContainer>
          <WarningContainer>
            <input type="radio" checked={errorConfirmPassword} />
            <WarningText>
              Asegúrate de incluir una combinación de letras mayúsculas,
              minúsculas, números y caracteres especiales en tu contraseña.
            </WarningText>
          </WarningContainer>
          <ButtonContainer>
            <ButtonCambiar
              hasError={errorNewPassword && errorConfirmPassword}
              disabled={errorNewPassword && errorConfirmPassword ? false : true}
            >
              Cambiar contraseña
            </ButtonCambiar>
          </ButtonContainer>
        </>
      ) : null}
    </MainContainer>
  );
}
