/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import Nav from '../components/common/nav';
import Footer from '../components/common/footer';
import {
  Background,
  Flex,
  GeneralContainer,
  Text,
  StyledPassword,
  Button,
  StyledInputIcon,
} from '../styles/common.styles';
import {
  CheckboxContainer,
  ContainerManter,
  Mantener,
  Olvide,
} from '../components/common/LogInV2/LogInForm.jsx/LogInForm.styles';
import { LOGIN } from '../../graphql/Mutation';
import { useMutation } from '@apollo/client';
import { url } from '../api';
import Loader from '../components/common/Loader';
const Bg = `${url}JPG/createAccountbg.jpg`;
const BgMobile = `${url}JPG/createAccountbgmobile.jpg`;
const UserIcon = `${url}IconUser.svg`;

// import Modal1 from '../components/PasswordRecoveryModals/Modal1';
// import Modal2 from '../components/PasswordRecoveryModals/Modal2';

const AdminLogin = () => {
  const history = useHistory();
  //! mock para loader-----
  const [loading, setLoading] = useState(false);
  // const [validEmail, setValidEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disabledLoginButton, setDisabledLoginButton] = useState(true)
  // const [openModal1, setOpenModal1] = useState(false);
  // const [openModal2, setOpenModal2] = useState(false);

  const fetchData = async ({ success }) => {
    setLoading(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Simula una carga de 2 segundos
    } catch (error) {
      console.error('Error al cargar los datos:', error);
    } finally {
      setLoading(false);
      success();
    }
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // Función para actualizar el estado con el ancho de pantalla actual
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };
  // Agregar el evento 'resize' cuando el componente se monta
  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);

    // Eliminar el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  const emailValidation = (e) => {
    const value = e.target.value;
    const regex = /^[^\s@]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (regex.test(value) === true) {
      // setValidEmail(true);
      handleEmailChange(e)
    } else {
      // setValidEmail(false);
    }
  };

  // const handleContinue = () => {
  //   fetchData({
  //     success: () => {
  //       setOpenModal1(false);
  //       setOpenModal2(true);
  //     },
  //   });
  // };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (event.target.value != '' && password != '') {
      setDisabledLoginButton(false)
    } else {
      setDisabledLoginButton(true)
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    if (event.target.value != '' && email != '') {
      setDisabledLoginButton(false)
    } else {
      setDisabledLoginButton(true)
    }
  };

  const [loginMutation] = useMutation(LOGIN, {
    onCompleted: (data) => {
      setLoading(false);
      if (data.login.ok === true) {
          sessionStorage.setItem("token", data.login.token);
          sessionStorage.setItem("refreshToken", data.login.refreshToken);
          history.push("/admin/dashboard");
      } else {
        setWrongCredentials("Credenciales incorrectas. Favor de revisarlas");
      }
    },
    onError: (error) => {
      alert(`Error en peticion: ${error.message.split("GraphQL error: ")[1]}`);
    },
  });

  const login = () => {
    setLoading(true);
    setDisabledLoginButton(true)
    if (!disabledLoginButton) {
      loginMutation({
        variables: {
          email: email,
          password: password,
        },
      });
    }
  }

  return (
    <>
      {/* <Modal1
        open={openModal1}
        handleContinue={() => handleContinue()}
        close={() => setOpenModal1(false)}
      />
      <Modal2 open={openModal2} /> */}
      <Loader open={loading} />
      <Nav />
      <GeneralContainer style={{ alignItems: 'center', width: '100%' }}>
        {/*  Banner start */}
        <>
          <Flex
            width='100%'
            maxHeight='208px'
            height='208px'
            padding='40px 80px'
            align='center'
            justify='center'
            gap='32px'
            direction='column'
            style={{
              position: 'relative',
            }}
            mobile='height:fit-content; padding: 80px 24px; gap: 32px; max-height:100%;'
          >
            <Background
              style={{
                height: '100%',
                width: '100%',
              }}
            >
              <img
                src={screenWidth <= 1023 ? BgMobile : Bg}
                style={{ width: '100%', height: '100%' }}
              />
            </Background>
            <Flex
              align='center'
              direction='row'
              mobile='flex-direction:column;'
            >
              <Text
                size='48px'
                weight='900'
                color='#32B7D7'
                textAlign='center'
                mobile='font-size:40px;'
              >
                Inicia sesión&nbsp;
              </Text>
              <Text
                size='48px'
                weight='900'
                color='#ffffff'
                textAlign='center'
                mobile='font-size:40px;'
              >
                en Ballena
              </Text>
            </Flex>
            <Text size='24px' weight='800' color='#ffffff' textAlign='center'>
              Por favor, ingresa los datos que se te piden a continuación para
              ingresar a tu cuenta.
            </Text>
          </Flex>
        </>
        {/*  Banner end */}
        {/*  Form start */}
        <Flex
          direction='column'
          gap='24px'
          width='100%'
          align='center'
          padding='3rem 2rem'
          maxWidth='476px'
        >
          <Flex direction='column' gap='4px' width='100%' align='flex-start'>
            <Text family='Inter' size='12px' weight='400'>
              Correo electrónico
            </Text>
            <StyledInputIcon
              icon={UserIcon}
              handleChange={(e) => emailValidation(e)}
            />
          </Flex>
          <Flex direction='column' gap='4px' width='100%' align='flex-start'>
            <Text family='Inter' size='12px' weight='400'>
              Ingresa de nuevo la nueva contraseña
            </Text>
            <StyledPassword
              versiontwo
              placeholder={'Ingresa de nuevo tu nueva contraseña'}
              handleChange={(e) => handlePasswordChange(e)}
            />
          </Flex>
          <ContainerManter>
            <CheckboxContainer>
              <input type='checkbox' />
              <Mantener>Mantener sesión iniciada</Mantener>
            </CheckboxContainer>
            <Olvide
            //TODO: Modificar modal1 (ocurre error - back)
            // onClick={() => setOpenModal1(true)}
            >
              Olvidé mi contraseña
            </Olvide>
          </ContainerManter>
          <Button
            style={{ marginTop: '1rem' }}
            width='194px'
            onClick={() =>login()
            }
            disabled={disabledLoginButton}
          >
            Iniciar sesión
          </Button>
        </Flex>

        {/*  Form end */}
      </GeneralContainer>
      <Footer />
    </>
  );
};

export default AdminLogin;
