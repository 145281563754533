import React from 'react'
//styles
import { MainContainer,ButtonDelete,Container,Imagen,OptionCotainer,Title,TitleContainer, OptionText } from './MarcasRegistradas.styles'
import { url } from '../../api'
//assets
const Empresas = `${url}Empresas.svg`
export default function MarcasRegistradas({opciones, eliminarOpcion, names}) {
  return (
    <MainContainer>
        <TitleContainer>
            <Imagen src={Empresas} alt=''/>
            <Title>Marcas registradas</Title>
        </TitleContainer>
         
      {opciones.map((opcion, index) => (
        <Container key={index}>
         <OptionCotainer><OptionText>{names[index]}</OptionText></OptionCotainer> 
          <ButtonDelete onClick={() => eliminarOpcion(index)}>-</ButtonDelete>
        </Container>
      ))}
      
    </MainContainer>
  )
}
