/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { GET_MARKETPLACE_USER } from '../../../graphql/Query';
import { SEND_EMAIL_CODE_PASSWORD } from '../../../graphql/Mutation';
import { Modal } from '@mui/material';
import {
  Button,
  Flex,
  StyledInputIcon,
  Text,
  Layout,
  ModalBox,
} from '../../styles/common.styles';
import { url } from '../../api';
const IconUser = `${url}IconUser.svg`;

const Modal1 = ({ open, handleContinue, close }) => {
  const [validEmail, setValidEmail] = useState(false);
  const [email, setEmail] = useState('');

  const handleValidation = (e) => {
    setEmail(e.target.value);
    const value = e.target.value;
    const regex = /^[^\s@]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (regex.test(value) === true) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };

  const handleClick = () => {
    handleContinue();
    sendEmail({
      variables: {
        email: email,
      },
    });
    getUserMktp({
      variables: {
          email,
          },
      });
  };


  const [sendEmail] = useMutation(SEND_EMAIL_CODE_PASSWORD, {
    onCompleted: (data) => {
      sessionStorage.setItem('Email', email);
    },
    onError: (error) => {
      alert(`Error en peticion: ${error.message.split('GraphQL error: ')[1]}`);
    },
  });

  const [getUserMktp] = useLazyQuery(GET_MARKETPLACE_USER, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      console.log('getUserMarketplace', data, data.getUserMarketplace.cat_user_marketplace_id)
      const idUser = data.getUserMarketplace.cat_user_marketplace_id;
      sessionStorage.setItem('Id', idUser);
      sessionStorage.setItem('IdUserMKP', idUser);
    },
    onError(err) {
    },
  });

  useEffect(() => {
    setValidEmail(false);
    return () => {
      // limpieza del efecto
    };
  }, []);
  return (
    <>
      <Modal open={open}>
        <Layout>
          <ModalBox>
            <Text size='32px' weight='800' color='#0D3564'>
              ¿Olvidaste tu contraseña?
            </Text>
            <Flex direction='column' gap='16px' width='100%'>
              <Text
                family='Inter'
                size='16px'
                weight='400'
                textAlign='center'
                color='#14181A'
              >
                Para ayudarte a restablecertu contraseña,{' '}
                <span style={{ fontWeight: '700', fontSize: '16px' }}>
                  necesitamos que nos proporciones tu dirección de correo
                  electrónico asociada a tu cuenta.
                </span>
                <br />
                <br /> Ingresa tu correo electrónico en el campo a continuación
                y te enviaremos las instrucciones para recuperar el acceso a tu
                cuenta.
              </Text>
              <Flex
                direction='column'
                gap='4px'
                width='100%'
                align='flex-start'
              >
                <Text
                  family='Inter'
                  size='16px'
                  weight='700'
                  textAlign='center'
                  color='#14181A'
                >
                  Correo electrónico registrado en Ballena:
                </Text>
                <StyledInputIcon
                  icon={IconUser}
                  handleChange={(e) => handleValidation(e)}
                />
              </Flex>
            </Flex>
            <Flex
              direction='row'
              gap='24px'
              width='100%'
              maxWidth='412px'
              mobile='flex-direction: column;'
              justify='center'
            >
              <Button
                width='100%'
                style={{ minWidth: '217px' }}
                disabled={!validEmail}
                onClick={handleClick}
                /* onClick={() => {
                  handleContinue();
                  handleClick();
                }} */
              >
                Recuperar contraseña
              </Button>
              <Button
                width='100%'
                style={{ minWidth: '217px' }}
                secondary
                onClick={close}
              >
                Cancelar
              </Button>
            </Flex>
          </ModalBox>
        </Layout>
      </Modal>
    </>
  );
};

export default Modal1;
