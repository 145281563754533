import React, { useState } from 'react'

//styles
import { MainContainer, Container, Imagen, InputConfig, InputContainer, InputLabel, SubContainer, Title, TitleContainer, ErrorMsg, ImagenArrow } from './DatosGeneralesConfig.style'
import { url } from '../../../api'
//assets
const iconUser = `${url}IconUser.svg`
const UpArrow = `${url}UpArrow.svg`
const downArrow = `${url}downArrow.svg`
export default function DatosGeneralesConfig() {

  const [hideArrow, setHideArrow] = useState(true)

    const [formData, setFormData] = useState({
        primerNombre: '',
        apellidoPaterno: '',
        segundoNombre: '',
        apellidoMaterno: '',
      });

      const [errors, setErrors] = useState({
        primerNombre: '',
        apellidoPaterno: '',
        segundoNombre: '',
        apellidoMaterno: '',
      });

      const handleInputChange = (fieldName, value) => {
        setFormData({
          ...formData,
          [fieldName]: value,
        });
      };

      const validateInput = (fieldName, value) => {
        if (/[^A-Za-zÀ-ÖØ-öø-ÿ\s]/.test(value)) {
          setErrors({
            ...errors,
            [fieldName]: 'Solo debe contener letras',
          });
        } else {
          setErrors({
            ...errors,
            [fieldName]: '',
          });
        }
      };
    


  
  return (
    <MainContainer>
        <TitleContainer>
            <Imagen src={iconUser} alt=''/>
            <Title>Datos generales</Title>
            <ImagenArrow  style={{justifySelf:'flex-end'}}src={hideArrow ? UpArrow : downArrow} alt='' onClick={()=>setHideArrow(!hideArrow)}/>
        </TitleContainer>

       {hideArrow ? 
       <SubContainer>
       <Container>
           <InputContainer>
               <InputLabel>Primer nombre</InputLabel>
               <InputConfig
               type="text"
               placeholder="Primer nombre"
               value={formData.primerNombre}
               onChange={(e) => {
               const inputValue = e.target.value;
               handleInputChange('primerNombre', inputValue);
               validateInput('primerNombre', inputValue);
               }}/>
               {errors.primerNombre && <ErrorMsg>{errors.primerNombre}</ErrorMsg>}
           </InputContainer>
           <InputContainer>
               <InputLabel>Apellido paterno</InputLabel>
               <InputConfig 
                type="text"
                placeholder="Apellido paterno"
                value={formData.apellidoPaterno}
                onChange={(e) => {
                const inputValue = e.target.value;
                handleInputChange('apellidoPaterno', inputValue);
                validateInput('apellidoPaterno', inputValue);
                }}/>
                {errors.apellidoPaterno && <ErrorMsg>{errors.apellidoPaterno}</ErrorMsg>}
           </InputContainer>
       </Container>

       <Container>
           <InputContainer>
               <InputLabel>Segundo nombre</InputLabel>
               <InputConfig 
                type="text"
                placeholder="Segundo nombre"
                value={formData.segundoNombre}
                onChange={(e) => {
                const inputValue = e.target.value;
                handleInputChange('segundoNombre', inputValue);
                validateInput('segundoNombre', inputValue);
                }}/>
                {errors.segundoNombre && <ErrorMsg>{errors.segundoNombre}</ErrorMsg>}
           </InputContainer>
           <InputContainer>
               <InputLabel>Apellido materno</InputLabel>
               <InputConfig 
                type="text"
                placeholder="Apellido materno"
                value={formData.apellidoMaterno}
                onChange={(e) => {
                const inputValue = e.target.value;
                handleInputChange('apellidoMaterno', inputValue);
                validateInput('apellidoMaterno', inputValue);
                }}/>
                {errors.apellidoMaterno && <ErrorMsg>{errors.apellidoMaterno}</ErrorMsg>}
           </InputContainer>
       </Container>
       
   </SubContainer>

       : null}
        
     
    </MainContainer>
  )
}
