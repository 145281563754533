import React, { useEffect, useState } from 'react';
import { url } from '../../../api';
//styles
import {
  MainContainer,
  Imagen,
  ImagenContainer,
  LogoImg,
} from './NavAltaUsuario.styles';
//assets
const logo = `${url}BallenaLogoLarge.svg`;
// const question = `${url}question.svg`;
// const notification = `${url}notification.svg`;
// const userIcon = `${url}IconUser.svg`;
const menuIcon = `${url}MenuIcon.svg`;
export default function NavAltaUsuario({ setOpenNav }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Función que se ejecuta cuando cambia el ancho de la ventana
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Agregar el evento de cambio de tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Limpieza del evento al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <MainContainer>
      <div>
        <LogoImg src={logo} alt='' />
      </div>
      <ImagenContainer>
        {windowWidth >= 1440 ? (
          <>
            {/* <Imagen src={question} alt='' />
            <Imagen src={userIcon} alt='' />
            <Imagen src={notification} alt='' /> */}
          </>
        ) : (
          <Imagen src={menuIcon} alt='' onClick={setOpenNav} />
        )}
      </ImagenContainer>
    </MainContainer>
  );
}
