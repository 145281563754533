/* eslint-disable */
import { Modal } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { url } from '../../api';
import useShoppingCart from '../../../hooks/useShoppingCart';
const warning = `${url}warnigSign.svg`;

const ModalDelete = ({ open, setOpen, single, id, box, updatePrice, all }) => {
  const { deleteItem, deleteBox, deleteAll } = useShoppingCart();
  const handleContinue = () => {
    if (box) {
      deleteBox(parseFloat(id));
      // updatePrice();
    }
    if (single) {
      deleteItem(id);
    }
    if (all) {
      deleteAll();
    }

    setOpen(false);
  };
  const closeModal = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal open={open}>
        <Layout>
          <ModalContent>
            <TitleContainer>
              <WarningSign alt='' src={warning} />
              <Title>
                {single
                  ? 'Eliminar producto'
                  : box
                  ? 'Eliminar paquete'
                  : 'Eliminar todos'}
              </Title>
            </TitleContainer>
            <Subtitle>
              {single
                ? '¿Estás seguro que deseas eliminar el producto de tu carrito?'
                : box
                ? '¿Estás seguro que deseas eliminar este paquete de tu carrito?'
                : '¿Estás seguro que deseas eliminar todos los paquetes de tu carrito?'}
            </Subtitle>

            {/* <Subtitle>
              <strong>¿Deseas continuar con esta modificación?</strong>
            </Subtitle> */}
            <ButtonContainer>
              <ButtonEliminar onClick={handleContinue}>Eliminar</ButtonEliminar>
              <CancelarButton onClick={closeModal}>Cancelar</CancelarButton>
            </ButtonContainer>
          </ModalContent>
        </Layout>
      </Modal>
    </>
  );
};

export default ModalDelete;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 40px;
  box-shadow: 0px 8px 24px 0px rgba(0, 32, 74, 0.4);
  gap: 20px;
  width: 100%;
  max-width: 680px;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;

const WarningSign = styled.img`
  width: 64px;
`;

const Title = styled.h2`
  font-family: Mulish;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 38.4px */
  color: #0d3564;
  margin: 0;
  text-align: center;
`;

const Subtitle = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  color: #14181a;
  text-align: center;
`;

const ButtonEliminar = styled.button`
  display: flex;
  width: 194px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background-color: #0d3564;
  border-style: solid;
  border-width: 1px;
  border-color: #0d3564;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  cursor: pointer;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const CancelarButton = styled.button`
  display: flex;
  width: 194px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #0d3564;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #0d3564;
  cursor: pointer;
  background-color: #ffffff;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
`;

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-wrap: wrap;
  width: 100%;
`;

const SelectorLabel = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

const StyledSelector = styled.select`
  width: 100%;
  height: 48px;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid #b9cad0;
  background: #ffffff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath stroke='%230E3665' stroke-linecap='round' stroke-linejoin='round' d='M6 6l4 4 4-4'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
  background-size: 40px;
`;
