/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { url } from '../../../api';
//assets

//styles
import { MainContainer, Image, Title, ButtonRegresar } from './Unauthorized.styles'
//librerias
import { useHistory } from 'react-router-dom';
const Imagen = `${url}Unautorized.svg`
export default function Unauthorized() {
  const history = useHistory();
  return (
    <MainContainer>
      <Title>¡Algo salió mal!</Title>
      <Image src={Imagen}/>
      <ButtonRegresar onClick={() => history.push('/store')}>Regresar al Inicio</ButtonRegresar>
    </MainContainer>
  )
}
