import styled from 'styled-components';

export const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Semi-transparent black background for the backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
  backdrop-filter: blur(5px); /* Apply a blur effect to the background */
  padding: 1rem;
`;

export const MainContainer = styled.div`
  width: 680px;
  max-height: 90vh;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--neutral-white, #fff);
  box-shadow: 0px 8px 24px 0px rgba(0, 32, 74, 0.4);
  z-index: 1001; /* Ensure it's above the backdrop */
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  height: auto;
  padding: 32px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--primaryocean, #0e79b4);
`;

export const Caminon = styled.img`
  width: 35px;
`;

export const Title = styled.h2`
  color: var(--neutral-white, #fff);
  text-align: left;
  /* COMMON/Title */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 21.6px */
`;
export const ColumnContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
  // grid-template-columns: auto auto auto auto;
  // align-items: center;
  ::-webkit-scrollbar {
    width: 16px; /* Anchura de la barra de desplazamiento en navegadores Webkit */
  }

  ::-webkit-scrollbar-thumb:vertical {
    background-color: #b9cad0; /* Barra translúcida */
    border-radius: 16px; /* Bordes redondeados */
    height: 4px;
    margin: 4px;
    background-clip: content-box;
    border: 5px solid transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 16px;
  }
`;

export const GridTitle = styled.p`
  color: var(--primaryocean, #0e79b4);
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
`;

export const ContaierPaqueteria = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  width: fit-content;
`;

export const PaqueteriaName = styled.p`
  color: var(--primarydeep, #0d3564);

  /* COMMON/Button */
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`;
export const Service = styled.div`
  color: var(--primarydeep, #0d3564);
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;
export const Price = styled.p`
  color: var(--primarycaribe, #32b7d7);
  text-align: left;

  /* COMMON/Button */
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  @media (min-width: 1024px) {
    text-align: right;
  }
`;

export const Days = styled.p`
  color: var(--primarydeep, #0d3564);
  text-align: left;

  /* COMMON/Body_Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  margin: 0;
  @media (min-width: 1024px) {
    text-align: right;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 32px;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const ButtonCancelar = styled.button`
  display: flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-color: #0d3564;
  background-color: #fff;
  border-width: 1px;
  border-radius: 8px;
  border-style: solid;
  color: var(--primarydeep, #0d3564);
  /* COMMON/Button */
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

export const ButtonConfirmar = styled.button`
  display: flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--primarydeep, #0d3564);
  color: var(--neutral-white, #fff);
  border-color: #0d3564;
  border-width: 1px;
  border-style: solid;
  /* COMMON/Button */
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;
