import styled from "styled-components";

export const MainContainer = styled.div `
    width:100%;
    height:208px;
    position:relative;
    @media (max-width: 768px) {
    height: 385px;
    margin: 0 auto
  }
`

export const Bgcolor = styled.div`
    width: 100%;
    height:100%;
    background: linear-gradient(270deg, rgba(13, 53, 100, 0.00) 22.43%, #0D3564 58.83%);
    position:absolute;
    top:0;
    left:0;
    @media (max-width: 768px) {
      background: linear-gradient(270deg, rgba(13, 53, 100, 0.00) 14.08%, #0D3564 55.87%);
      flex-shrink: 0;
  }
    
    
`

export const Title = styled.h2`
    font-family: Mulish;
    font-size: 56px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 67.2px */
    padding-left: 78px;
    padding-right: 78px;
    padding-top: 40px;
    text-align:center;
    color:#32B7D7;
    position:relative;
    @media (max-width: 768px) {
        font-size: 48px;
        padding-left: 24px;
    padding-right: 24px;
    padding-top: 80px;
  }
`
export const TitleWhite = styled.span`
    font-family: Mulish;
    font-size: 56px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 67.2px */
    color:#FFF;
    position:relative;
    @media (max-width: 768px) {
        font-size: 48px;
  }
`

export const Subtitle = styled.p`
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
    padding-left: 78px;
    padding-right: 78px;
    padding-top: 32px;
    padding-bottom: 40px;
    text-align:center;
    color:#FFF;
    position:relative;
    @media (max-width: 768px) {
      
        padding-left: 24px;
    padding-right: 24px;
    padding-top: 32px;

  }
`