// import { useState } from "react";
import {
	useDispatch,
	useSelector
} from "react-redux";
// import axios from "axios";
// import { useLazyQuery } from "@apollo/client";
// import { GET_MARKETPLACE_USER } from "../graphql/Query";
// import { useHistory } from 'react-router-dom';
// import { getUserInfo } from "../redux/reducers/user.reducer";
// import { getShoppingCart } from "../redux/reducers/shoppingCart.reducer";
import { useEffect } from "react";
import { useState } from "react";
import { getShipmentSelected } from "../redux/reducers/shipmentSelected.reducer";

export default function useShipmentCart() {
	// const history = useHistory();
	const dispatch = useDispatch();
	const [ShippmentId, setShippmentId] = useState({});
	const [shippmentsAvailables, setShippmentsAvailables] = useState({});

	const stateShoppingItems = useSelector((state) => state.rootReducers.shipmentSelected);

	useEffect(() => {
		
		let parseDataShipment = stateShoppingItems.state ? JSON.parse(stateShoppingItems.state) : []
		let keys = Object.keys(parseDataShipment);
		if (keys.length > 0) {
			setShippmentsAvailables(parseDataShipment)
			setShippmentId(parseDataShipment)
		}

	}, [stateShoppingItems])


	const addShipmentComplete = (shipment) => {
		// let tempItems = [{...shippmentsAvailables}]
		// console.log({tempItems})
		const tempItems = {
			...shipment,
		}
		dispatch(getShipmentSelected(JSON.stringify(tempItems)));
	}
	return {
		ShippmentId,
		shippmentsAvailables,
		addShipmentComplete
	}
}
