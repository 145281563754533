import styled from 'styled-components';

export const GeneralContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  @media (max-width: 1439px) {
    flex-direction: column;
  }
`;

export const InlineCont = styled.div`
  display: flex;
  ${({ styled }) => styled}
`;
