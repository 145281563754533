import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
`;
export const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 48px;
  color: #0d3564;
  display: flex;
  font-family: 'Mulish';
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: start;
  padding-left: 80px;
  @media (max-width: 1023px) {
    padding-left: 0px;
    width: 100%;
    font-size: 2rem;
    text-align: center !important;
    justify-content: center;
    margin: 0;
    padding: 0 0 4rem;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;
