/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../styles/common.styles';
import { Modal } from '@mui/material';

const NoResults = ({ open, setClose }) => {
  return (
    <>
      <Modal open={open}>
        <Layout>
          <ModalContent>
            <Title>¡UPS!</Title>
            <Subtitle>
              Por el momento, no hemos encontrado resultados de búsqueda en el
              Marketplace
            </Subtitle>
            <Button onClick={() => setClose(false)}>Entendido</Button>
          </ModalContent>
        </Layout>
      </Modal>
    </>
  );
};

export default NoResults;

const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 40px;
  box-shadow: 0px 8px 24px 0px rgba(0, 32, 74, 0.4);
  gap: 20px;
  width: 100%;
  max-width: 680px;
  align-items: center;
`;

const Title = styled.h2`
  font-family: Mulish;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 38.4px */
  color: #0d3564;
  margin: 0;
  text-align: center;
`;

const Subtitle = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  color: #14181a;
  text-align: center;
`;
