import Terms from '../../../screens/PoliciesTerms/Terms';
import Footer from '../common/footer';
import Nav from '../common/nav';

/* eslint-disable */
const TyC = () => {
  return (
    <>
      <Nav store />
      <Terms />
      <Footer />
    </>
  );
};

export default TyC;
