import styled from "styled-components";

export const Container = styled.div`
display:flex;
flex-direction:column;
gap:1rem;
padding: 24px 72px 80px 72px;
`
export const Title = styled.div`
font-size:3.5rem;
font-weight: 800;
line-height:67px;
font-family: Mulish;
color:#0D3564;
text-align:center

`



