/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { url } from '../../../api';
import { useHistory } from 'react-router';
//styles
import { MainContainer, Burger, BurgerContainer, BurgerText, ImagenCarrito, ImagenLogo, ImagenUser, LogoContainer, MenuContainer, NameContainer, NameText, ButtonCerrarSesion, ButtonContainer } from './NavLog.styles'
import SellFormModal from '../../MainPage/Modals/SellFormModal';
// import ModalGracias from '../../QuieresComprar/ModalGracias';
import FinishModal from '../../MainPage/Modals/FinishModal';
// import ModalComprarMayoreo from '../../QuieresComprar/ModalComprarMayoreo';
import useUser from '../../../../hooks/useUser';
import useShoppingCart from '../../../../hooks/useShoppingCart';
//assets
const logo = `${url}BallenaLogoLarge.svg`
const carrito = `${url}carrito.svg`
const iconUser = `${url}IconUser.svg`
const iconMenu = `${url}MenuIcon.svg`


export default function NavLog() {
  const { itemsShoppingCart } = useShoppingCart()
  const { clearLocalItems } = useShoppingCart();
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  const history = useHistory();
  const email = sessionStorage.getItem('auxEmail');
  function logOut() {
    clearLocalItems()
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('auxEmail');
    sessionStorage.removeItem('redux_user');
    sessionStorage.removeItem('redux_getPayment');
    sessionStorage.removeItem('idPart');
    sessionStorage.removeItem('refreshToken');
    history.push('/login');
  }

  const { user } = useUser();

  const [countItems, setCountItems] = useState(0);
  useEffect(() => {
    if (itemsShoppingCart) {
      const count = itemsShoppingCart?.reduce((accumulator, currentValue) => accumulator + 1, 0)
      setCountItems(count);
    }
  }, [itemsShoppingCart])

  return (
    <MainContainer>

      <SellFormModal
        setOpen={setOpenModal1}
        open={openModal1}
        finishModal={setOpenModal2}
      />
      <FinishModal open={openModal2} setOpen={setOpenModal2} />
      {/* <LogoContainer>
            <ImagenLogo src={logo} alt=''/>
        </LogoContainer> */}
      <MenuContainer>
        <LogoContainer onClick={() => history.push('/')}>
          <ImagenLogo src={logo} alt='' />
        </LogoContainer>
        <BurgerContainer>
          <Burger src={iconMenu} alt='' />
          <BurgerText onClick={() => history.push('/store')}>Categorías</BurgerText>
        </BurgerContainer>
        <NameContainer onClick={() => history.push('/quienes-somos')}>
          <NameText>¿Quienes somos?</NameText>
        </NameContainer>
        <NameContainer>
          <NameText onClick={() => setOpenModal1(true)}>¿Quieres vender?</NameText>
        </NameContainer>


        {
          email &&
          <NameContainer>
            <ImagenUser src={iconUser} alt='' />
            <NameText>¡Hola {`${user.name} ${user.sur_name}`}!</NameText>
            <div
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative'
              }}>
              <div
                style={{
                  backgroundColor: '#0e79b4',
                  borderRadius: '50%',
                  height: '20px',
                  width: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'absolute',
                  top: '-11px',
                  right: '-14px'
                }}
              > <span
                style={{
                  color: '#ffffff',
                  fontWeight: 'bold',
                }}
              >{countItems}</span></div>
              {/* #0e79b4 */}
              {/* <ImagenCarrito src={carrito} alt='' /> */}
              <ImagenCarrito
                src={carrito}
                alt=''
                style={{ cursor: "pointer" }}
                onClick={() => history.push('/store/cart')}
              />
            </div>

          </NameContainer>
        }

        <ButtonContainer>
          <ButtonCerrarSesion
            onClick={() => logOut()}
          >
            Cerrar sesión
          </ButtonCerrarSesion>
        </ButtonContainer>

      </MenuContainer>
    </MainContainer>
  )
}
