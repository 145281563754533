import React from 'react'
import Nav from '../../components/common/nav'
import Unauthorized from '../../components/common/Unauthorized'

export const UnauthorizedUser = () => {
  return (
    <div>
        <Nav/>
        <Unauthorized />
    </div>
  )
}
