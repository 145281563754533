import styled from 'styled-components';

export const FooterRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #14181a;
  color: #ffffff;
  @media (max-width: 1023px) {
    ${(props) => props.mobile}
  }
`;
export const FooterCont = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  max-width: 1440px;
  padding: 40px 80px;
  justify-content: space-between;
  gap: 80px;
  @media (max-width: 1023px) {
    ${(props) => props.mobile}
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  gap: ${(props) => props.gap};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  width: ${(props) => props.justify || 'fit-content'};
  @media (max-width: 1023px) {
    ${(props) => props.mobile}
  }
`;

export const Text = styled.div`
  font-family: Roboto;
  font-size: ${(props) => props.size || '16px'};
  font-style: normal;
  font-weight: ${(props) => props.weight || '400'};
  max-width: ${(props) => props.maxWidth};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color};
  @media (max-width: 1023px) {
    ${(props) => props.mobile}
  }
`;
