import styled from 'styled-components';

export const MainContaier = styled.div`
  width: 236px;
  min-width: 236px;
  left: 0;
  background-color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
  box-shadow: 4px 4px 15px 0px rgba(13, 53, 100, 0.15);
  z-index: 3;
  @media (max-width: 1439px) {
    display: ${({ open }) => (open ? 'block' : 'none')};
    position: absolute;
    top: 72px;
    left: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  padding: 24px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  border-bottom: 1px;
  border-bottom-color: #dcdede;
  border-bottom-style: solid;
  cursor: pointer;
`;

export const LinkMkt = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
export const ContainerAlta = styled.div`
  display: flex;
  padding: 24px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  background-color: #0d3564;
  margin-top: 60px;
  border-color: #0d3564;
  border-style: solid;
`;

export const Title = styled.p`
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #0d3564;
  margin: 0;
`;

export const TitleAlta = styled.p`
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  margin: 0;
`;

export const Imagen = styled.img`
  width: 24px;
`;
