import React from 'react';
//styles
import { MainContainer } from './Descuentos.styles';
import UsuariosMkt from '../../components/Descuentos/UsuariosMkt';
//components

export default function Descuentos({ setClose }) {
  return (
    <MainContainer onClick={setClose}>
      <UsuariosMkt />
    </MainContainer>
  );
}
