import { configureStore } from '@reduxjs/toolkit';
import shoppingReducer, {
	loadState,
	saveState,
} from './reducers/shopping.reducer';
import filtersReducer, {
	saveStateFilter,
} from './reducers/FiltersProducts.reducer';
import tbReducer, {
	saveStateTB,
} from './reducers/TasaBeneficio.reducer';
import { combineReducers } from 'redux';
import shipmentSettingsReducer from './reducers/shipment.reducer';
import shipmentChoicesReducer from './reducers/shipmentChoices.reducer';
import apiPegasusReducer, { saveStateApiPegasus } from './reducers/apiPegasus.reducer';
import formDataReducer from './reducers/direccion.reducer'
import datosGeneralesReducer from './reducers/datosGrales.reducer'
import userReducer, { saveUser } from './reducers/user.reducer';
import shoppingCartReducer, { saveShoppingCart } from './reducers/shoppingCart.reducer';
import shipmentSelectedReducer, { saveShipmentSelected } from './reducers/shipmentSelected.reducer';
import paymentStatusReducer, { savePaymentStatus } from './reducers/payment.reducer';
import directionCartReducer, { saveDirectionCart } from './reducers/direccionShipment.reducer';

const rootReducers = combineReducers({
	user: userReducer,
	shoppingCart: shoppingCartReducer,
	shipmentSelected: shipmentSelectedReducer,
	paymentStatus: paymentStatusReducer,
	shoop: shoppingReducer,
	filters: filtersReducer,
	tasa: tbReducer,
	shipment: shipmentSettingsReducer,
	shipmentChoices: shipmentChoicesReducer,
	apiPegasus: apiPegasusReducer,
	formData: formDataReducer,
	datosGenerales: datosGeneralesReducer,
	directionCart: directionCartReducer,
});

const persistentDate = loadState();
const storeShop = configureStore({
	reducer: { rootReducers },
	preloadedState: persistentDate,
});

storeShop.subscribe(() => {
	saveUser(storeShop.getState().rootReducers.user);
	saveState(storeShop.getState().rootReducers.shoop);
	saveStateFilter(storeShop.getState().rootReducers.filters);
	saveStateTB(storeShop.getState().rootReducers.tasa);
	saveStateApiPegasus(storeShop.getState().rootReducers.apiPegasus);
	saveShoppingCart(storeShop.getState().rootReducers.shoppingCart);
	savePaymentStatus(storeShop.getState().rootReducers.paymentStatus);
	saveShipmentSelected(storeShop.getState().rootReducers.shipmentSelected);
	saveDirectionCart(storeShop.getState().rootReducers.directionCart);
});

export default storeShop;
