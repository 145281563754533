import React from 'react';
// styles
import { Container, Title } from '../PoliciesTerms/Terms.Styles';
import { ReuseTerms } from '../../components/ReuseTerms/ReuseTerms';

export const Privacy = () => {
  return (
    <Container>
      <Title>Aviso de privacidad</Title>
      <ReuseTerms term='El aviso de privacidad forma parte del uso del sitio web www.ballenamx.com' />

      <ReuseTerms
        term={
          <>
            <strong>Refacciones Digitales S de RV de CV</strong> (en adelante{' '}
            <strong>“Ballena”</strong> ), es responsable de la protección y
            tratamiento de sus datos personales, por lo que emite el presente
            aviso de privacidad como parte de sus políticas de privacidad y en
            cumplimiento a las disposiciones de la Ley Federal de Protección de
            Datos Personales en Posesión de Particulares (en adelante la "Ley")
            contemplando en todo momento los principios de licitud,
            consentimiento, información, calidad, finalidad, lealtad,
            proporcionalidad y responsabilidad en el tratamiento de los datos
            personales. Su privacidad y confianza son muy importantes para
            Ballena, por ello queremos asegurarnos que conozca cómo
            salvaguardamos la integridad, privacidad y protección de sus datos
            personales, en apego a la Ley. Para Ballena resulta necesaria la
            recopilación de ciertos datos personales para llevar a cabo las
            actividades intrínsecas a su giro comercial y mercantil. Ballena,
            tiene la obligación legal y social de cumplir con las medidas
            legales y de seguridad, suficientes para proteger los datos
            personales que recaba para las finalidades que en el presente Aviso
            de Privacidad serán descritas. Todo lo anterior se realiza con el
            objetivo de que usted tenga pleno control y decisión sobre sus datos
            personales. Por ello, le recomendamos que lea atentamente la
            siguiente información. Usted tendrá disponible en todo momento este
            Aviso de Privacidad en nuestra página de Internet
            www.ballenamx.com/aviso-de-privacidad.
          </>
        }
      />

      <ReuseTerms
        term={
          <>
            Su privacidad y confianza son muy importantes para{' '}
            <strong>Ballena</strong>, por ello queremos asegurarnos que conozca
            cómo salvaguardamos la integridad, privacidad y protección de sus
            datos personales, en apego a la Ley.
          </>
        }
      />

      <ReuseTerms
        term={
          <>
            Para <strong>Ballena</strong> resulta necesaria la recopilación de
            ciertos datos personales para llevar a cabo las actividades
            intrínsecas a su giro comercial y mercantil.{' '}
            <strong>Ballena</strong>, tiene la obligación legal y social de
            cumplir con las medidas legales y de seguridad, suficientes para
            proteger los datos personales que recaba para las finalidades que en
            el presente Aviso de Privacidad serán descritas.
          </>
        }
      />

      <ReuseTerms term='Todo lo anterior se realiza con el objetivo de que usted tenga pleno control y decisión sobre sus datos personales. Por ello, le recomendamos que lea atentamente la siguiente información.' />

      <ReuseTerms term='Usted tendrá disponible en todo momento este Aviso de Privacidad en nuestra página de Internet www.ballenamx.com/aviso-de-privacidad.' />

      <ReuseTerms
        title='1. Datos del Responsable '
        term={
          <>
            <strong>Refacciones Digitales S de RL de CV </strong>(La
            “Responsable”) es una sociedad constituida de conformidad con las
            leyes de los Estados Unidos Mexicanos, con domicilio en Av. Tláhuac
            128, Santa Isabela Industrial, Iztapalapa CP 09820 CDMX
          </>
        }
      />

      <ReuseTerms
        title='2. Datos Personales'
        term={
          <>
            <strong>Ballena</strong> recabará de usted (en adelante, “titular”)
            los datos personales (“Datos Personales”) que sean necesarios para
            procesar de manera adecuada sus compras a través de nuestros
            portales de internet o tiendas en el interior de la república, ya
            sea (i) directa o personalmente (ii) a través de sus agentes,
            promotores, comisionistas o socios comerciales. Los datos que
            solicitamos son los siguientes: • Datos de identificación y
            contacto: nombre completo, dirección, teléfono de contacto, correo
            electrónico(s); • Datos de facturación: Registro Federal de
            Contribuyentes (“RFC”), domicilio fiscal, correo electrónico.
          </>
        }
      />

      <ReuseTerms
        title='3. Datos recabados por medio de cookies web beacons o similares'
        term={
          <>
            Los cookies son un conocido mecanismo que almacena información sobre
            un usuario de internet en su propio ordenador, y se suelen emplear
            para asignar a los visitantes de un sitio de internet un número de
            identificación individual para su reconocimiento subsiguiente. Sin
            embargo, la existencia de los cookies y su uso generalmente no están
            ocultos al usuario, quien puede desactivar el acceso a la
            información de los cookies. Sin embargo, dado que un sitio Web puede
            usar un identificador cookie para construir un perfil del usuario y
            este no conoce la información que se añade a este perfil, se puede
            considerar a los cookies una forma de spyware. Por ejemplo, una
            página con motor de búsqueda puede asignar un número de
            identificación individual al usuario la primera vez que visita la
            página, y puede almacenar todos sus términos de búsqueda en una base
            de datos con su número de identificación como clave en todas sus
            próximas visitas (hasta que el cookie expira o se borra). Estos
            datos pueden ser empleados para seleccionar los anuncios
            publicitarios que se mostrarán al usuario, o pueden ser transmitidos
            (legal o ilegalmente) a otros sitios u organizaciones.
            <strong> Ballena</strong> utilizará los cookies solo con fines
            estadísticos para mejorar estrategias comerciales. En el caso de
            empleo de cookies, el botón de “ayuda” que se encuentra en la barra
            de herramientas de la mayoría de los navegadores, le dirá cómo
            evitar aceptar nuevos cookies, cómo hacer que el navegador le
            notifique cuando recibe un nuevo cookie o cómo deshabilitar todos
            los cookies.
          </>
        }
      />

      <ReuseTerms
        title='4. Uso de la información recabada y transferencia de datos con terceros.'
        term={
          <>
            <strong>Ballena</strong> utilizará la información recabada del
            titular para las siguientes finalidades primarias:
            <br />• Identificarle y darle de alta en nuestro sistema,
            generándole una cuenta personal y contraseña para que pueda realizar
            sus pedidos.
            <br />• Procesar sus compras y enviar sus productos al domicilio
            solicitado. • Responder dudas o comentarios.
            <br />• Proveerle los productos y servicios que contrata con
            nosotros y cumplir las obligaciones contraídas con usted.
            <br />• Informarle sobre los cambios en nuestros productos y/o
            servicios.
            <br />• Brindarle atención personalizada y seguimiento en relación
            con pedidos, servicios, solicitudes, comentarios, quejas o cualquier
            asunto relacionado con nuestro proceso de venta.
            <br />• Cumplir con la normativa aplicable.
            <br />• Ejecutar el proceso de facturación.
            <br />• Efectuar gestiones de cobranza, si se requiere.
            <br />• Gestionar devoluciones de pago.
            <br />• Apoyarlo en la gestión para el ejercicio de una garantía.
            Finalidades secundarias para el tratamiento de datos personales:
            <br />• Invitarle a conocer nuestros productos o promociones.
            <br />• Informarle sobre nuevos productos, servicios u ofertas.
            <br />• Llevar a cabo estadísticas, encuestas o estudios sobre
            hábitos de consumo y de mercado.
            <br />• Evaluar la calidad de nuestros servicios, productos y
            procesos de venta.
            <br />• Procesamiento de datos para el perfilamiento y mejor
            conocimiento del cliente.
            <br />
            En tanto, el titular no haya expresado su oposición, las finalidades
            secundarias tienen la legitimación de su consentimiento. Para
            oponerse al uso de sus datos personales para alguna de las
            finalidades secundarias, el titular podrá solicitar en cualquier
            momento su adición a la “Lista de Exclusión” que gestiona{' '}
            <strong>Ballena</strong> , a efecto de que se detenga el tratamiento
            de sus datos personales para estos fines, enviando un correo
            electrónico a datospersonales@ballenamx.com, indicando en el asunto
            “Lista de Exclusión”.
            <br /> <strong>Ballena</strong> no compartirá datos personales con
            terceras partes, excepto que tenga expresa autorización del titular,
            sea necesario para el cumplimiento de las obligaciones contractuales
            contraídas por Ballena, o por orden judicial para cumplir con las
            disposiciones procesales. En caso de tener su autorización, Ballena
            podrá transferir sus datos personales a las siguientes entidades:
            <br />• Empresas de servicio de envío y recepción de paquetería.
            <br />• Proveedores de productos para el ejercicio de una garantía.
            <br />• Instituciones bancarias.
          </>
        }
      />

      <ReuseTerms
        title='5. Protección'
        term={
          <>
            <strong>Ballena</strong> se compromete a asegurar la privacidad de
            la información obtenida a través de este medio. Sugerimos leer
            atentamente este Aviso de Privacidad para estar consciente de cómo
            se usarán los datos personales proporcionados.
            <br />
            Del mismo modo, hacemos de su conocimiento que el llenado de
            cualquier formulario, la remisión de un correo electrónico, mensaje
            o llamada, sin que previamente se haya recibido una oposición de su
            parte al tratamiento de sus datos personales o a este documento,
            implica la aceptación de este Aviso de Privacidad
            <br />
            Todos los datos obtenidos a través del proceso de gestión de su
            compra por este medio o por cualquier plataforma de venta en línea,
            red social, o mensajería, serán utilizados para las finalidades
            establecidas en el apartado 4 de este Aviso de Privacidad.
            <br />
            Los datos personales proporcionados por usted a través de
            plataformas como Facebook, Twitter, Amazon, Mercado Libre o
            similares, pueden ser almacenados por estas, en cuyo caso,{' '}
            <strong>Ballena</strong> no tendrá el control del tratamiento de sus
            datos personales por parte de dichos terceros y deberá atender a las
            políticas de privacidad de estos.
            <br />
            Con el simple hecho de visitar nuestra página web, el usuario no
            está obligado a facilitar información personal. Una vez que usted
            elija el producto es indispensable dar clic en “comprar”, a partir
            de ese momento Ballena le solicitará información personal.
            <br />
            <strong>Ballena</strong> se compromete a salvaguardar la máxima
            protección y confidencialidad sobre la información que usted nos
            facilite por medio del sitio y a utilizarla únicamente para los
            fines aquí indicados. Única y exclusivamente se utilizará el correo
            electrónico y datos de contacto proporcionados por el titular.
            <br />
            <strong>Ballena</strong> no recaba datos personales de menores de
            edad. <strong>Ballena</strong> presume que los datos han sido
            introducidos por su titular o por una persona autorizada para la
            compra, siendo en ambos casos mayores de edad, así como verdaderos,
            correctos y exactos. <strong>Ballena</strong> no será responsable de
            la inexactitud de los datos personales y/o referencias de pago
            proporcionado por los titulares que ocasionen retrasos en la entrega
            de lo(s) producto(s) comercializados por <strong>Ballena</strong> .
            La seguridad y la confidencialidad de los datos que los titulares
            proporcionen al contratar un servicio o comprar un producto en línea
            estarán protegidos por un servidor seguro bajo el protocolo Secure
            Socket Layer (SSL), de tal forma que los datos enviados se
            transmitirán a <strong>Ballena</strong> encriptados para asegurar su
            resguardo.
            <br />
            Para verificar que se encuentra en un entorno protegido, asegúrese
            de que aparezca una S en la barra de navegación. Ejemplo: https://.
            <br />
            Sin embargo, y a pesar de contar cada día con herramientas más
            seguras, la protección de los datos enviados a través de Internet no
            se puede garantizar al 100%; sin embargo, <strong>
              Ballena
            </strong>{' '}
            garantiza sus esfuerzos en mantener la seguridad de la información y
            que aplica herramientas y medidas tecnológicas de vanguardia
            tendientes a salvaguardar la información que se recibe de forma
            electrónica.
          </>
        }
      />

      <ReuseTerms
        title='6. Limitación de uso y divulgación de información.'
        term={
          <>
            Para el ejercicio de sus Derechos ARCO (Acceso, Rectificación,
            Cancelación u Oposición) respecto de los datos personales que son
            tratados por Ballena, el titular podrá hacerlo en cualquier momento
            presentando una solicitud al responsable (la “Solicitud de Ejercicio
            de Derechos ARCO”), la cual deberá enviar a la dirección de correo
            electrónico soporte.clientes@ballenamx.com o bien entregar en el
            domicilio ubicado en CDMX, mediante escrito libre que deberá
            contener la siguiente información y ser acompañada de los documentos
            siguientes, en original (para cotejo) y copia:
            <br />
            a. Nombre y domicilio completo (calle, número interior y exterior,
            colonia, código postal, ciudad, municipio y estado), número de
            teléfono para contactarlo y correo electrónico en caso de tenerlo, y
            la indicación de ser una “Solicitud de Ejercicio de Derechos ARCO”;
            <br />
            b. Copia legible de identificación oficial (credencial de elector,
            pasaporte vigente, cédula profesional u documento migratorio), en
            caso de que actúe en representación de otra persona, los documentos
            que acrediten su representación legal o poder (i. acta constitutiva
            de la persona moral y del instrumento en donde conste su
            nombramiento, así como de una identificación oficial; ii. Carta
            poder firmada ante dos testigos);
            <br />
            c. La petición concreta sobre el derecho ARCO que desea ejercer.
            <br />
            d. Una descripción clara y precisa de los datos personales y/o datos
            personales sensibles respecto de los cuales busca revocar su
            consentimiento o ejercer alguno de los Derechos ARCO, y;
            <br />
            e. Cualquier documento o información que facilite la localización de
            sus datos personales y/o datos personales;
            <br />
            f. En caso de solicitar una rectificación de datos, deberá indicar
            también las modificaciones a realizarse y aportar la documentación
            que sustente su petición (acta de nacimiento, comprobante de
            domicilio, o cualquier otro documento que de acuerdo a su solicitud
            brinde certeza de la rectificación que solicita.
            <br />
            <strong>Ballena</strong> podrá requerir cualquier información
            adicional que sea necesaria para responder de manera más adecuada a
            su solicitud.
            <br />
            <strong>Ballena</strong> comunicará al titular la respuesta a su
            Solicitud de Ejercicio de Derechos ARCO, informando los motivos de
            su decisión mediante correo certificado a la dirección proporcionada
            (con costo adicional a cargo del titular) o de manera presencial en
            el domicilio señalado dentro del Aviso de Privacidad y de este
            proceso) en un plazo máximo de 20 (veinte) días hábiles contados
            desde el día en que se haya recibido su Solicitud de Ejercicio de
            Derechos ARCO. En caso de que la Solicitud de Ejercicio de Derechos
            ARCO se conteste de manera afirmativa o procedente, se hará efectiva
            en un plazo máximo de 15 (quince) días hábiles. Los plazos referidos
            en este párrafo se podrán prorrogar por una sola vez por un periodo
            igual en caso de ser necesario.
            <br />
            <strong>Ballena</strong> podrá negar (la “Negativa”) el acceso a los
            datos o realizar la rectificación o cancelación o conceder la
            oposición al tratamiento de los mismos en los siguientes supuestos:
            <br />
            a) Cuando el solicitante no sea el titular de los datos personales
            y/o datos personales, o no pueda acreditar la representación del
            titular;
            <br />
            b) Cuando sus datos personales y/o datos personales no obren en la
            base de datos de la Empresa;
            <br />
            c) Cuando se lesionen los derechos de un tercero;
            <br />
            d) Cuando exista un impedimento legal o la resolución de una
            autoridad competente, que restrinja sus Derechos ARCO, y;
            <br />
            e) Cuando la rectificación, cancelación u oposición haya sido
            previamente realizada.
            <br />
            La Negativa podrá ser parcial, en cuyo caso <strong>
              Ballena
            </strong>{' '}
            efectuará el acceso, rectificación, cancelación u oposición en la
            parte procedente, informando al titular o su representante legal el
            motivo de su decisión acompañado de las pruebas pertinentes.
            <br />
            El ejercicio de los Derechos ARCO será gratuito, el titular deberá
            cubrir únicamente los gastos justificados de envío o el costo de
            reproducción en copias u otros formatos para que el derecho que ha
            solicitado se efectúe de forma expedita.
          </>
        }
      />

      <ReuseTerms
        title='7. Datos personales sensibles'
        term={
          <>
            <strong>Ballena</strong> no recaba datos sensibles directamente
            desde nuestros sitios WEB, todas las operaciones financieras en
            línea están protegidas por un servidor seguro bajo el protocolo
            SECURE SOCKET LAYER (SSL), el cual podrá corroborar al momento de
            realizar su compra en la barra de su navegador, dichos datos serán
            encriptados para asegurar su resguardo.
            <br />
            Los medios de pago en línea utilizados son:
            <br />
            1. Pago con tarjeta de crédito y/o débito por medio del motor de
            pago Mercado Pago…
            <br />
            2. Paypal. El usuario tendrá que tener una cuenta activa en
            www.paypal.com para poder procesar su pago, así mismo los datos
            sensibles de la tarjeta serán introducidos en dicho establecimiento.
            <br />
            En lo general <strong>Ballena</strong> no captura, solicita, ni
            almacena datos sensibles de nuestros clientes mediante los portales
            o plataforma de internet.
            <br />
            En caso de que el titular llegue a solicitar una devolución de pago
            y esta no pueda efectuarse de forma automática mediante las
            plataformas o forma de pago usada por el titular para la compra,{' '}
            <strong>Ballena</strong> se podrá en contacto directo con el titular
            para brindarle una solución extraordinaria para el proceso de
            devolución. Cualquier dato personal que sea proporcionado por el
            titular para estos fines será tratado con la misma confidencialidad
            y seguridad que la empleada para los datos personales y en caso de
            que sea necesario, se solicitará que manifieste su consentimiento
            expreso para el tratamiento de sus datos personales, de conformidad
            con las políticas de privacidad de <strong>Ballena</strong>.
          </>
        }
      />

      <ReuseTerms
        title='8. Aceptación de los términos'
        term={
          <>
            Si el usuario utiliza los servicios del sitio web de{' '}
            <strong>Ballena</strong> , significa que ha leído, entendido y
            acordado los términos antes expuestos.
          </>
        }
      />

      <ReuseTerms
        title='9. Modificaciones '
        term='Le informamos que podremos realizar modificaciones al presente Aviso de Privacidad de manera unilateral cuando lo consideremos necesario. Cuando estos cambios se lleven a cabo, se actualizará la fecha de "última actualización" que se señala en la parte superior de esta página web. Las modificaciones se harán efectivas a partir de la última fecha de actualización, por lo que le recomendamos revise periódicamente este Aviso para que permanezca informado sobre nuestras políticas de privacidad.'
      />

      <ReuseTerms
        title='10. Autoridad'
        term={
          <>
            Si el usuario considera que han sido vulnerados sus derechos
            respecto de la protección de datos personales, tiene el derecho de
            acudir a la autoridad correspondiente para defender su ejercicio. La
            autoridad es el Instituto Nacional de Transparencia, Acceso a la
            Información y Protección de Datos Personales (INAI), su sitio web
            es: www.inai.org.mx.
            <br />
            Con el fin de reforzar la prevención, protección y derechos de
            consumidor, ponemos a su disposición enlace directo con "PROFECO" y
            "CONDUSEF"
          </>
        }
      />
    </Container>
  );
};
