import {
	useDispatch,
	useSelector
} from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { getPaymentStatus } from "../redux/reducers/payment.reducer";

export default function usePaymentStatus() {
	const dispatch = useDispatch();
	const [statusPayment, setStatusPayment] = useState([]);

	const stateShoppingItems = useSelector((state) => state.rootReducers.paymentStatus);

	useEffect(() => {
		let parseDataShipment = stateShoppingItems.state ? JSON.parse(stateShoppingItems.state) : []
		setStatusPayment(parseDataShipment)

	}, [stateShoppingItems])


	const updateStatusPayment = (dataStatus) => {
		dispatch(getPaymentStatus(JSON.stringify(dataStatus)));
	}

	return {
		statusPayment,
		updateStatusPayment
	}
}
