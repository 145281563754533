import styled from "styled-components";

export const MainContainer = styled.div`
    width:100%;
    display: flex;
    padding: 24px;
    flex-direction: column;
    gap: 40px;
    align-self: stretch;
    border-radius: 8px;
    background-color:#FFF;
    box-shadow: 0px 20px 54px 0px rgba(13, 53, 100, 0.15);
`
export const TitleContainer = styled.div`
    display:flex;
    gap: 8px;
    @media (max-width: 1023px) {
    margin-top:40px
  }    
`

export const Imagen = styled.img`
    width:32px
`
export const ImagenArrow = styled.img`
    display:none;
    @media (max-width: 1023px) {
     width:32px;
     display:flex;
     margin-left: auto;
  }    
`

export const Title = styled.div`
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
    color:#0D3564;
    margin:0
`

export const Container = styled.div`
    width:50%;
    display:flex;
    flex-direction:column;
    justify-content:end;
    @media (max-width: 1023px) {
    flex-direction: column;
    width:100%;
    margin:0 auto
  }
`

export const InputContaier = styled.div`
    width:100%;margin-bottom: 1rem;
    position:relative;
`
export const InputContaierButtonValidar = styled.div`
    width:100%;margin-bottom: 1rem;
    position:relative;
    display:flex;
     @media (max-width: 1023px) {
  display:none
  }
`

export const InputContaierNuevaContraseña = styled.div`
    width:100%;margin-bottom: 1rem;
    position:relative;
    display:flex;
    flex-direction:column;
   @media (max-width: 1023px) {
    flex-direction: column;
    width:100%;
    margin:0 auto;
    display:none
  }

`

export const InputContaierValidadCodigo = styled.div`
display:flex;
    width:100%;margin-bottom: 1rem;
    position:relative;
    @media (max-width: 1023px) {
    display:none
  }

`

export const InputLabel = styled.p`
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 14.4px */
color:#061222;
margin:0
`

export const InputSeguridad = styled.input`
display: flex;
height: 48px;
padding: 16px 8px;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: 8px;
border: 1px solid #B9CAD0;
background-color:#F4F4F4;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 19.2px */
color:#8299A3;
 width:100%
`

export const ButtonSeg = styled.button`
display: flex;
width: 194px;
height: 40px;
padding: 8px 24px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
background:#0D3564;
border-color: #0D3564;
border-style: solid;
border-width: 1px;
color:#FFF;
@media (max-width: 1023px) {
    width:100%;
  }

`
export const ButtonContainer = styled.div`
    display:flex;
    width:100%;
    justify-content: center;
`

export const ButtonCambiar = styled.button`
display: flex;
  width: 194px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border-color: ${({ hasError }) => (hasError ? '#0D3564' : '#DCDEDE')};
  border-style: solid;
  border-width: 1px;
  background: ${({ hasError }) => (hasError ? '#0D3564' : '#DCDEDE')};
  color: ${({ hasError }) => (hasError ? '#FFF' : '#8299A3')};
  @media (max-width: 1023px) {
    width:100%;
  }
`
export const IconUserEye = styled.img`
  width: 20px;
  height: 15px;
  position: absolute;
  bottom: 18px;
  right: 10px;
`;

export const WarningContainer = styled.div`
    display:flex;
    gap:1rem;
    align-items:center;
   
`

export const WarningText = styled.p`
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    color:#55646A;
    margin:0
`

export const ContainerContrasena = styled.div`
display: flex; 
gap:24px;
@media (max-width: 1023px) {
    flex-direction: column;

  }
`