import { createSlice } from "@reduxjs/toolkit";


const initialState = JSON.parse(
  sessionStorage.getItem('redux_user') || '[]'
);

export const loadState = () => {
  try {
    return JSON.parse(sessionStorage.getItem('redux_user') || '[]');
  } catch (err) {
    return [];
  }
};

export const saveUser = (state) => {
  const serializedState = JSON.stringify(state);
  sessionStorage.setItem('redux_user', serializedState);
};

export const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUserInfo: (state, action) => (
      // console.log(state),
      {
        state: action.payload,
      }
    ),
  },
});

export const { getUserInfo } = userReducer.actions;

export default userReducer.reducer;
