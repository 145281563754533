import React from 'react';
//styles
import {
  MainContainer,
  InsideContaier,
  GeralDataContainer,
  SecurityContainer,
} from './Configuracion.styles';
import ConfigTitle from '../../components/Configuracion/ConfigTitle/ConfigTitle';
import DatosGeneralesConfig from '../../components/Configuracion/DatosGeneralesConfig/DatosGeneralesConfig';
import DireccionConfig from '../../components/Configuracion/DireccionConfig/DireccionConfig';
import DatosContactoConfig from '../../components/Configuracion/DatosContactoConfig/DatosContactoConfig';
import Seguridad from '../../components/Configuracion/Seguridad/Seguridad';

export default function Configuracion({ setClose }) {
  return (
    <MainContainer onClick={setClose}>
      <ConfigTitle />
      <InsideContaier>
        <GeralDataContainer>
          <DatosGeneralesConfig />
          <DireccionConfig />
        </GeralDataContainer>
        <SecurityContainer>
          <DatosContactoConfig />
          <Seguridad />
        </SecurityContainer>
      </InsideContaier>
    </MainContainer>
  );
}
