// import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
// import axios from "axios";
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_MARKETPLACE_USER, GET_USER_INFO } from '../graphql/Query';
import { useHistory } from 'react-router-dom';
import { getUserInfo } from '../redux/reducers/user.reducer';
import { useState } from 'react';
import useShoppingCart from './useShoppingCart';
import { SAVE_SHOPING_CART_PROGRESS } from '../graphql/Mutation';

export default function useUser() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { combineItems, getItemsCart, itemsShoppingCart } = useShoppingCart();
  const stateShoppingItems = useSelector(
    (state) => state.rootReducers.shoppingCart
  );
  // const [loading, setLoading] = useState(false);
  // const [idUser, setIdUser] = useState(false);

  const [inCart, setInCart] = useState(false);

  const [saveProgressMutation] = useMutation(SAVE_SHOPING_CART_PROGRESS, {
    fetchPolicy: 'no-cache',
    onCompleted(saveShoppingCartProgress) {
      // const res = JSON.parse(saveShoppingCartProgress.saveShoppingCartProgress.response)
      // setLoading(false)
      // getItemsCart(idUser)
    },
    onError(err) {
      // setLoading(false)
    },
  });

  const [getUserMktp] = useLazyQuery(GET_MARKETPLACE_USER, {
    fetchPolicy: 'no-cache',
    onCompleted(getUser) {
      dispatch(getUserInfo(JSON.stringify(getUser)));
      const info =
        getUser?.getUserMarketplace['pending_purchases.shopping_cart']?.items ||
        [];
      if (stateShoppingItems.state && stateShoppingItems.state !== '[]') {
        // setIdUser(info)
        saveProgressMutation({
          variables: {
            step: 'shopping_cart',
            info: JSON.stringify({
              items: JSON.parse(stateShoppingItems.state),
              idCustomer: getUser.getUserMarketplace.cat_user_marketplace_id,
            }),
          },
        });
        combineItems(info, itemsShoppingCart);
      } else {
        if (typeof info === 'string') {
          getItemsCart(JSON.parse(info));
        } else {
          getItemsCart(info);
        }
      }
      // setIdUser(info)
      if (!inCart) {
        history.push('/store');
      }
    },
    onError(err) { },
  });

  const [getUserInfoMutation] = useLazyQuery(GET_USER_INFO, {
    fetchPolicy: 'no-cache',
    onCompleted({ getInfoByUserId }) {
      console.log(getInfoByUserId);
      // dispatch(getUserInfo(JSON.stringify(getInfoByUserId.message)));
    },
    onError(err) {
      console.log('err', err);
    },
  });

  const getInfoUser = (email, isLoginInCart) => {
    setInCart(isLoginInCart);
    getUserMktp({
      variables: {
        email,
      },
    });
  };

  const getInfoUserDashboard = async (token) => {
    const temp = await getUserInfoMutation({
      variables: {
        token,
      },
    });
    dispatch(getUserInfo(temp.data.getInfoByUserId.message));
  };

  const infoUser = useSelector((state) => state.rootReducers.user);
  const parseData = infoUser.state
    ? JSON.parse(infoUser.state)
    : { getUserMarketplace: '' };
  return { user: parseData.getUserMarketplace || parseData, getInfoUser, getInfoUserDashboard };
}
