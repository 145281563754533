import styled from "styled-components";

export const MainContainer = styled.div`
    display: flex;
    padding: 24px;
    flex-direction: column;
    gap: 40px;
    align-self: stretch;
    border-radius: 8px;
    box-shadow: 0px 20px 54px 0px rgba(13, 53, 100, 0.15);
    background-color:#FFF;
    margin-top:24px
`

export const TitleContainer = styled.div`
    display:flex;
    gap: 8px;
`

export const Imagen = styled.img`
    width:32px
`

export const Title = styled.h3`
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
    color:#0D3564
`
export const Container = styled.div`
    display:flex;
    gap:1rem;
    align-items:center;

`

export const OptionCotainer = styled.div`
    display: flex;
    height: 48px;
    padding: 16px 8px;
   
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #B9CAD0;
    background-color: #DCDEDE;
    width:100%
`

export const ButtonDelete = styled.button`
    display: flex;
    width: 68px;
    height: 40px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background-color:#0D3564;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color:#FFF;
    border-color: #0D3564;
    border-style:solid
`
export const OptionText = styled.p`
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    color:#14181A;

`