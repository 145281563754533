import styled from "styled-components";

export const MainContainer = styled.div`
    padding: 40px 24px 80px 24px;
    gap:24px;
    display:flex;
    flex-direction:column;
    width:100%;
    @media (max-width: 1023px) {
        padding:20px 0;
  }
`
export const InsideContaier = styled.div`
display:flex; 
flex-direction:row; 
gap:24px;
width:100%;
@media (max-width: 1023px) {
    flex-direction: column;
    width:100%;
    margin:0 auto
  }

`
export const GeralDataContainer = styled.div`
display: flex;
flex-direction: column; 
gap: 24px; 
width:50%;
@media (max-width: 1023px) {
    width:100%;
  }
`

export const SecurityContainer = styled.div`
display: flex; 
flex-direction:column; 
gap:24px;
width:50%;
@media (max-width: 1023px) {
    width:100%;
  }
`