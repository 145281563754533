import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const shipmentChoicesReducer = createSlice({
	name: 'shipmentChoices',
	initialState,
	reducers: {
		addShipmentChoices: (state, action) => ({ ...state, ...action.payload }),
		deleteShipmentChoices: (state, action) => {
			const id_parte = action.payload;

			if (state[id_parte]) {
				const newState = { ...state };
				delete newState[id_parte];
				return newState;
			}
		},
		cuantifyModifyInChoices: (state, action) => {
			const { id_parte, value } = action.payload;

			if (state[id_parte]) {
				const newState = state;
				newState[id_parte].cuantity += value;
				return newState;
			}
		},
	},
});

export const {
	addShipmentChoices,
	deleteShipmentChoices,
	cuantifyModifyInChoices,
} = shipmentChoicesReducer.actions;

export default shipmentChoicesReducer.reducer;
