/* eslint-disable */
import React from 'react';
import Nav from '../components/common/nav';
import { Flex } from '../styles/common.styles';
import Footer from '../components/common/footer';
import DetailItemComponent from '../components/DetailItem/DetailItemComponent';
import { Finder } from '../components/Store/Finder/Finder';

const DetailItem = () => {
  return (
    <>
      <Nav store />
      <Flex direction='column' width='100%' gap='4rem'>
        {/* <Finder redirect={true} /> */}
        <DetailItemComponent />
      </Flex>
      <Footer />
    </>
  );
};

export default DetailItem;
