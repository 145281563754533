import React from 'react'
//styles
//assets
import { url } from '../../../api';
import { Overlay,MainContainer,ButtonCargar,ButtonContainer, MainTitle, Subtitle,ButtonCancelar  } from './ModalEliminarAdminMkt.styles'
const edit = `${url}Editar.svg`;
const close = `${url}CloseButton.png`;
export default function ModalEliminarAdminMkt({showModalDelete, setShowModalDelete, handleDelete}) {
  return (
    <Overlay>
    <MainContainer>
      <MainTitle>Eliminar producto</MainTitle>
    <Subtitle>¿Estás seguro que deseas eliminar el producto?</Subtitle>
    <ButtonContainer>
     
      <ButtonCargar
      onClick={handleDelete}
      >Eliminar</ButtonCargar>
      <ButtonCancelar onClick={()=>setShowModalDelete(!showModalDelete)}>Cancelar</ButtonCancelar>
    </ButtonContainer>
      
    </MainContainer>
    </Overlay>
  )
}

