/* eslint-disable */
import React, { useEffect } from 'react';
import { Flex } from '../styles/common.styles';
import Nav from '../components/common/nav';
import Footer from '../components/common/footer';
import { Finder } from '../components/Store/Finder/Finder';
import CartSteps from '../components/CartComponent/CartSteps';
import ResultPayment from '../components/CartComponent/ResultPayment';

const ResultCart = () => {

  return (
    <>
      <Nav store />
      <Flex direction='column' width='100%' gap='4rem'>
        <ResultPayment />
      </Flex>
      <Footer />
    </>
  );
};

export default ResultCart;
