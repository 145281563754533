/* eslint-disable */
import React, { useState } from 'react';
import { Flex, Card, Text, Button } from '../../styles/common.styles';
import SellFormModal from './Modals/SellFormModal.jsx';
import FinishModal from './Modals/FinishModal';
import { url } from '../../api';
const Laptop = `${url}LaptopMoney.svg`;
const QuieresVender = () => {
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  return (
    <>
      <SellFormModal
        setOpen={setOpenModal1}
        open={openModal1}
        finishModal={setOpenModal2}
      />
      <FinishModal open={openModal2} setOpen={setOpenModal2} />
      <Flex
        direction='row'
        width='100%'
        align='center'
        maxWidth='1440px'
        alignSelf='center'
        mobile='flex-direction: column-reverse; align-items: flex-start;'
      >
        <Card
          padding='48px 24px 48px 80px'
          align='flex-start'
          gap='40px'
          height='fit-content'
          minHeight='343px'
          width='50%'
          direction='column'
          shadow='8px 0px 20px 0px rgba(0, 76, 151, 0.25);'
          mobile='width:100%; padding: 20px 40px;'
        >
          <Text
            size='40px'
            weight='800'
            color='#0D3564'
            mobile='width: 100%; text-align:center;'
          >
            ¿Quieres vender?
          </Text>
          <Flex direction='column' gap='16px'>
            <Text
              size='24px'
              weight='700'
              color='#14181A'
              lineHeight='120%'
              mobile='font-weight: 800;'
            >
              Habla con un experto en Ballena
            </Text>
            <Text
              size='16px'
              weight='400'
              color='#55646A'
              family='Inter'
              lineHeight='120%'
            >
              Agenda una llamada con un experto sin compromiso y descubre cómo
              nuestra solución puede ayudar a tu negocio.
            </Text>
          </Flex>
          <Button mobile='width:100%;' onClick={() => setOpenModal1(true)}>
            Llenar formulario
          </Button>
        </Card>
        <img
          src={Laptop}
          style={{
            maxWidth: '50%',
            zIndex: '-1',
            maxWidth: '652px',
            width: '100%',
          }}
        />
      </Flex>
    </>
  );
};

export default QuieresVender;
