export const formConfig = {
	id: 'form-checkout',
	cardholderName: {
		id: 'form-checkout__cardholderName',
		placeholder: 'Titular de la tarjeta',
	},
	cardholderEmail: {
		id: 'form-checkout__cardholderEmail',
		placeholder: 'E-mail',
	},
	cardNumber: {
		id: 'form-checkout__cardNumber',
		placeholder: 'Número de la tarjeta',
	},
	cardExpirationMonth: {
		id: 'form-checkout__cardExpirationMonth',
		placeholder: 'mm',
	},
	cardExpirationYear: {
		id: 'form-checkout__cardExpirationYear',
		placeholder: 'yy',
	},
	securityCode: {
		id: 'form-checkout__securityCode',
		placeholder: 'cvc',
	},
	installments: {
		id: 'form-checkout__installments',
		placeholder: 'Cuotas',
	},
	docType: {
		id: "form-checkout__identificationType",
		placeholder: "Tipo de documento",
	  },
	identificationNumber: {
		id: 'form-checkout__identificationNumber',
		placeholder: 'Número de documento',
	},
	issuer: {
		id: 'form-checkout__issuer',
		placeholder: 'Banco emisor',
	},
};
