import React, { useState } from "react";

//styles
import {
  MainContainer,
  Container,
  Imagen,
  InputDireccion,
  InputLabel,
  Subcotainer,
  TitleContainer,
  Title,
  InputContainer,
  ErrorMsg,
  ImagenArrow
} from "./DireccionConfig.styles";
import { url } from "../../../api";
//assets
const ubicacion = `${url}ubicacion.svg`;
const UpArrow = `${url}UpArrow.svg`
const downArrow = `${url}downArrow.svg`
export default function DireccionConfig() {
  const [codigoPostal, setCodigoPostal] = useState("");
  const [errorCodigoPostal, setErrorCodigoPostal] = useState("");
  const [hideArrow, setHideArrow] = useState(true)

  const handleCodigoPostalChange = (e) => {
    const inputValue = e.target.value;
    setCodigoPostal(inputValue);

    if (!/^\d{0,5}$/.test(inputValue)) {
      setErrorCodigoPostal("El código postal debe ser numérico y tener hasta 5 dígitos");
    } else {
      setErrorCodigoPostal("");
    }
  };
  return (
    <MainContainer>
      <TitleContainer>
        <Imagen src={ubicacion} alt="" />
        <Title>Dirección</Title>
        <ImagenArrow src={hideArrow ? UpArrow : downArrow} alt='' onClick={()=>setHideArrow(!hideArrow)}/>
      </TitleContainer>

     {hideArrow ? 
     <Subcotainer>
     <Container>
       <InputContainer>
         <InputLabel>País o región</InputLabel>
         <InputDireccion placeholder="País o región"/>
       </InputContainer>
       <InputContainer>
         <InputLabel>Número exterior</InputLabel>
         <InputDireccion placeholder="Num Ext"/>
       </InputContainer>
       <InputContainer>
         <InputLabel>Código postal</InputLabel>
         <InputDireccion 
         placeholder="Código postal"
         value={codigoPostal}
         onChange={handleCodigoPostalChange} />
         {errorCodigoPostal && <ErrorMsg>{errorCodigoPostal}</ErrorMsg>}
       </InputContainer>
     </Container>
     <Container>
       <InputContainer>
         <InputLabel>Calle</InputLabel>
         <InputDireccion placeholder="Calle" />
       </InputContainer>
       <InputContainer>
         <InputLabel>Número interior (opcional)</InputLabel>
         <InputDireccion placeholder="Num int" />
       </InputContainer>
     </Container>
   </Subcotainer>
     : null}
     
      
    </MainContainer>
  );
}
