/* eslint-disable */
import React, { useState } from 'react';
import { Flex, Text } from '../../styles/common.styles';
import {
  Box,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  stepConnectorClasses,
  styled,
} from '@mui/material';
import TuCarrito from './Subsections/TuCarrito';
import DatosEnvio from './Subsections/DatosEnvio';
import Payment from './Subsections/Payment';
import PaqueteriaElegir from './Subsections/PaqueteriaElegir/PaqueteriaElegir';
import Success from './Subsections/Success';
import { SAVE_SHOPING_CART_PROGRESS } from '../../../graphql/Mutation';
import { useMutation } from '@apollo/client';
import Loader from '../common/Loader';
import useUser from '../../../hooks/useUser';
import useShoppingCart from '../../../hooks/useShoppingCart';
import { useEffect } from 'react';
import useShipmentCart from '../../../hooks/useShippment';



const ResultPayment = () => {
  const { user } = useUser();
  // 
  const [loading, setLoading] = useState(false);

  /**Actualizacion del carrito ligado a la cuenta y progresos en la db */
  const { updateItemsCart, itemsShoppingCart } = useShoppingCart()

  useEffect(() => {
    console.log(itemsShoppingCart)
  }, [itemsShoppingCart])
  /**------------------------------------------------------------------ */
  return (
    <>
      <Loader open={loading} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Success items={itemsShoppingCart} />
        </div>
      </div>
    </>
  );
};

export default ResultPayment;
