import React, { useState } from 'react'
//styles 
import { MainContainer, Imagen, InputContacto, InputContainer, InputLabel, Title, TitleContainer, Container, ErrorMsg, ImagenArrow } from './DatosContactoConfig.styles'
import { url } from '../../../api'
//assets
const  contacto = `${url}contacto.svg`
const UpArrow = `${url}UpArrow.svg`
const downArrow = `${url}downArrow.svg`
export default function DatosContactoConfig() {

    const [correoElectronico, setCorreoElectronico] = useState('');
    const [errorCorreoElectronico, setErrorCorreoElectronico] = useState('');
    const [numeroCelular, setNumeroCelular] = useState('');
    const [errorNumeroCelular, setErrorNumeroCelular] = useState('');
    const [hideArrow, setHideArrow] = useState(true)

    const handleCorreoElectronicoChange = (e) => {
        const inputValue = e.target.value;
        setCorreoElectronico(inputValue);
    
        if (!/^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,}$/.test(inputValue)) {
          setErrorCorreoElectronico('El correo electrónico no es válido');
        } else {
          setErrorCorreoElectronico('');
        }
      };
      
    const handleNumeroCelularChange = (e) => {
        const inputValue = e.target.value;
        setNumeroCelular(inputValue);

        if (!/^\d{10}$/.test(inputValue)) {
        setErrorNumeroCelular('El número de celular debe contener 10 dígitos');
        } else {
        setErrorNumeroCelular('');
        }
    };

  return (
    <MainContainer>
        <TitleContainer>
            <Imagen alt='' src={contacto}/>
            <Title>Datos de contacto</Title>
            <ImagenArrow src={hideArrow ? UpArrow : downArrow} alt='' onClick={()=>setHideArrow(!hideArrow)}/>
        </TitleContainer>
        {hideArrow ? 
            <Container>
            <InputContainer>
                <InputLabel>Correo electrónico</InputLabel>
                <InputContacto 
                  placeholder='Correo eléctronico'
                  value={correoElectronico}
                  onChange={handleCorreoElectronicoChange}/>
                  {errorCorreoElectronico && <ErrorMsg>{errorCorreoElectronico}</ErrorMsg>}
            </InputContainer>
            <InputContainer>
                <InputLabel>Número celular</InputLabel>
                <InputContacto 
                placeholder='Número de celular'
                value={numeroCelular}
                onChange={handleNumeroCelularChange}/>
                {errorNumeroCelular && <ErrorMsg>{errorNumeroCelular}</ErrorMsg>}
            </InputContainer>
        </Container>
        : null}
    
    </MainContainer>
  )
}
