import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
//styes
import {
  MainContaier,
  Title,
  Container,
  // TitleAlta,
  // ContainerAlta,
  Imagen,
  LinkMkt,
} from './SideNav.styles';
import { url } from '../../../api';
import useShoppingCart from '../../../../hooks/useShoppingCart';
//assets
const dashboard = `${url}Dashboard.svg`;
const logOutImg = `${url}logOut.svg`;
const descuentos = `${url}descuentos.svg`;
// const iconUser = `${url}IconUser.svg`;
const add = `${url}add.svg`;
const engrane = `${url}engrane.svg`;
const MarketPlace = `${url}MarketPlace.svg`;
const AltaDistribuidor = `${url}AltaDistribuidor.svg`;
const configBlanco = `${url}configBlanco.svg`;
const DescuentosBlanco = `${url}DescuentosBlanco.svg`;
export default function SideNav({
  handleSectionClick,
  selectedSections,
  open,
  handleSidebarClose,
}) {
  const history = useHistory();
  const containerRef = useRef(null);
  const { clearLocalItems } = useShoppingCart();
  useEffect(() => {
    // Add a click event listener to the document
    function handleClickOutside(event) {
      // Check if the click target is outside the container
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        // Clicked outside, close the sidebar
        // You may also want to check if the sidebar is open before closing it
        // to avoid unnecessary state updates.
        // if (open) {
        //   // Close the sidebar by calling a function from the parent component
        //   // that sets the "open" state to false
        //   handleSidebarClose();
        // }
      }
    }
    // Attach the event listener
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [open, handleSidebarClose]);

  function logOut() {
    clearLocalItems();
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('auxEmail');
    sessionStorage.removeItem('redux_user');
    sessionStorage.removeItem('redux_getPayment');
    sessionStorage.removeItem('idPart');
    sessionStorage.removeItem('refreshToken');
    history.push('/login');
  }

  return (
    <MainContaier open={open} ref={containerRef}>
      <Container
        style={{
          background: selectedSections['dashboard'] ? '#0D3564' : '#FFF',
        }}
        onClick={() => handleSectionClick('dashboard')}
      >
        <Imagen src={dashboard} alt='' />
        <Title
          style={{ color: selectedSections['dashboard'] ? '#FFF' : '#0D3564' }}
        >
          Dashboard
        </Title>
      </Container>
      {/* //! Configuración deshabilitado */}
      {/* <Container
        style={{
          background: selectedSections['configuracion'] ? '#0D3564' : '#FFF',
        }}
        onClick={() => handleSectionClick('configuracion')}
      >
        <Imagen
          src={selectedSections['configuracion'] ? configBlanco : engrane}
          alt=''
        />
        <Title
          style={{
            color: selectedSections['configuracion'] ? '#FFF' : '#0D3564',
          }}
        >
          Configuración
        </Title>
      </Container> */}
      {/* <Container>
        <Imagen src={iconUser} alt='' />
        <Title>Cuenta</Title>
      </Container> */}

      <Container
        style={{
          background: selectedSections['altaDistribuidor'] ? '#0D3564' : '#FFF',
        }}
        onClick={() => handleSectionClick('altaDistribuidor')}
      >
        <Imagen
          src={selectedSections['altaDistribuidor'] ? add : AltaDistribuidor}
          alt=''
        />
        <Title
          style={{
            color: selectedSections['altaDistribuidor'] ? '#FFF' : '#0D3564',
          }}
        >
          Alta de distribuidor
        </Title>
      </Container>
      <Container
        style={{
          background: selectedSections['adminMkt'] ? '#0D3564' : '#FFF',
        }}
        onClick={() => handleSectionClick('adminMkt')}
      >
        <Imagen src={selectedSections['adminMkt'] ? add : MarketPlace} alt='' />
        <Title
          style={{
            color: selectedSections['adminMkt'] ? '#FFF' : '#0D3564',
          }}
        >
          Admin Market Place
        </Title>
      </Container>
      <Container
        style={{
          background: selectedSections['descuentos'] ? '#0D3564' : '#FFF',
        }}
        onClick={() => handleSectionClick('descuentos')}
      >
        <Imagen
          src={selectedSections['descuentos'] ? DescuentosBlanco : descuentos}
          alt=''
        />
        <Title
          style={{ color: selectedSections['descuentos'] ? '#FFF' : '#0D3564' }}
        >
          Descuentos
        </Title>
      </Container>
      <Container>
        <LinkMkt href='/' target='_blank'>
          <Imagen src={MarketPlace} alt='' />
          <Title>Market Place</Title>
        </LinkMkt>
      </Container>
      <Container
        style={{ bottom: 0, position: 'relative' }}
        onClick={() => logOut()}
      >
        <Imagen src={logOutImg} alt='' />
        <Title>Cerrar sesión</Title>
      </Container>
    </MainContaier>
  );
}
