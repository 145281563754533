/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_USER_SEGMENT } from '../../../graphql/Query';
import { UPDATE_USER_SEGMENT } from '../../../graphql/Mutation';
import styled from 'styled-components';
import { Modal } from '@mui/material';
import { url } from '../../api';
const warning = `${url}MassiveDiscount.svg`;

const MassiveDiscountModal = ({
  open,
  setOpen,
  getAllUsers,
  onClose,
  index,
  selectedEmails,
}) => {
  const segment = sessionStorage.getItem('id');
  // const emails = sessionStorage.getItem('selectedEmails');
  // const email = sessionStorage.getItem('seleccionado');
  const [data, setData] = useState({
    id: '',
    segment: '',
  });
  const [selectedValue, setSelectedValue] = useState('');

  const [getSegments] = useLazyQuery(GET_USER_SEGMENT, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const segments = data.getUserSegment.map((item) => {
        return {
          segment: item.description,
          id: item.id_type_user_segment,
        };
      });
      setData(segments);
    },
    onError(err) {},
  });

  const [updateSegment] = useMutation(UPDATE_USER_SEGMENT, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      getAllUsers();
      setOpen(false);
    },
    onError(err) {},
  });

  const sendSegment = () => {
    let section = 0;
    switch (selectedValue) {
      case 'U1':
        section = 1;
        break;
      case 'U2':
        section = 2;
        break;
      case 'U3':
        section = 3;
        break;
      case 'U0':
        section = 4;
        break;
      default:
        break;
    }
    updateSegment({
      variables: {
        emails: selectedEmails,
        segment: section,
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    sendSegment();
    onClose([]);
  };

  useEffect(() => {
    getSegments();
  }, []);

  const handleSelect = (e) => {
    setSelectedValue(e.target.value);
  };

  sessionStorage.setItem('Segment', selectedValue);
  return (
    <>
      <Modal open={open}>
        <Layout>
          <ModalContent>
            <div
              onClick={handleClose}
              style={{
                position: 'absolute',
                top: '1rem',
                right: '1rem',
                fontSize: '24px',
                cursor: 'pointer',
                fontWeight: '800',
              }}
            >
              ✕
            </div>
            <TitleContainer>
              <WarningSign alt='' src={warning} />
              <Title>Administración de Descuentos Masivo</Title>
            </TitleContainer>
            <Subtitle>
              Estás a punto de realizar cambios en la sección actual:{' '}
              <strong>{segment}</strong>
              <br />
              <br />
              En este momento, los usuarios seleccionados en la sección{' '}
              <strong>{segment}</strong> disfrutan de un descuento del{' '}
              <strong>0%</strong>
              <br />
              <br />A continuación, podrás elegir a qué nueva sección deseas
              trasladar a los usuarios y asignarles un nuevo descuento.
            </Subtitle>
            <SelectorContainer>
              <SelectorLabel>Nueva sección</SelectorLabel>
              <StyledSelector
                onChange={handleSelect}
                name='segmentos'
                value={selectedValue}
              >
                <option selected defaultValue hidden>
                  Selecciona una sección
                </option>
                {data.length > 1 &&
                  data.map((item) => (
                    <option key={item.id} value={item.segment}>
                      {item.segment}
                    </option>
                  ))}
              </StyledSelector>
            </SelectorContainer>
            <Subtitle>
              <strong>
                ¿Estás seguro de que deseas proceder con esta acción?
              </strong>
            </Subtitle>
            <ButtonContainer>
              <ButtonEliminar onClick={handleClick}>
                Aplicar cambios
              </ButtonEliminar>
              <CancelarButton onClick={handleClose}>Cancelar</CancelarButton>
            </ButtonContainer>
          </ModalContent>
        </Layout>
      </Modal>
    </>
  );
};

export default MassiveDiscountModal;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 24px;
  box-shadow: 0px 8px 24px 0px rgba(0, 32, 74, 0.4);
  gap: 20px;
  width: 100%;
  max-width: 680px;
  align-items: center;
  position: relative;
  overflow: auto;
  max-height: 90%;
  scrollbar-width: thin; /* Anchura de la barra de desplazamiento */
  scrollbar-color: #888 #f0f0f0; /* Color de la barra y el fondo */

  ::-webkit-scrollbar {
    width: 8px; /* Anchura de la barra de desplazamiento en navegadores Webkit */
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(136, 136, 136, 0.5); /* Barra translúcida */
    border-radius: 4px; /* Bordes redondeados */
    height: 10%; /* Altura del 90% */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Fondo transparente */
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: fit-content;
`;

const WarningSign = styled.img`
  width: 45px;
`;

const Title = styled.h2`
  font-family: Mulish;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 38.4px */
  color: #0d3564;
  margin: 0;
  text-align: center;
  @media (max-width: 1023px) {
    font-size: 28px;
  }
`;

const Subtitle = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  color: #14181a;
  text-align: center;
`;

const ButtonEliminar = styled.button`
  display: flex;
  width: 194px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background-color: #0d3564;
  border-style: solid;
  border-width: 1px;
  border-color: #0d3564;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  cursor: pointer;
  @media (max-width: 1439px) {
    width: 100%;
  }
`;

const CancelarButton = styled.button`
  display: flex;
  width: 194px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #0d3564;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #0d3564;
  cursor: pointer;
  background-color: #ffffff;
  @media (max-width: 1439px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  @media (max-width: 1439px) {
    flex-direction: column;
  }
`;

const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-wrap: wrap;
  width: 100%;
`;

const SelectorLabel = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;

const StyledSelector = styled.select`
  width: 100%;
  height: 48px;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid #b9cad0;
  background: #ffffff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath stroke='%230E3665' stroke-linecap='round' stroke-linejoin='round' d='M6 6l4 4 4-4'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
  background-size: 40px;
`;
