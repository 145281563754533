import React, { useEffect, useState } from 'react';

//styles
import {
  MainContainer,
  Title,
  Divider,
  ButtonModal,
  TextContainer,
  LogoContainer,
  LogoModal,
  CarritoModal,
} from './ModalMenu.styles';
//libreries
import { useHistory } from 'react-router-dom';
import { url } from '../../../api';
//assets
const carrito = `${url}carrito.svg`;
const logo = `${url}BallenaLogoLarge.svg`;
export default function ModalMenu({ openSell }) {
  const history = useHistory();

  const [logged, setLogged] = useState(false);

  function logOut() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('auxEmail');
    sessionStorage.removeItem('redux_user');
    sessionStorage.removeItem('redux_getPayment');
    sessionStorage.removeItem('idPart');
    history.push('/login');
    setLogged(false);
  }

  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      setLogged(true);
    } else {
      setLogged(false);
    }
  }, []);
  return (
    <MainContainer>
      <TextContainer>
        <Title onClick={() => history.push('/store')}>Categorías</Title>
        <Divider></Divider>
      </TextContainer>
      <TextContainer>
        <Title onClick={() => history.push('/quienes-somos')}>
          ¿Quiénes somos?
        </Title>
        <Divider></Divider>
      </TextContainer>
      <TextContainer onClick={() => openSell(true)}>
        <Title>¿Quieres vender?</Title>
        <Divider></Divider>
      </TextContainer>
      {!logged && (
        <TextContainer>
          <Title
            onClick={() => history.push('/create-account')}
            style={{ width: '100%', textAlign: 'center' }}
          >
            Crear cuenta
          </Title>
        </TextContainer>
      )}

      {logged ? (
        <>
          <ButtonModal onClick={() => logOut()}>Cerrar sesión</ButtonModal>
        </>
      ) : (
        <ButtonModal onClick={() => history.push('/login')}>
          Iniciar sesión
        </ButtonModal>
      )}
    </MainContainer>
  );
}
