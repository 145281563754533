import styled from 'styled-components';

export const ModalBackdrop = styled.div`
  width: 100%;
  height: 100vh;
  display: ${(props) => (props.open ? 'flex' : 'none')};
  align-items: center;
  background-color: #000000b3;
  position: fixed;
  z-index: 1;
  top: 0px;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: 60%;
  height: 60%;
  z-index: 2;
  border-radius: 5px;
  @media (max-width: 991px) {
    width: 98%;
    height: 60%;
  }
`;
