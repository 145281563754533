import styled from "styled-components";

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap:40px;
    width:100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`

export const Image = styled.img`
    width: 768px;
    height: auto;
`

export const Title = styled.h2`
    font-family: Mulish;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 48px */
    color:#0E79B4
`

export const ButtonRegresar = styled.button`
display: flex;
width: 194px;
height: 40px;
padding: 8px 24px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
background: var(--primarydeep, #0D3564);
color:#FFF;
border-color: #0D3564;
border-width: 1px;
border-style: solid;
`