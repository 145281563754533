import { createSlice } from "@reduxjs/toolkit";

// const initialState = JSON.parse(
//   sessionStorage.getItem("redux_Filters") || JSON.stringify({})
// );
// export const saveStateFilter = (state) => {
//   //console.log("saver");
//   //console.log(state);
//   const serializedState = JSON.stringify(state);
//   sessionStorage.setItem("redux_Filters", serializedState);
// };
const initialState = JSON.parse(JSON.stringify({}));
export const saveStateFilter = (state) => {
  //console.log("saver");
  //console.log(state);
  // const serializedState = JSON.stringify(state);
};

export const filtersReducer = createSlice({
  name: "filters",
  initialState,
  reducers: {
    searchWithFilter: (state, action) => (
      //console.log(state),
      {
        state: action.payload,
      }
    ),
  },
});

export const { searchWithFilter } = filtersReducer.actions;

export default filtersReducer.reducer;
