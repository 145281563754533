import React from 'react';
//styles
import {
  ModalOverlay,
  ModalContent,
  ButtonEliminar,
  CancelarButton,
  Subtitle,
  Title,
  TitleContainer,
  ButtonContainer,
} from './ModalGracias.styles';
import { useHistory } from 'react-router-dom';
//assets

// redux

export default function ModalGracias({ show, setShow, clearData }) {
  const history = useHistory();
  const handleEnviar = () => {
    history.push(`/store`);
    setShow(false);
  };
  return (
    <ModalOverlay>
      <ModalContent>
        <TitleContainer>
          <Title>¡Gracias por tu interés!</Title>
        </TitleContainer>

        <Subtitle>
          Estamos emocionados por trabajar contigo y ayudarte a explorar nuevas
          oportunidades de venta.
        </Subtitle>
        <Subtitle>
          Puedes confiar en que tus datos están seguros, y en breve, un
          representante de Ballena se comunicará contigo.
        </Subtitle>
        <ButtonContainer>
          <ButtonEliminar onClick={handleEnviar}>Ir de compras</ButtonEliminar>
          <CancelarButton
            onClick={() => {
              setShow(false);
            }}
          >
            Cerrar
          </CancelarButton>
        </ButtonContainer>
      </ModalContent>
    </ModalOverlay>
  );
}
