import React from 'react';
import { Flex, Text } from '../../../../styles/common.styles';
import { url } from '../../../../api';
import desplegar from '../../../../assets/dropdownlightblue.svg';
export default function DesplegablePq({ openCard, setOpenCard, index }) {
  return (
    <>
      <Flex
        width='100%'
        direction='row'
        justify='space-between'
        align='center'
        padding='12px 0'
        onClick={() => setOpenCard(!openCard)}
      >
        <Text color='#32B7D7' family='Mulish' weight='700' size='24px'>
          Paquete {index + 1}
        </Text>
        <Flex direction='row' gap='8px' align='center'>
          <Text color='#32B7D7' family='Inter' size='16px'>
            {' '}
            {openCard ? 'Ocultar detalles' : 'Ver detalle'}
          </Text>
          <img
            src={desplegar}
            style={{
              transform: !openCard ? 'rotate(0deg)' : 'rotate(180deg)',
              cursor: 'pointer',
              width: '28px',
            }}
          />
        </Flex>
      </Flex>
      {!openCard && (
        <>
          <div
            style={{
              width: '100%',
              height: '1px',
              background: '#C7CACE',
              marginBottom: '8px',
            }}
          ></div>
        </>
      )}
    </>
  );
}
