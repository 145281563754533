import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    Flex,
    Text,
    Button,
} from '../../../../styles/common.styles';
import iconError from './iconError.svg';
import useShoppingCart from '../../../../../hooks/useShoppingCart';

const ModalShipmentError = ({ open, setOpen, info, items, setLoading, reLoadShipment }) => {
    const { itemsShoppingCart } = useShoppingCart()
    const [shipment, setShipment] = useState([])
    const [claves, setClaves] = useState([]);
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        let nuevoObjeto = {};
        items.forEach((x) => {
            if (!nuevoObjeto.hasOwnProperty(x.id_uac)) {
                nuevoObjeto[x.id_uac] = {
                    data: [],
                };
            }
            nuevoObjeto[x.id_uac].data.push(x);
        });
        let tempclaves = Object.keys(nuevoObjeto);
        setGroups(nuevoObjeto);
        setClaves(tempclaves);
    }, [items]);

    useEffect(() => {
        if (info.length > 0) {
            setShipment(info)
        }

    }, [info])
    // itemsShoppingCart.map((item)=> {

    // })
    return (
        <>
            <Modal open={open}>
                <Layout>
                    <ModalContent>
                        <Flex
                            //Parcial Form Container
                            width='100%'
                            gap='24px'
                            direction='row'
                            align='center'
                            justify='center'
                        >
                            <img src={iconError} alt='iconError' />
                            <Title>Lo sentimos</Title>
                        </Flex>
                        <Text
                            textAlign='center'
                        >
                            Se ha presentado un problema con los siguientes servicios de envío seleccionados:  <br />
                        </Text>
                        {
                            claves?.map((currentItems, index) =>  {
                                return shipment?.map((itemShipment) => {
                                    const error = groups[currentItems].data.filter((item) => item.id_uac === Number(itemShipment.id_uac))
                                    return error.length > 0 && <Text
                                        textAlign='center'
                                        weight='bold'
                                    >
                                        Paquete {index + 1} - {itemShipment?.nombre} {itemShipment?.costo}
                                    </Text>
                                })
                            })

                        }
                        <Text
                            textAlign='center'
                        >
                            Por favor, elija otra opción de envío para poder completar su pedido.  <br />
                        </Text>

                        <Flex
                            direction='row'
                            wisth='100%'
                            justify='center'
                            align='center'
                            gap='24px'
                        >
                            <Button width='200px' onClick={() => {
                                setOpen(false)
                                setLoading(true)
                                reLoadShipment()
                                }}>
                                Entiendo
                            </Button>
                        </Flex>
                    </ModalContent>
                </Layout>
            </Modal>
        </>
    );
};

export default ModalShipmentError;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 40px;
  box-shadow: 0px 8px 24px 0px rgba(0, 32, 74, 0.4);
  gap: 40px;
  width: 100%;
  max-width: 630px;
  max-height: 95vh;
  align-items: center;
  overflow-y: scroll;
  scrollbar-width: thin; /* Anchura de la barra de desplazamiento */
  scrollbar-color: #888 #f0f0f0; /* Color de la barra y el fondo */

  ::-webkit-scrollbar {
    width: 8px; /* Anchura de la barra de desplazamiento en navegadores Webkit */
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(136, 136, 136, 0.5); /* Barra translúcida */
    border-radius: 4px; /* Bordes redondeados */
    height: 10%; /* Altura del 90% */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Fondo transparente */
  }
`;

const Title = styled.h2`
  font-family: Mulish;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 38.4px */
  color: #0d3564;
  margin: 0;
  text-align: center;
`;

const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;