import styled from 'styled-components';

export const MainContainer = styled.div`
  position: relative;
  z-index: 3;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.backgroundColor || '#FFF'};
  padding: 1rem 1rem 1rem 2rem;
  margin-right: 0;
  @media (max-width: 1023px) {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    gap: 2rem;
    height: 136px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
  @media (max-width: 1023px) {
    width: 65%;
    order: 2;
    gap: 0;
  }
`;
export const ImgContainer = styled.div`
  padding-right: 0;
  margin-right: 0;
  width: auto;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1023px) {
    order: 1;
    padding-left: 1rem;
    width: 35%;
    justify-content: flex-start;
  }
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 28px;
  color: #fff;
  font-family: 'Inter';
`;

export const Description = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  color: #fff;
  font-family: 'Inter';
`;

export const Enlace = styled.p`
  font-size: 1rem;
  font-weight: 700;
  line-height: 25px;
  color: #fff;
  font-family: 'Inter';
  margin: 0;
`;

export const Imagen = styled.img`
  width: 100%;
  @media (max-width: 1023px) {
    width: ${(props) => (props.tamano === '60px' ? '60px' : '100px')};
    object-fit: cover;
  }
`;

export const VerMasContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const RightArrow = styled.img`
  width: 1.5rem;
`;
