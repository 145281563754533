import { createSlice } from '@reduxjs/toolkit';

const datosGeneralesSlice = createSlice({
  name: 'datosGenerales',
  initialState: {},
  reducers: {
    setDatosGenerales: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearDatosGenerales: (state) => {
      return {}; // Clear datosGenerales by setting it to an empty object
    },
  },
});

export const { setDatosGenerales, clearDatosGenerales } = datosGeneralesSlice.actions;
export default datosGeneralesSlice.reducer;

