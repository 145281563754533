/* eslint-disable */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Modal } from '@mui/material';
import { useMutation } from '@apollo/client';
import { UPDATE_DISCOUNT } from '../../../graphql/Mutation';
import { url } from '../../api';
const warning = `${url}MassiveDiscountConfirm.svg`;
const MassiveDiscountConfirmModal = ({
  open,
  setOpen,
  getAllUsers,
  setSelectAll,
  onClose,
  index,
  newDiscount,
}) => {
  // const id = sessionStorage.getItem('id');
  const [setDiscount] = useMutation(UPDATE_DISCOUNT, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      getAllUsers();
    },
    onError(err) {},
  });

  const segment = sessionStorage.getItem('id');
  const sendDiscount = () => {
    /* switch (id) {
      case 'U1':
        id = 1;
        break;
      case 'U2':
        id = 2;
        break;
      case 'U3':
        id = 3;
        break;
      case 'U0':
        id = 4;
        break;
      default:
        break;
    } */
    setDiscount({
      variables: {
        discount: parseInt(newDiscount),
        id: index,
      },
    });
    setOpen(false);
    setSelectAll(false);
    onClose([]);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal open={open}>
        <Layout>
          <ModalContent>
            <TitleContainer>
              <WarningSign alt='' src={warning} />
              <Title>Aplicación de Descuento Masivo</Title>
            </TitleContainer>
            <Subtitle>
              {/* Estás a punto de segmentar a tus usuarios en grupos específicos.
              <br />
              <br />
              Mediante esta acción, podrás:
              <br />
              <br />
              &#x2022; Asignar descuentos personalizados según el grupo al que
              pertenezcan tus usuarios.
              <br />
              &#x2022; Mover uno o más usuarios a grupos de tu elección.
              <br />
              <br />
              ¿Deseas proceder con esta operación? */}
              Estás a punto de aplicar un descuento del{' '}
              <strong>{newDiscount}%</strong> a todos los usuarios seleccionados
              en la sección <strong>{segment}</strong>.
              {/*  <strong>{id ? id : '' }</strong> */}
              <br />
              <br />
              ¿Estás seguro de que deseas continuar con esta acción?
            </Subtitle>
            <ButtonContainer>
              <ButtonEliminar onClick={sendDiscount}>
                Aplicar descuento
              </ButtonEliminar>
              <CancelarButton onClick={closeModal}>Cancelar</CancelarButton>
            </ButtonContainer>
          </ModalContent>
        </Layout>
      </Modal>
    </>
  );
};

export default MassiveDiscountConfirmModal;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 40px;
  box-shadow: 0px 8px 24px 0px rgba(0, 32, 74, 0.4);
  gap: 20px;
  width: 100%;
  max-width: 630px;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: fit-content;
`;

const WarningSign = styled.img`
  width: 64px;
`;

const Title = styled.h2`
  font-family: Mulish;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 38.4px */
  color: #0d3564;
  margin: 0;
  text-align: center;
`;

const Subtitle = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  color: #14181a;
  text-align: center;
`;

const ButtonEliminar = styled.button`
  display: flex;
  width: 194px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background-color: #0d3564;
  border-style: solid;
  border-width: 1px;
  border-color: #0d3564;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  cursor: pointer;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const CancelarButton = styled.button`
  display: flex;
  width: 194px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #0d3564;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #0d3564;
  cursor: pointer;
  background-color: #ffffff;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
`;
