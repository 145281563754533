import styled from "styled-components";

export const MainContainer = styled.div`
    display: flex;
    flex-direction:column;
    padding: 24px 80px;
    gap: 24px;
    align-self: stretch;
    backdrop-filter: blur(16px);
    background-color:#FFF;
    width:100%
`

export const LogoContainer = styled.div`
    display:flex;
    cursor: pointer;
`

export const MenuContainer = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center
`

export const BurgerContainer = styled.div`
    display:flex;
    gap:12px;
    align-items:center
`

export const NameContainer = styled.div`
    font-family: Mulish;
    display:flex;
    gap:1rem;
    justify-content:center;
    align-items:center;
`

export const BurgerText = styled.div`
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color:#0D3564;
    cursor: pointer;
`

export const NameText = styled.div`
    text-align: center;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color:#0D3564;
    cursor: pointer;
`
export const ButtonContainer = styled.div`
    display:flex;
    gap:12px;
    align-items:center
`
export const ButtonCerrarSesion = styled.button`
    display: flex;
    width: 254px;
    height: 40px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border-color: #0D3564;
    border-style:solid;
    background: var(--primarydeep, #0D3564);
    color: var(--neutral-white, #FFF);
    cursor: pointer;
    /* COMMON/Button */
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`

export const ImagenLogo = styled.img`
    width:166px;
    
`

export const Burger = styled.img`
    width:24px
`

export const ImagenUser = styled.img`
    width:17px
`

export const ImagenCarrito = styled.img`
   width: 29px
`