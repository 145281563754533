/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_OTP_CODE } from '../../graphql/Query';
import {
  RESET_PASSWORD,
  SEND_EMAIL_CODE_PASSWORD,
} from '../../graphql/Mutation';
import Nav from '../components/common/nav';
import Footer from '../components/common/footer';
import {
  Background,
  Flex,
  GeneralContainer,
  Text,
  StyledInput,
  StyledPassword,
  Button,
} from '../styles/common.styles';
import { url } from '../api';

import { Link } from 'react-router-dom/cjs/react-router-dom';
import ModalToShop from '../components/PasswordRecoveryModals/ModalToShop';
import Loader from '../components/common/Loader';
import ReactCodeInput from 'react-code-input';
const Bg = `${url}JPG/createAccountbg.jpg`;
const BgMobile = `${url}JPG/createAccountbgmobile.jpg`;
const personalDataIconn = `${url}personalDataIcon.svg`;
const errorImg = `${url}error.svg`;
const correctImg = `${url}correct.svg`;

const PasswordRecovery = () => {
  //Value de los inputs
  const [singleinputValue, setsingleInputValue] = useState('');
  const [codeError, setCodeError] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [disablePassword, setDisablePassword] = useState(true);
  const [disabledContinue, setDisabledContinue] = useState(true);
  const [validForm, setValidForm] = useState({
    password: '',
    passwordConfirm: '',
  });

  const handleCopy = (event) => {
    event.preventDefault();
    // Opcional: Mostrar un mensaje de advertencia al usuario
  };

  const validarContrasena = (contrasena) => {
    // Expresión regular para verificar la longitud mínima de 8 caracteres
    const longitudValida = contrasena.length >= 8;

    // Expresión regular para verificar una combinación de letras mayúsculas, minúsculas, números y caracteres especiales
    const combinacionValida =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/g.test(
        contrasena
      );

    setValidPassword(combinacionValida);

    return longitudValida && combinacionValida;
  };

  const [getCode] = useLazyQuery(GET_OTP_CODE, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (data.getOTPcode == 'Código correcto') {
        setDisablePassword(false);
        setCodeError(false);
      } else {
        //alert('El código ingresado es incorrecto');
        setCodeError(true);
        setInputValues([]);
      }
    },
    onError(err) {},
  });

  const [resetPassword] = useMutation(RESET_PASSWORD, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      // console.log('hola', data);
    },
    onError(err) {},
  });

  const [ResendEmail] = useMutation(SEND_EMAIL_CODE_PASSWORD, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      alert(`Error en peticion: ${error.message.split('GraphQL error: ')[1]}`);
    },
  });

  useEffect(() => {
    validarContrasena(validForm.password);
    if (
      validForm.password != '' &&
      validForm.passwordConfirm != '' &&
      validForm.password === validForm.passwordConfirm &&
      validarContrasena(validForm.password)
    ) {
      setDisabledContinue(false);
    } else {
      setDisabledContinue(true);
    }
  }, [validForm]);

  //! mock para loader-----
  const [loading, setLoading] = useState(false);

  const fetchData = async ({ success }) => {
    setLoading(true);

    try {
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Simula una carga de 2 segundos
    } catch (error) {
      console.error('Error al cargar los datos:', error);
    } finally {
      setLoading(false);
    }
  };

  const idUser = sessionStorage.getItem('IdUserMKP');

  const handleVerification = async () => {
    getCode({
      variables: {
        code: singleinputValue,
        idUser: idUser,
      },
    });
    await fetchData({
      success: () => {
        //Insertar acciones
      },
    });
  };

  const email = sessionStorage.getItem('Email');
  const handleIniciarSesion = async () => {
    resetPassword({
      variables: {
        password: validForm.passwordConfirm,
        email,
      },
    });
    await fetchData({
      success: () => {
        //Insertar acciones
      },
    });
    setModalOpen(true);
  };

  const handleClick = () => {
    ResendEmail({
      variables: {
        email,
      },
    });
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // Función para actualizar el estado con el ancho de pantalla actual
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };
  // Agregar el evento 'resize' cuando el componente se monta
  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);

    // Eliminar el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  //Indicar en inputValues '' por la cantidad de inputs deseada. Ejemplo: 2 inputs = ['','']
  const [inputValues, setInputValues] = useState([]);

  //Indicar en inputsRefs useRef(null) por la misma cantidad de inputsValues. Ejemplo inputsValue = ['',''], inputsRefs = [useRef(null), useRef(null)]
  const inputsRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  //logica
  const handleInputChange = (
    // index,
    value
  ) => {
    // const newInputValues = [...inputValues];
    // newInputValues[index] = value;
    // setInputValues(newInputValues);
    // if (value.length === 1 && index < inputsRefs.length - 1) {
    //   inputsRefs[index + 1].current.focus();
    // } else if (value.length === 0 && index > 0) {
    //   inputsRefs[index - 1].current.focus();
    // }
    //
    console.log(value, typeof value);
    setInputValues([value]);
  };

  //effects
  useEffect(() => {
    setsingleInputValue(inputValues.join(''));
  }, [inputValues]);

  useEffect(() => {
    validForm;
  }, [validForm]);

  const passwordError = () => {
    if (
      (validForm.password.length >= 8 && validPassword) ||
      validForm.password === ''
    ) {
      return false;
    } else {
      return true;
    }
  };

  const passwordConfirmError = () => {
    if (
      validForm.password === validForm.passwordConfirm ||
      validForm.passwordConfirm === ''
    ) {
      return false;
    } else {
      return true;
    }
  };

  const styles = {
    className: ReactCodeInput,
    inputStyle: {
      width: '14%',
      height: '48px',
      padding: '16px 8px',
      borderRadius: '8px',
      border: '1px solid #b9cad0',
      background: '#ffffff',
      fontFamily: 'Inter',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      outline: 'none',
      margin: '4px',
      textAlign: 'center',
    },
  };

  return (
    <>
      <Loader open={loading} />
      <ModalToShop open={modalOpen} />
      <Nav />
      <GeneralContainer style={{ alignItems: 'center' }}>
        {/*  Banner start */}
        <>
          <Flex
            width='100%'
            maxHeight='208px'
            height='208px'
            padding='40px 80px'
            align='center'
            justify='center'
            gap='32px'
            direction='column'
            style={{
              position: 'relative',
            }}
            mobile='height:fit-content; padding: 80px 24px; gap: 32px; max-height:100%;'
          >
            <Background
              style={{
                height: '100%',
                width: '100%',
              }}
            >
              <img
                src={screenWidth <= 1023 ? BgMobile : Bg}
                style={{ width: '100%', height: '100%' }}
              />
            </Background>
            <Flex
              align='center'
              direction='row'
              mobile='flex-direction:column;'
            >
              <Text
                size='48px'
                weight='900'
                color='#32B7D7'
                textAlign='center'
                mobile='font-size:40px;'
              >
                Restablecimiento&nbsp;
              </Text>
              <Text
                size='48px'
                weight='900'
                color='#ffffff'
                textAlign='center'
                mobile='font-size:40px;'
              >
                de contraseña
              </Text>
            </Flex>
            <Text size='24px' weight='800' color='#ffffff' textAlign='center'>
              Por favor, ingresa los datos que se te piden a continuación para
              recuperar tu cuenta.
            </Text>
          </Flex>
        </>
        {/*  Banner end */}
        {/*  Form start */}
        <Flex
          direction='column'
          gap='24px'
          width='100%'
          align='center'
          padding='3rem 2rem'
          maxWidth='476px'
        >
          <Flex
            //Title container
            gap='32px'
            direction='row'
            align='center'
            margin='0 0 16px'
            width='100%'
            justify='flex-start'
          >
            <img src={personalDataIconn} />
            <Text size='28px' weight='800' color='#0D3564'>
              Código de verificación
            </Text>
          </Flex>
          <Flex width='100%' maxWidth='412px' direction='column'>
            <Text family='Inter' size='12px' weight='400' textAlign='center'>
              Ingresa el código de 6 digitos que se ha enviado a tu correo
              electrónico
            </Text>
            <>
              <Flex
                direction='column'
                width='100%'
                height='48px'
                display='flex'
                gap='4px'
              >
                {/* {inputValues.map((value, index) => (
                  <StyledInput
                    style={{
                      textAlign: 'center',
                      border: codeError ? '1px solid red' : null,
                    }}
                    maxLength={1}
                    key={index}
                    ref={inputsRefs[index]}
                    value={value}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    disabled={!disablePassword}
                    id={`inputCode${index}`}
                  />
                ))} */}
              </Flex>
            </>
            <ReactCodeInput
              type='text'
              fields={6}
              {...styles}
              onChange={(e) => handleInputChange(e)}
            />
          </Flex>{' '}
          {codeError && (
            <Flex
              height='40px'
              direction='row'
              width='100%'
              align='center'
              gap='1rem'
            >
              <>
                <img
                  src={errorImg}
                  style={{ width: '12px', minWidth: '12px' }}
                />
                <Text size='12px' family='Inter' color='red'>
                  {/* Asegúrate de incluir una combinación de letras mayúsculas,minúsculas, números y caracteres especiales en tu contraseña. */}
                  El código que has introducido es incorrecto. Puedes usar la
                  opción Reenviar código e intenta una vez más.
                </Text>
              </>
            </Flex>
          )}
          {disablePassword && (
            <>
              <Button
                width='194px'
                onClick={() => handleVerification()}
                disabled={!disablePassword}
              >
                Validar código
              </Button>
              <>
                <Flex direction='row' width='100%' gap='20px' align='center'>
                  <div
                    style={{
                      width: '100%',
                      height: '1px',
                      background: '#8299A3',
                    }}
                  />
                  <Text family='Inter' size='12px' weight='400'>
                    Ó
                  </Text>
                  <div
                    style={{
                      width: '100%',
                      height: '1px',
                      background: '#8299A3',
                    }}
                  />
                </Flex>
              </>
              <Flex direction='column' align='center' width='100%' gap='8px'>
                <Text family='Inter' size='16px' weight='400' color='#505B69'>
                  ¿No te llego el correo electrónico?
                </Text>
                <Text
                  family='Mulish'
                  size='16px'
                  weight='800'
                  color='#0E79B4'
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={handleClick}
                >
                  Reenviar código
                </Text>
              </Flex>
            </>
          )}
          <Flex
            //Title container
            gap='32px'
            direction='row'
            align='center'
            margin='0 0 16px'
            width='100%'
            justify='flex-start'
          >
            <img src={personalDataIconn} />
            <Text size='28px' weight='800' color='#0D3564'>
              Nueva contraseña
            </Text>
          </Flex>
          <Flex direction='column' gap='4px' width='100%' align='flex-start'>
            <Text family='Inter' size='12px' weight='400'>
              Nueva contraseña
            </Text>
            <StyledPassword
              disabled={disablePassword}
              versiontwo
              placeholder={'Ingresa tu nueva contraseña'}
              handleChange={(e) => {
                setValidForm({ ...validForm, password: e.target.value });
              }}
              onCopy={handleCopy}
              error={passwordError()}
            />
          </Flex>
          <Flex direction='column' gap='4px' width='100%' align='flex-start'>
            <Text family='Inter' size='12px' weight='400'>
              Ingresa de nuevo la nueva contraseña
            </Text>
            <StyledPassword
              disabled={disablePassword}
              versiontwo
              placeholder={'Ingresa de nuevo tu nueva contraseña'}
              handleChange={(e) =>
                setValidForm({ ...validForm, passwordConfirm: e.target.value })
              }
              onCopy={handleCopy}
              error={passwordConfirmError()}
            />
          </Flex>
          <Flex width='100%' direction='column' gap='1rem'>
            {validForm.password === '' ? (
              <Text family='Inter' size='12px' weight='400'>
                Tu contraseña debe tener al menos 8 caracteres.
              </Text>
            ) : (
              <>
                <Flex direction='row' width='100%' align='center' gap='1rem'>
                  {validForm.password.length >= 8 ? (
                    <>
                      <img
                        src={correctImg}
                        style={{ width: '12px', minWidth: '12px' }}
                      />
                      <Text
                        family='Inter'
                        size='12px'
                        weight='400'
                        color='green'
                      >
                        Tu contraseña debe tener al menos 8 caracteres.
                      </Text>
                    </>
                  ) : (
                    <>
                      <img
                        src={errorImg}
                        style={{ width: '12px', minWidth: '12px' }}
                      />
                      <Text family='Inter' size='12px' weight='400' color='red'>
                        Tu contraseña debe tener al menos 8 caracteres.
                      </Text>
                    </>
                  )}
                </Flex>
              </>
            )}
            <Flex direction='row' width='100%' align='center' gap='1rem'>
              {validForm.password === '' ? (
                <Text family='Inter' size='12px' weight='400'>
                  Asegúrate de incluir una combinación de letras mayúsculas,
                  minúsculas, números y caracteres especiales en tu contraseña.{' '}
                </Text>
              ) : (
                <>
                  {validPassword ? (
                    <>
                      <img
                        src={correctImg}
                        style={{ width: '12px', minWidth: '12px' }}
                      />
                      <Text
                        family='Inter'
                        size='12px'
                        weight='400'
                        color='green'
                      >
                        Asegúrate de incluir una combinación de letras
                        mayúsculas, minúsculas, números y caracteres especiales
                        en tu contraseña.{' '}
                      </Text>
                    </>
                  ) : (
                    <>
                      <img
                        src={errorImg}
                        style={{ width: '12px', minWidth: '12px' }}
                      />
                      <Text family='Inter' size='12px' weight='400' color='red'>
                        Asegúrate de incluir una combinación de letras
                        mayúsculas, minúsculas, números y caracteres especiales
                        en tu contraseña.{' '}
                      </Text>
                    </>
                  )}
                </>
              )}
            </Flex>
          </Flex>
          {disablePassword || disabledContinue ? (
            <Button style={{ marginTop: '1rem' }} width='194px' disabled>
              Iniciar sesión
            </Button>
          ) : (
            <Button
              style={{ marginTop: '1rem' }}
              width='194px'
              onClick={() => handleIniciarSesion()}
            >
              Iniciar sesión
            </Button>
          )}
        </Flex>

        {/*  Form end */}
      </GeneralContainer>
      <Footer />
    </>
  );
};

export default PasswordRecovery;
