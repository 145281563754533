import React from 'react';
//styles
import {
  ModalOverlay,
  ModalContent,
  ButtonEliminar,
  CancelarButton,
  Subtitle,
  Title,
  TitleContainer,
  WarningSign,
  ButtonContainer,
} from './ModalDistribuidores.styles';
import { url } from '../../../api';
//assets
const warning = `${url}warnigSign.svg`;
// redux

export default function ModalDistribuidores({
  show,
  setShow,
  clearData,
  nameGroup,
}) {
  return (
    <ModalOverlay>
      <ModalContent>
        <TitleContainer>
          <WarningSign alt='' src={warning} />
          <Title style={{ textAlign: 'center' }}>Eliminar usuario</Title>
        </TitleContainer>

        <Subtitle>
          Estás a punto de eliminar un usuario del grupo distribuidor <br />{' '}
          <strong>{nameGroup.catalog_DistribuidorPadre.name_DisPadre}</strong>
        </Subtitle>
        <Subtitle>¿Estás seguro de que deseas eliminarlo?</Subtitle>
        <ButtonContainer>
          <ButtonEliminar
            onClick={() => {
              clearData({ idUser: nameGroup.id_uac });
            }}
          >
            Eliminar
          </ButtonEliminar>
          <CancelarButton onClick={() => setShow(!show)}>
            Cancelar
          </CancelarButton>
        </ButtonContainer>
      </ModalContent>
    </ModalOverlay>
  );
}
