export const capitalizeWords = (inputString) => {
  // Dividir el string en palabras
  const words = inputString.split(' ');

  // Mapear cada palabra y capitalizar la primera letra, convirtiendo las demás en minúsculas
  const formattedWords = words.map((word) => {
    if (word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    return word;
  });

  // Unir las palabras formateadas de nuevo en un string
  const formattedString = formattedWords.join(' ');

  return formattedString;
};
