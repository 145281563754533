import { useDispatch, useSelector } from 'react-redux';
import { getShoppingCart } from '../redux/reducers/shoppingCart.reducer';
import { useEffect, useState } from 'react';
import { SAVE_SHOPING_CART_PROGRESS } from '../graphql/Mutation';
import { useMutation } from '@apollo/client';
import { getUserInfo } from '../redux/reducers/user.reducer';

export default function useShoppingCart() {
  const dispatch = useDispatch();
  const infoUser = useSelector((state) => state.rootReducers.user);
  const parseData = infoUser.state
    ? JSON.parse(infoUser.state)
    : { getUserMarketplace: '' };
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const stateShoppingItems = useSelector(
    (state) => state.rootReducers.shoppingCart
  );

  const [saveProgressMutation] = useMutation(SAVE_SHOPING_CART_PROGRESS, {
    fetchPolicy: 'no-cache',
    onCompleted(saveShoppingCartProgress) {
      // const res = JSON.parse(saveShoppingCartProgress.saveShoppingCartProgress.response)
      setLoading(false);
    },
    onError(err) {
      setLoading(false);
    },
  });

  useEffect(() => {
    let parseData = stateShoppingItems.state
      ? JSON.parse(stateShoppingItems.state)
      : [];
    if (typeof parseData === 'string') {
      parseData = JSON.parse(parseData);
    }
    setItems(parseData ? parseData : []);
  }, [stateShoppingItems]);

  useEffect(() => {
    return () => {
      // limpieza del efecto
    };
  }, [items]);

  const updateItemsCart = async (items) => {
    setLoading(true);
    if (parseData?.getUserMarketplace?.cat_user_marketplace_id) {
      await saveProgressMutation({
        variables: {
          step: 'shopping_cart',
          info: JSON.stringify({
            items,
            idCustomer: parseData.getUserMarketplace.cat_user_marketplace_id,
          }),
        },
      });
    }
    dispatch(getShoppingCart(JSON.stringify(items)));
  };

  const updateItemsQuantity = async (item, quantity) => {
    const indiceElemento = items.findIndex((el) => el.id === item.id);
    let tempItems = [...items];
    tempItems[indiceElemento] = { ...tempItems[indiceElemento], qty: quantity };
    await updateItemsCart(tempItems);
  };

  const combineItems = async (itemsLocal, itemsDB) => {
    itemsDB.map((item) => {
      const add = itemsLocal.filter((el) => el.id === item.id);
      if (add.length === 0) {
        itemsLocal.push(item);
      }
    });
    await updateItemsCart(itemsLocal);
  };

  const deleteItem = async (id) => {
    const arrFilter = items.filter((item) => item.id !== id);
    await updateItemsCart(arrFilter);
  };

  const deleteBox = async (id) => {
    const arrFilter = items.filter((item) => item.id_uac !== id);
    await updateItemsCart(arrFilter);
  };

  const deleteAll = async (id) => {
    const arrFilter = [];
    await updateItemsCart(arrFilter);
  };

  const getItemsCart = async (items) => {
    dispatch(getShoppingCart(JSON.stringify(items)));
  };

  const clearLocalItems = async () => {
    dispatch(getShoppingCart(JSON.stringify([])));
    dispatch(getUserInfo(JSON.stringify([])));
  };

  return {
    itemsShoppingCart: items,
    updateItemsCart,
    updateItemsQuantity,
    deleteItem,
    deleteBox,
    getItemsCart,
    clearLocalItems,
    deleteAll,
    loading,
    combineItems,
  };
}
