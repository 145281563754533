import styled from "styled-components";

export const Container = styled.div`
width:100%;
`

export const Title = styled.h2`
  /* CSS styles go here */
  // font-family: Inter;
  font-size:1rem;
  font-weight: 700;
  line-height:19.2px;
`;

export const TermText = styled.p`
  /* CSS styles go here */
  // font-family: Inter;
  font-size:1rem;
  font-weight: 400;
  line-height:19.2px;
`;


