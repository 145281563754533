/* eslint-disable */
import { Modal } from '@mui/material';
import React from 'react';
import {
  Button,
  Flex,
  Text,
  Layout,
  ModalBox,
} from '../../styles/common.styles';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const Modal2 = ({ open }) => {
  return (
    <>
      <Modal open={open}>
        <Layout>
          <ModalBox>
            <Text size='32px' weight='800' color='#0D3564'>
              Correo de Recuperación Enviado
            </Text>
            <Flex direction='column' gap='16px' width='100%'>
              <Text
                family='Inter'
                size='16px'
                weight='400'
                textAlign='center'
                color='#14181A'
              >
                ¡Listo!{' '}
                <span style={{ fontWeight: '700', fontSize: '16px' }}>
                  Hemos enviado un correo electrónico a tu dirección
                  proporcionada con las instrucciones necesarias para
                  restablecer tu contraseña.
                </span>{' '}
                <br /> <br />
                Por favor, revisa tu bandeja de entrada y sigue los pasos
                indicados en el correo para recuperar el acceso a tu cuenta.
                <br /> <br /> Si no recibes el mensaje en unos minutos,
                asegúrate de verificar tu carpeta de correo no deseado o spam.
                ¡Gracias por tu paciencia
              </Text>
            </Flex>
            <Link to='/recover-password'>
              <Button width='217px'>Recuperar contraseña</Button>
            </Link>
          </ModalBox>
        </Layout>
      </Modal>
    </>
  );
};

export default Modal2;
