/* eslint-disable */
import React from 'react';
import QuienesSomos from '../components/MainPage/QuienesSomos';
import QuieresVender from '../components/MainPage/QuieresVender';
import { Flex } from '../styles/common.styles';
import Nav from '../components/common/nav';
import Footer from '../components/common/footer';
import { Finder } from '../components/Store/Finder/Finder';
import { MostWantedPieces } from '../components/Store/MostWantedPieces';
import MarketOptions from '../components/MainPage/MarketOptions';
import ChosePaqueteria from '../components/Carrito/Paqueteria/ChosePaqueteria';

//import ModalEscogerPaqueteria from '../components/Carrito/ModalEscogerPaqueteria';


const MainPage = () => {

  return (
    <>
    {/* <ModalEscogerPaqueteria/> */}
      <Nav />
      <Flex direction='column' width='100%' gap='4rem'>
        <Finder redirect={true}/>
        <MostWantedPieces />
        <QuienesSomos />
        <QuieresVender />
        {/* componente opciones de market place (bdr, 9-10)
        <MarketOptions /> */}
      </Flex>
      <Footer />
    </>
  );
};

export default MainPage;
