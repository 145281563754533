/* eslint-disable */
import React, { useEffect } from 'react';
import { Flex } from '../styles/common.styles';
import Nav from '../components/common/nav';
import Footer from '../components/common/footer';
import { Finder } from '../components/Store/Finder/Finder';
import CartSteps from '../components/CartComponent/CartSteps';

const Cart = () => {
  return (
    <>
      <Nav store />
      <Flex direction='column' width='100%' gap='4rem'>
        {/* <Finder redirect={true} /> */}
        <CartSteps />
      </Flex>
      <Footer />
    </>
  );
};

export default Cart;
