import React, { useEffect, useState } from 'react';
//styles
import {
  InnerContaierPrincipal,
  Divider,
  EscogeText,
  ParaCada,
} from './PaqueteriaElegir.styles';
import ResumenCompra from './ResumenCompra';
import ComponentePaquete from './ComponentePaquete';
import { stringToNumber } from '../../../../../helpers/shopCart';
import ScrollToTop from '../../../../../hooks/topHook';

export default function PaqueteriaElegir({
  setStepSelected,
  items,
  paqueterias,
  setIdEnvio,
  idEnvio,
  totalFailed,
}) {
  const [subTotal, setSubTotal] = useState(0);
  const [countItems, setCountItems] = useState(0);
  const [costoEnvio, setCostoEnvio] = useState('$0.00 MXN');

  useEffect(() => {
    setSubTotal(
      items.reduce(
        (accumulator, currentValue) =>
          accumulator + stringToNumber(currentValue.price) * currentValue.qty,
        0
      )
    );
    setCountItems(
      items.reduce(
        (accumulator, currentValue) => accumulator + currentValue.qty,
        0
      )
    );
  }, [items]);

  useEffect(() => {
    const temp = stringToNumber(costoEnvio) - totalFailed;
    setCostoEnvio(`$${temp} MXN`);
  }, [totalFailed]);

  /**============================================================= */
  const [acceptedTerms, setAcceptedTerms] = useState(
    Array(paqueterias.length).fill(false)
  );

  const handleAcceptTerms = async (index, value) => {
    // Create a promise for updating the state
    const updatePromise = new Promise((resolve) => {
      setAcceptedTerms((prevAcceptedTerms) => {
        const updatedAcceptedTerms = [...prevAcceptedTerms];
        updatedAcceptedTerms[index] = value;
        resolve(updatedAcceptedTerms);
        return updatedAcceptedTerms;
      });
    });
    await updatePromise;
  };

  return (
    <>
      <ScrollToTop />
      <EscogeText>Escoge el servicio de paquetería</EscogeText>
      <ParaCada>Para cada producto</ParaCada>
      <Divider></Divider>
      <InnerContaierPrincipal>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          {paqueterias.map((currentBox, index) => {
            const tempShipments = currentBox.filter(Boolean);
            const filterItems = items.filter(
              (el) => el.id_uac === Number(tempShipments[0].id_distribuidor)
            );
            return (
              filterItems && (
                <ComponentePaquete
                  items={items}
                  setStepSelected={setStepSelected}
                  paqueterias={currentBox.filter(Boolean)}
                  setCostoEnvio={setCostoEnvio}
                  costoEnvio={costoEnvio}
                  setIdEnvio={setIdEnvio}
                  idEnvio={idEnvio}
                  index={index}
                  totalFailed={totalFailed}
                  handleAcceptTerms={handleAcceptTerms}
                />
              )
            );
          })}
        </div>

        <ResumenCompra
          items={items}
          setStepSelected={setStepSelected}
          subTotal={subTotal}
          countItems={countItems}
          costoEnvio={costoEnvio}
          acceptedTerms={acceptedTerms}
        />
      </InnerContaierPrincipal>
    </>
  );
}
