import styled from "styled-components";

export const MainContainer = styled.div`
    display: flex;
    width: 100%;
    padding: 24px;
    flex-direction: column;
    gap: 40px;
    border-radius: 8px;
    background-color:#FFF;
    box-shadow: 0px 20px 54px 0px rgba(13, 53, 100, 0.15);
 
  
`

export const TitleContainer = styled.div`
    display:flex;
    gap:8px;
    align-items:center;
    @media (max-width: 1023px) {
    
 
  }
`

export const Imagen = styled.img`
    width:32px;
    @media (max-width: 1023px) {
     
  }    
`

export const ImagenArrow = styled.img`
    display:none;
    @media (max-width: 1023px) {
     width:32px;
     display:flex;
     margin-left: auto;
  }    
`

export const Title = styled.h2`
    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
    color:#0D3564;
    margin:0;
    @media (max-width: 1023px) {

  }    
`

export const SubContainer = styled.div`
    display:flex;
    gap:24px;
    @media (max-width: 1023px) {
    flex-direction: column;
    width:100%;
    margin:0 auto
  }
`

export const Container = styled.div`
    display:flex;
    width:50%;
    flex-direction:column;
    gap:1rem;
    @media (max-width: 1023px) {
    flex-direction: column;
    width:100%;
  
  }
`

export const InputContainer = styled.div`
    width:100%;
    margin-bottom: 1rem;
`

export const InputLabel = styled.p`
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    color:#55646A;
    margin:0
`

export const InputConfig = styled.input`
display: flex;
height: 48px;
padding: 16px 8px;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: 8px;
border: 1px solid #B9CAD0;
background: var(--neutral-very-light-gray, #F4F4F4);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 19.2px */
color : #8299A3;
width:100%
`
export const ErrorMsg = styled.p`
    color:red
`
