/* eslint-disable */
import { Modal } from '@mui/material';
import React from 'react';
import { Layout } from '../../styles/common.styles';
import { url } from '../../api';
const LoaderGif = `${url}Gifs/Loader.gif`;

const Loader = ({ open }) => {
  return (
    <>
      <Modal open={open}>
        <Layout>
          <img
            src={LoaderGif}
            style={{ width: '30vw', maxWidth: '250px', height: 'auto' }}
          />
        </Layout>
      </Modal>
    </>
  );
};

export default Loader;
