import React from 'react';
import Nav from '../common/nav';
import QuienesSomosP from '../../../screens/QuienesSomos/QuienesSomosP';
import Footer from '../common/footer';
import Placa from '../../../front2/assets/Placa.svg';

export default function QuienesSomosPage() {
  return (
    <div>
      <Nav store Quienes />
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          padding: '0 4rem',
        }}
      >
        <img style={{ width: '100%' }} src={Placa} />
      </div>
      <QuienesSomosP />
      <Footer />
    </div>
  );
}
