import { createSlice } from "@reduxjs/toolkit";

const initialState = JSON.parse(JSON.stringify({}));
export const saveStateApiPegasus = (state) => {
  console.log("saver");
  console.log(state);
};

export const apiPegasusReducer = createSlice({
  name: "pegasusName",
  initialState,
  reducers: {
    getValueApiPegasus: (state, action) => (
      // console.log(state),
      {
        state: action.payload,
      }
    ),
  },
});

export const { getValueApiPegasus } = apiPegasusReducer.actions;

export default apiPegasusReducer.reducer;
