import { createSlice } from "@reduxjs/toolkit";


const initialState = JSON.parse(
  sessionStorage.getItem('redux_shipmentSelected') || '[]'
);

export const loadState = () => {
  try {
    return JSON.parse(sessionStorage.getItem('redux_shipmentSelected') || '[]');
  } catch (err) {
    return [];
  }
};

export const saveShipmentSelected = (state) => {
  const serializedState = JSON.stringify(state);
  localStorage.setItem('redux_shipmentSelected', serializedState);
};

export const shipmentSelectedReducer = createSlice({
  name: "shipmentSelected",
  initialState,
  reducers: {
    getShipmentSelected: (state, action) => (
      // console.log(state),
      {
        state: action.payload,
      }
    ),
  },
});

export const { getShipmentSelected } = shipmentSelectedReducer.actions;

export default shipmentSelectedReducer.reducer;
