/* eslint-disable */
import React, { useState } from 'react';
import { Modal } from '@mui/material';
import { Button, Flex, Text } from '../../styles/common.styles';

//router
import { useHistory } from 'react-router-dom';
import { capitalizeWords } from '../../Formats/Formats';

const NewAccountModal = ({ open, validForm }) => {
  const history = useHistory();
  return (
    <>
      <Modal open={open}>
        <Flex
          width='100vw'
          height='100vh'
          align='center'
          justify='center'
          padding='40px'
        >
          <Flex
            bg='#ffffff'
            extras='border-radius: 16px; box-shadow: 0px 8px 24px 0px rgba(0, 32, 74, 0.40);'
            padding='40px'
            gap='40px'
            direction='column'
            maxWidth='630px'
            align='center'
          >
            <Text size='32px' weight='800' color='#0D3564' textAlign='center'>
              ¡Felicidades, {capitalizeWords(validForm.firstName)}! Tu cuenta en
              Ballena ha sido creada con éxito. 🎉
            </Text>
            <Text
              size='16px'
              weight='400'
              color='#14181A'
              textAlign='center'
              family='Inter'
            >
              Estamos emocionados de tenerte como parte de nuestra comunidad.
              <br />
              <br />
              Queremos recordarte que, si deseas recibir facturas de tus
              compras, por favor,
              <span style={{ fontWeight: '700' }}>
                {' '}
                envíanos un correo electrónico a facturas@ballena.com.mx{' '}
              </span>
              con el asunto "Solicitud de facturas".
              <br />
              <br />
              Estaremos encantados de atenderte.
            </Text>
            <Flex
              direction='row'
              align='center'
              justify='center'
              gap='24px'
              mobile='flex-direction:column;'
            >
              <Button
                onClick={() => history.push('/store')}
                mobile='width:100%;'
                extras='width:200px;'
              >
                Ir de compras
              </Button>
              <Button
                onClick={() => history.push('/login')}
                mobile='width:100%;'
                extras='width:200px;'
                secondary
              >
                Regresar al inicio
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default NewAccountModal;
