import { createSlice } from "@reduxjs/toolkit";

const initialState = JSON.parse(JSON.stringify({}));

export const saveStateTB = (state) => {
  // const serializedState = JSON.stringify(state);
};

export const tbReducer = createSlice({
  name: "filters",
  initialState,
  reducers: {
    changeTasa: (state, action) => ({
      state: action.payload,
    }),
  },
});

export const { changeTasa } = tbReducer.actions;

export default tbReducer.reducer;
