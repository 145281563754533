import React from 'react';
//styles
import {
  MainContainer,
  Title,
  AltaContainer,
  ButtonAlta,
  ButtonContainer,
  Imagen,
} from './TitleAlta.styles';
import { url } from '../../../api';
//assets
const altaTitle = `${url}altaTitle.svg`;
export default function TitleAlta({
  setNewGroup,
  setGroupDone,
  newGroup,
  groupDone,
}) {
  const handleGruop = () => {
    setNewGroup(true);
    setGroupDone(false);
  };

  const handleGruopDone = () => {
    setNewGroup(false);
    setGroupDone(true);
  };

  return (
    <MainContainer>
      <AltaContainer>
        <Imagen src={altaTitle} alt='' />
        <Title>Alta de usuarios</Title>
      </AltaContainer>
      <ButtonContainer>
        <ButtonAlta
          onClick={handleGruop}
          style={{
            backgroundColor: newGroup ? '#0E79B4' : '#0D3564',
            borderColor: newGroup ? '#0E79B4' : '#0D3564',
          }}
        >
          Nuevo grupo
        </ButtonAlta>
        <ButtonAlta
          onClick={handleGruopDone}
          style={{
            backgroundColor: groupDone ? '#0E79B4' : '#0D3564',
            borderColor: groupDone ? '#0E79B4' : '#0D3564',
          }}
        >
          Grupo existente
        </ButtonAlta>
      </ButtonContainer>
    </MainContainer>
  );
}
