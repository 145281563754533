import styled from 'styled-components';

export const NavRow = styled.div`
  display: flex;
  flex-direction:row;
  width:100%
  align-items:center;
  justify-content:center;
  
`;
export const NavCont = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 1440px;
  gap: 80px;
  padding: 16px 80px;
  justify-content: space-between;
  @media (max-width: 1023px) {
    ${(props) => props.mobile}
  }
`;

export const OptionsRow = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
`;

export const Button = styled.button`
  display: flex;
  width: 194px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--primarydeep, #0d3564);
  background: var(--neutral-white, #0d3564);
  color: var(--primarydeep, #fff);

  /* COMMON/Button */
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Text = styled.div`
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: #0d3564;
`;
