/* eslint-disable */
import { Modal } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { Flex, Text, Button } from '../../../styles/common.styles';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const FinishModal = ({ open, setOpen }) => {
  return (
    <>
      <Modal open={open}>
        <Layout>
          <ModalContent>
            <Title>¡Gracias por tu interés!</Title>
            <Text>
              Estamos emocionados por trabajar contigo y ayudarte a explorar
              nuevas oportunidades de venta.
              <br />
              <br />
              Puedes confiar en que tus datos están seguros, y en breve, un
              representante de Ballena se comunicará contigo.
            </Text>

            <Flex
              direction='row'
              wisth='100%'
              justify='center'
              align='center'
              gap='24px'
              mobile='flex-direction:column;'
            >
              {' '}
              <Link to='/store'>
                <Button width='190px' onClick={() => setOpen(false)}>
                  Ir de compras
                </Button>
              </Link>
              <Button width='190px' secondary onClick={() => setOpen(false)}>
                Cerrar
              </Button>
            </Flex>
          </ModalContent>
        </Layout>
      </Modal>
    </>
  );
};

export default FinishModal;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 40px;
  box-shadow: 0px 8px 24px 0px rgba(0, 32, 74, 0.4);
  gap: 40px;
  width: 100%;
  max-width: 630px;
  max-height: 95vh;
  align-items: center;
  overflow-y: scroll;
  scrollbar-width: thin; /* Anchura de la barra de desplazamiento */
  scrollbar-color: #888 #f0f0f0; /* Color de la barra y el fondo */

  ::-webkit-scrollbar {
    width: 8px; /* Anchura de la barra de desplazamiento en navegadores Webkit */
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(136, 136, 136, 0.5); /* Barra translúcida */
    border-radius: 4px; /* Bordes redondeados */
    height: 10%; /* Altura del 90% */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* Fondo transparente */
  }
`;

const Title = styled.h2`
  font-family: Mulish;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 38.4px */
  color: #0d3564;
  margin: 0;
  text-align: center;
`;

const Layout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;
