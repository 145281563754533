export const stringToNumber = (value) => {
    if (!value) {
        return 0
    }
    let number = value;
    if (number.includes('$')) {
        number = number.replace('$', "");
    }
    if (number.includes('MXN')) {
        number = number.replace('MXN', '');
    }
    if (number.includes(',')) {
        number = number.replace(',', '');
    }
    return Number(number)
}

export const formatNumberWithTwoDecimals = (number) => {
    // Check if the number is an integer
    if (Number.isInteger(number)) {
        return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    } else {
        return parseFloat(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
}