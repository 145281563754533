import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  display:flex;
  flex-direction:column;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 40px;
  box-shadow: 0px 8px 24px 0px rgba(0, 32, 74, 0.40);
  gap:20px;
  width: 630px;

`;

export const TitleContainer = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    gap:1rem
`

export const WarningSign = styled.img`
    width:38px
`

export const Title = styled.h2`
    font-family: Mulish;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%; /* 38.4px */
    color:#0D3564;
    margin:0
`

export const Subtitle = styled.p`
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    color:#14181A;
    
`

export const ButtonEliminar = styled.button`
    display: flex;
    width: 194px;
    height: 40px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background-color:#0D3564;
    border-style: solid;
    border-width:1px;
    border-color: #0D3564;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color:#FFF;
    cursor: pointer;
`

export const CancelarButton = styled.button`
    display: flex;
    width: 194px;
    height: 40px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid  #0D3564;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color : #0D3564;
    cursor: pointer;

`

export const ButtonContainer = styled.div`
    display: flex;
    width:100%;
    justify-content: center;
    align-items:center;
    gap:1.5rem
`
