import React from 'react';
import { Redirect, Route } from 'react-router';

const PrivateRoute = ({children, ...propierties}) => {
    const token = sessionStorage.getItem('token')

    if (token) {
        return <Route {...propierties}>{children}</Route>
    } else {
        return <Redirect to="/unauthorized" />
    }
}

export default PrivateRoute;