import React from 'react'
import HeroLogIn from '../../components/common/LogInV2/HeroLogIn'
import LogInForm from '../../components/common/LogInV2/LogInForm.jsx/LogInForm'
import Nav from '../../components/common/nav'
import Footer from '../../components/common/footer'
// import ModalSelectUser from '../../components/InicioSesion/ModalSelectUser'

export const LogInV2 = () => {
  return (
    <div>
        <Nav/>
        <HeroLogIn/>
        <LogInForm/>
        <Footer/>
    </div>
  )
}
