import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 20px 54px 0px rgba(13, 53, 100, 0.15);
  padding: 24px;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
  // justify-content:flex-start;
  align-items: center;
`;

export const Title = styled.h2`
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  color: #0d3564;
  margin: 0;
`;

export const Imagen = styled.img`
  width: 32px;
`;

export const Contaier = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  @media (max-width: 1439px) {
    ${({ column }) => column && 'flex-direction: column;'}
  }
`;

export const InputAlta = styled.input`
  display: flex;
  width: 100%;
  height: 48px;
  padding: 16px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #b9cad0;
  background-color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
`;

export const ButtonCrear = styled.button`
  display: flex;
  width: 194px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background-color: #0d3564;
  border-style: solid;
  border-color: #0d3564;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
`;

export const ContainerEstatus = styled.div``;

export const LabelGeneral = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  color: #061222;
  font-family: Inter;
`;

export const ContainerButtons = styled.div`
  display: flex;
  width: 100%;
`;

export const ButtonActive = styled.button`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  border-radius: 12px 0px 0px 12px;
  color: #8299a3;
  background-color: #dcdede;
  display: flex;
  width: 50%;
  height: 40px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
  border: none;
  outline: none;
  &.active {
    background-color: #41b770; // Cambia este color al que prefieras
    color: #fff;
  }
`;

export const ButtonInactive = styled.button`
  display: flex;
  width: 50%;
  height: 40px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  color: #8299a3;
  border-radius: 0px 12px 12px 0px;
  background-color: #dcdede;
  cursor: pointer;
  border: none;
  outline: none;
  &.active {
    background-color: red; // Cambia este color al que prefieras
    color: #fff;
  }
`;

export const SelectAlta = styled.select`
  display: flex;
  width: 100%;
  height: 48px;
  padding: 0 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 8px;
  border: 1px solid #b9cad0;
  background-color: #fff;
  outline: none;

  &:not(:disabled) {
    color: grey;
  }
`;

export const ButtonAdd = styled.button`
  display: flex;
  width: 68px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background-color: #0d3564;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  border-color: #0d3564;
  border-style: solid;
  cursor: pointer;
`;
