import styled from 'styled-components';

export const TitleContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
`;

export const TitleImg = styled.img`
  width: 40px;
`;

export const Title = styled.h2`
  font-family: Mulish;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 48px */
  color: #0d3564;
  margin: 0;
  @media (max-width: 1023px) {
    font-size: 32px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  height: fit-content;
  transition: height 1s;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 20px 54px 0px rgba(13, 53, 100, 0.15);
  width: 90vw;
  @media (min-width: 1023px) {
    width: 100%;
  }
`;

export const RowColor = styled.div`
  &:nth-child(even) {
    background-color: #f0f0f0; /* Cambia este color según tus preferencias */
  }

  /* Estilo para elementos impares */
  &:nth-child(odd) {
    background-color: #ccc; /* Cambia este color según tus preferencias */
  }
`;

export const SubtitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SubtitleContainerText = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const ImgSubtitle = styled.img`
  width: 38px;
`;

export const Subtitle = styled.h4`
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  color: #0d3564;
  margin: 0;
`;

export const ArrowDown = styled.img`
  width: 18px;
`;

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  padding: 10px 10px 32px;
  align-items: center;
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 16px; /* Anchura de la barra de desplazamiento en navegadores Webkit */
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background-color: #b9cad0; /* Barra translúcida */
    border-radius: 16px; /* Bordes redondeados */
    width: 4px;
    margin: 4px;
    background-clip: content-box;
    border: 5px solid transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: #eff3f8;
    border-radius: 16px;
  }
`;

export const ImgGrid = styled.img`
  width: 24px;
`;

export const GridTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 16.8px */
  color: #55646a;
  padding: 1rem;
  margin: 0;
  height: 100%;
  border-bottom: 3px solid #c5cad1;
`;

export const GridText = styled.div`
  display: flex;
  align-items: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  color: #14181a;
  padding: 1rem;
  margin: 0;
  border-bottom: 2px solid #eff3f8;
  height: 100%;
`;

export const ImgEdit = styled.img`
  width: 20px;
`;

export const Divider = styled.div`
  height: 1px;
  background: var(--neutral-mist, #dcdede);
`;
export const InputContainer = styled.div`
  width: 100%;
  max-width: 300px;
  @media (max-width: 1023px) {
    max-width: 100%;
  }
`;

export const LabelInput = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  color: #061222;
`;

export const InputDescuento = styled.input`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  color: #8299a3;
  display: flex;
  height: 48px;
  padding: 16px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-cloud, #b9cad0);
  background: ${({ disabled }) => (disabled ? '#dcdede' : null)};
  width: 100%;
  outline: none;
`;

export const ContainerBottom = styled.div`
  display: flex;
  gap: 24px;
  align-items: end;
  width: 100%;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;
export const ButtonDescuento = styled.button`
  display: flex;
  width: fit-content;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border-color: #0d3564;
  border-style: solid;
  border-width: 1px;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${({ secondary }) => (secondary ? '#0D3564' : '#fff')};
  background-color: ${({ secondary }) => (secondary ? '#fff' : '#0D3564')};
  ${({ disabled }) =>
    disabled
      ? 'background-color:  #dcdede; border: 1px solid #B9CAD0;color: #8299A3;'
      : null}
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

export const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
`;
