import React from 'react'
//styles
import { MainContainer,ButtonDelete,Container,Imagen,OptionCotainer,Title,TitleContainer, OptionText } from './DistribuidoresAdd.styles'
import { url } from '../../../api'
//assets
const Empresas = `${url}Empresas.svg`
export default function DistribuidoresAdd({opciones, eliminarOpcion}) {
  return (
    <MainContainer>
        <TitleContainer>
            <Imagen src={Empresas} alt=''/>
            <Title>Distribuidores</Title>
        </TitleContainer>
         
      {opciones.map((opcion, index) => (
        <Container key={index}>
         <OptionCotainer><OptionText>Valor seleccionado {index + 1}: {opcion}</OptionText></OptionCotainer> 
          <ButtonDelete onClick={() => eliminarOpcion(index)}>-</ButtonDelete>
        </Container>
      ))}
      
    </MainContainer>
  )
}
