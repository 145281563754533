import { createSlice } from '@reduxjs/toolkit';

const formDataSlice = createSlice({
  name: 'formData',
  initialState: {},
  reducers: {
    setFormData: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearFormData: (state) => {
      return {}; // Clear formData by setting it to an empty object
    },
  },
});

export const { setFormData, clearFormData } = formDataSlice.actions;
export default formDataSlice.reducer;
