import React from 'react'
//syles
import { Title, TermText, Container } from './ReuseTerm.styles'
export const ReuseTerms = ({title, term}) => {
  return (
    <Container>
        <Title>{title}</Title>
        <TermText>{term}</TermText>
    </Container>
  )
}
