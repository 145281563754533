import { createSlice } from "@reduxjs/toolkit";


const initialState = JSON.parse(
  sessionStorage.getItem('redux_getPayment') || '[]'
);

export const loadState = () => {
  try {
    return JSON.parse(sessionStorage.getItem('redux_getPayment') || '[]');
  } catch (err) {
    return [];
  }
};

export const savePaymentStatus = (state) => {
  const serializedState = JSON.stringify(state);
  sessionStorage.setItem('redux_getPayment', serializedState);
};

export const paymentStatusReducer = createSlice({
  name: "paymentStatus",
  initialState,
  reducers: {
    getPaymentStatus: (state, action) => (
      // console.log(state),
      {
        state: action.payload,
      }
    ),
  },
});

export const { getPaymentStatus } = paymentStatusReducer.actions;

export default paymentStatusReducer.reducer;
