/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Card, Flex, Text } from '../../styles/common.styles';
import { url } from '../../api';
const CarBg = `${url}JPG/CarBg.jpg`;
const EquipmentIcon = `${url}OriginalEquipmentIcon.svg`;
const PiecesIcon = `${url}PiecesIcon.svg`;
const Pricetag = `${url}Pricetag.svg`;

const QuienesSomos = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Función para actualizar el estado con el ancho de pantalla actual
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  // Agregar el evento 'resize' cuando el componente se monta
  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);

    // Eliminar el evento cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  return (
    <>
      <Flex direction='row' width='100%' position='relative'>
        {screenWidth >= 1024 ? (
          <img
            src={CarBg}
            style={{
              width: '50%',
              position: 'absolute',
              height: '400px',
              top: '0',
              left: '0',
              zIndex: '-1',
            }}
          />
        ) : null}
        <Flex
          direction='column'
          align='flex-end'
          width='100%'
          padding='20px 80px'
          gap='40px'
          minHeight='400px'
          mobile='align-items:center; padding:20px 24px'
        >
          <Text size='40px' weight='800' color='#0D3564'>
            ¿Quiénes somos?
          </Text>
          <Flex
            width='100%'
            gap='24px'
            height='100%'
            mobile='flex-direction: column;'
          >
            <Card
              padding='32px 48px 32px 24px'
              align='flex-start'
              gap='16px'
              height='100%'
              width='100%'
              direction='row'
              mobile='flex-direction: column;'
            >
              <img
                src={EquipmentIcon}
                style={{ width: '64px', height: '64px' }}
              />
              <Flex direction='column' gap='8px'>
                <Text
                  size='24px'
                  weight='700'
                  color='#14181A'
                  lineHeight='120%'
                >
                  Equipo 100% original
                </Text>
                <Text
                  size='16px'
                  weight='400'
                  color='#55646A'
                  family='Inter'
                  lineHeight='120%'
                >
                  Somos la refaccionaria virtual de equipo original más grande
                  de México, con más de 1 millón de productos y más de 4
                  millones de aplicaciones, todos ellos cumpliendo los más altos
                  estándares de calidad. Todo esto a tu disposición.
                </Text>
              </Flex>
            </Card>
            <Card
              padding='32px 48px 32px 24px'
              align='flex-start'
              gap='16px'
              height='100%'
              width='100%'
              direction='row'
              mobile='flex-direction: column;'
            >
              <img src={PiecesIcon} style={{ width: '64px', height: '64px' }} />
              <Flex direction='column' gap='8px'>
                <Text
                  size='24px'
                  weight='700'
                  color='#14181A'
                  lineHeight='120%'
                >
                  Piezas exactas{' '}
                </Text>
                <Text
                  size='16px'
                  weight='400'
                  color='#55646A'
                  family='Inter'
                  lineHeight='120%'
                >
                  Encuentra la pieza exacta que tu vehículo necesita con total
                  confianza. Garantizamos productos originales de fábrica,
                  envíos a precios justos y cambios o devoluciones sin costo
                  adicional.
                </Text>
              </Flex>
            </Card>
            <Card
              padding='32px 48px 32px 24px'
              align='flex-start'
              gap='16px'
              height='100%'
              width='100%'
              direction='row'
              mobile='flex-direction: column;'
            >
              <img src={Pricetag} style={{ width: '64px', height: '64px' }} />
              <Flex direction='column' gap='8px'>
                <Text
                  size='24px'
                  weight='700'
                  color='#14181A'
                  lineHeight='120%'
                >
                  Precios competitivos{' '}
                </Text>
                <Text
                  size='16px'
                  weight='400'
                  color='#55646A'
                  family='Inter'
                  lineHeight='120%'
                >
                  Tu destino para refacciones y accesorios de la industria
                  automotriz. Contamos con el catálogo más extenso de partes
                  nuevas y originales, gracias a nuestra red de proveedores,
                  agencias automotrices, que ofrecen inventarios a precios
                </Text>
              </Flex>
            </Card>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default QuienesSomos;
