/* eslint-disable */
import React from 'react';
import { Modal } from '@mui/material';
import {
  Button,
  Flex,
  Text,
  Layout,
  ModalBox,
} from '../../styles/common.styles';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const ModalToShop = ({ open }) => {
  return (
    <>
      <Modal open={open}>
        <Layout>
          <ModalBox>
            <Text size='32px' weight='800' color='#0D3564' textAlign='center'>
              Tu contraseña se ha restablecido con éxito
            </Text>
            <Flex direction='column' gap='16px' width='100%'>
              <Text
                family='Inter'
                size='16px'
                weight='400'
                textAlign='center'
                color='#14181A'
              >
                <span style={{ fontWeight: '700', fontSize: '16px' }}>
                  ¡Felicidades! Tu contraseña ha sido restablecida con éxito.
                </span>
                <br />
                <br />
                Ahora puedes acceder nuevamente a tu cuenta con tu nueva
                contraseña. <br />
                Si realizaste este cambio por error o necesitas realizar alguna
                otra acción, no dudes en contactarnos para brindarte la
                asistencia necesaria. <br />
                <br />
                Recuerda mantener tu contraseña segura y no compartirla con
                nadie más. <br />
                Siempre estamos aquí para ayudarte en caso de cualquier duda o
                inconveniente. <br />
                <br />
                ¡Gracias por confiar en nosotros!
              </Text>
            </Flex>
            <Link to='/login'>
              <Button width='217px'>Ir de compras</Button>
            </Link>
          </ModalBox>
        </Layout>
      </Modal>
    </>
  );
};

export default ModalToShop;
