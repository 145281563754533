import styled from "styled-components";

export const MainContainer = styled.div`
    display: flex;
    padding: 40px 24px 80px 24px;
    flex-direction: column;
    gap: 40px;
    flex: 1 0 0;
    background-color: #EFF3F8;
`

export const Subcontainer = styled.div`
    display: flex;
    gap:24px
`