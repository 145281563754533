/* eslint-disable */
import React from 'react';
import { Modal } from '@mui/material';
import {
  Button,
  Flex,
  Layout,
  ModalBox,
  Text,
  UList,
} from '../../../styles/common.styles';

import { url } from '../../../api';

const warning = `${url}warnigSign.svg`;

const UncompleteModal = ({ open, setOpen }) => {
  return (
    <>
      <Modal open={open}>
        <Layout>
          <ModalBox>
            <Flex gap='16px'>
              <img src={warning} />
              <Text size='32px' weight='800' color='#0D3564'>
                Datos incompletos{' '}
              </Text>
            </Flex>
            <Flex gap='16px' direction='column' width='100%' align='center'>
              <Text
                family='Inter'
                size='16px'
                weight='400'
                color='#14181A'
                width='100%'
                textAlign='left'
              >
                Lo sentimos, no podemos proceder con la publicación del
                producto.
              </Text>
              <Text
                family='Inter'
                size='16px'
                weight='400'
                color='#14181A'
                width='100%'
                textAlign='left'
              >
                Información pendiente:
              </Text>
              <UList family='Inter' size='16px' weight='700' color='#14181A'>
                <li>Volumetria</li>
                <li>Costos</li>
              </UList>
              <Text
                family='Inter'
                size='16px'
                weight='400'
                color='#14181A'
                width='100%'
                textAlign='left'
              >
                Por favor, verifique los campos faltantes y vuelva a intentarlo.
              </Text>
            </Flex>
            <Flex align='center' gap='24px' justify='center'>
              <Button width='194px' onClick={() => setOpen(false)}>
                Entendido
              </Button>
            </Flex>
          </ModalBox>
        </Layout>
      </Modal>
    </>
  );
};

export default UncompleteModal;
