import React from 'react'

//styles
import { MainContainer, Title, Subtitle, TitleWhite, Bgcolor } from './HeroLogIn.styles';
import { url } from '../../../api';
//assets
const heroimg = `${url}heroNewimg_.svg`
const heroimgmb = `${url}heroimgmb_.svg`
const Bg = `${url}JPG/createAccountbg.jpg`;
export default function HeroLogIn() {
    const isMobile = window.innerWidth <= 768;
  return (
    <div>
        <MainContainer style={{ backgroundImage: `url(${isMobile ? heroimgmb: Bg})`, backgroundSize: 'cover' }}>
            <Bgcolor></Bgcolor>
            <Title>Inicia sesión en <TitleWhite>Ballena</TitleWhite></Title>
            <Subtitle>Por favor ingresa los datos que se te piden a continuación para ingresar a tu cuenta</Subtitle>
        </MainContainer>
    </div>
  )
}
