import { Privacy } from '../../../screens/Privacy/Privacy';
import Footer from '../common/footer';
import Nav from '../common/nav';

/* eslint-disable */
const AvisoPrivacidad = () => {
  return (
    <>
      <Nav store />
      <Privacy />
      <Footer />
    </>
  );
};

export default AvisoPrivacidad;
