import { createSlice } from "@reduxjs/toolkit";


const initialState = JSON.parse(
  localStorage.getItem('redux_shoppingCart') || '[]'
);

export const loadState = () => {
  try {
    return JSON.parse(localStorage.getItem('redux_shoppingCart') || '[]');
  } catch (err) {
    return [];
  }
};

export const saveShoppingCart = (state) => {
  const serializedState = JSON.stringify(state);
  localStorage.setItem('redux_shoppingCart', serializedState);
};

export const shoppingCartReducer = createSlice({
  name: "shoppingCart",
  initialState,
  reducers: {
    getShoppingCart: (state, action) => (
      // console.log(state),
      {
        state: action.payload,
      }
    ),
  },
});

export const { getShoppingCart } = shoppingCartReducer.actions;

export default shoppingCartReducer.reducer;
