import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  background-color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 24px;
  padding-right: 24px;
  position: absolute;
  top: 89.97px;
  background: #fff;
  z-index: 1;
  border-radius: 0 0 16px 16px;
  box-shadow: 0px 16px 20px 0px #b9b2b287;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 44px;
`;

export const LogoModal = styled.img`
  width: 122.54px;
  height: 40px;
`;

export const CarritoModal = styled.img`
  width: 29.772px;
  height: 26.795px;
  flex-shrink: 0;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
`;

export const Title = styled.p`
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #0d3564;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dcdede;
`;

export const ButtonModal = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 8px 24px;
  border-radius: 8px;
  gap: 8px;
  align-self: stretch;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  background-color: #0d3564;
  box-shadow: none;
  outline: none;
  border: none;
`;
