import React from 'react'
//styles
import { TitleContainer, Imagen, Title } from './ConfigTitle.styles'
import { url } from '../../../api'
//assets
const engrane = `${url}engrane.svg`
export default function ConfigTitle() {
  return (
    <TitleContainer>
      <Imagen src={engrane} alt=''/>
        <Title>Configuración</Title>
    </TitleContainer>
  )
}
