import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	province: '',
	city: '',
	zip: '',
	street: '',
	number: '',
	company: '',
	address2: '',
	phone: '',
};

export const shipmentReducer = createSlice({
	name: 'shipment',
	initialState,
	reducers: {
		addShipmentSettings: (state, action) => action.payload,
	},
});

export const { addShipmentSettings } = shipmentReducer.actions;

export default shipmentReducer.reducer;
