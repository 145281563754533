import styled from "styled-components";

export const MainContainer = styled.div`
    display: flex;
    width: 873px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    background: var(--neutral-white, #FFF);
    box-shadow: 0px 20px 54px 0px rgba(13, 53, 100, 0.15);
    font-family: Mulish;

`

export const TitleContainer = styled.div`
    background-color: #0E79B4;
    padding:32px;
    display:flex;
    justify-content: space-around;;
    width:100%

`

export const TitleGrid = styled.p`
    font-weight: 700;
    color:#0D3564;
    margin:0
`

export const Title = styled.h2`
    margin:0;
    color: #fff;;
`

export const TitleSeleccionar = styled.h2`
    margin:0;
    color: #fff;
    font-weight: 700;
`


export const InnerTitleContainer = styled.div`
    display: flex;
    gap:16px;
    align-items: center;
`

export const BodyText = styled.p`
    color: var(--neutral-night, #55646A);
    /* COMMON/Body */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    width:70%;
    margin:0
`

export const NoSelect = styled.p`
    color: var(--neutral-storm, #8299A3);
    /* COMMON/Body */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    margin:0
`

export const TotalAmount = styled.p`
    color: var(--primaryocean, #0E79B4);
    text-align: right;
    /* COMMON/Title */
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 21.6px */
    margin:0
`

export const EnvioText = styled.p`
    color: var(--neutral-night, #55646A);
    /* COMMON/Body_Small */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin:0
`
export const BodyCard = styled.div`
    display: flex;
    gap:8px;
    align-items:center
    
`

export const InnerContaier = styled.div`
    display: flex;
    gap:8px;
    padding:0 32px;
    align-items: center;
    
`
export const EliminarText = styled.p`
    color: var(--primarydeep, #0D3564);
/* COMMON/Button */
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin:0;
    cursor: pointer;
`

export const ExistenciaContainer = styled.div`
    display: flex;
    justify-content:space-around;
    width:100%
`

export const EnvioContainer = styled.div`
    display: flex;
    gap:16px;
    margin:0;
    align-items:center
`

export const Divisor = styled.div`
    width:80%;
    height:1px;
    background-color: gray;
`

export const DivisorContainer = styled.div`
    width:100%;
    display:flex;
    justify-content: center;

`

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    width:100%;
    justify-content: space-around;
    padding-bottom: 24px;
    
`

export const ButtonComprar = styled.button`
display: flex;
width: 254px;
height: 40px;
padding: 8px 24px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
border: 1px solid var(--primarydeep, #0D3564);
background: var(--neutral-white, #FFF);
color: var(--primarydeep, #0D3564);
/* COMMON/Button */
font-family: Mulish;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
cursor: pointer;
`

export const DotCantidad = styled.div`
    width: 30px;
    height: 30px;
    background-color: #EFF3F8;
    border-radius: 50%;
    display:flex ;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

export const DotText = styled.p`
   color: #55646a;;
   margin: 0%;
`