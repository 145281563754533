import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  padding: 32px;
  background-color: #eff3f8;
  @media (max-width: 1023px) {
    padding: 32px 32px;
  }
`;

export const InnerContaier = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const Title = styled.p`
  color: var(--primarydeep, #0d3564);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  margin-bottom: 16px;
`;

export const GridContaier = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-content: center;
  gap: 32px;
  row-gap: 8px;
  overflow-x: auto;
  width: 100%;
  padding: 0 0 16px;
  ::-webkit-scrollbar {
    height: 16px; /* Anchura de la barra de desplazamiento en navegadores Webkit */
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background-color: #b9cad0; /* Barra translúcida */
    border-radius: 16px; /* Bordes redondeados */
    width: 4px;
    margin: 4px;
    background-clip: content-box;
    border: 5px solid transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 16px;
  }
  //scrollbar styles
`;

export const GridTextTile = styled.p`
  color: var(--primarydeep, #0d3564);

  /* COMMON/Caption */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
`;
export const DescriptionText = styled.p`
  /* COMMON/Caption */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  color: #55646a;
  margin: 0;
  text-align: left;
`;

export const CantidadText = styled.p`
  color: var(--neutral-storm, #8299a3);
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;

export const PrecioText = styled.p`
  color: var(--neutral-night, #55646a);

  /* COMMON/Caption */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  margin: 0;
  width: fit-content;
  text-wrap: nowrap;
`;
export const SubtotalText = styled.p`
  color: var(--neutral-night, #55646a);
  text-align: right;

  /* COMMON/Body_Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
`;

export const SubtotalGrayContainer = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
`;

export const AmountGray = styled.p`
  color: var(--neutral-night, #55646a);
  text-align: right;

  /* COMMON/Button */
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 170px;
  min-width: 170px;
`;

export const AmountEnvio = styled.p`
  color: var(--neutral-night, #55646a);
  text-align: right;

  /* COMMON/Body */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  width: 170px;
  min-width: 170px;
`;

export const SubotalBold = styled.p`
  color: #022741;
  text-align: right;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
`;

export const AmountBold = styled.p`
  color: var(--primaryocean, #0e79b4);
  text-align: right;

  /* COMMON/Title */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 21.6px */
  width: 170px;
  min-width: 170px;
`;
