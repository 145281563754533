import {
	useDispatch,
	useSelector
} from "react-redux";
import { useEffect, useState } from "react";
import { getDirectionCart } from "../redux/reducers/direccionShipment.reducer";

export default function useDirectinCart() {
	const dispatch = useDispatch();
	const [items, setItems] = useState([]);
	const stateDirectionCart = useSelector((state) => state.rootReducers.directionCart);

	useEffect(() => {
		let parseData = stateDirectionCart?.state ? JSON.parse(stateDirectionCart.state) : []
		if (typeof parseData === 'string') {
			parseData = JSON.parse(parseData)
		}
		setItems(parseData ? parseData : [])
	}, [stateDirectionCart])

	const updatedirectionCart = async (items) => {
		dispatch(getDirectionCart(JSON.stringify(items)));
	}


	return {
		directionShoppingCart: items,
		updatedirectionCart,
	}
}
