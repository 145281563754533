/* eslint-disable */

import { useEffect, useState } from 'react';
import useScript from './useScript';
import { useMutation } from '@apollo/client';
import {
  SAVE_SHOPING_CART_PROGRESS,
  SELL_SHOPPING_CART,
} from '../graphql/Mutation';
import { useSelector } from 'react-redux';
import { formConfig } from '../front2/components/CartComponent/MercadoPago/formConfig';
import useShipmentCart from './useShippment';
import useShoppingCart from './useShoppingCart';
import { stringToNumber } from '../helpers/shopCart';
//router
import { useHistory } from 'react-router-dom';
import usePaymentStatus from './usePaymentStatus';
import useUser from './useUser';

export default function useMercadoPago() {
  const history = useHistory();
  const { ShippmentId, shippmentsAvailables } = useShipmentCart();
  const { itemsShoppingCart } = useShoppingCart();
  const { updateStatusPayment } = usePaymentStatus();
  const { user } = useUser();

  const [resultPayment, setResultPayment] = useState(undefined);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState('0');
  const [ShipmentsByDistribuidor, setShipmentsByDistribuidor] = useState([]);
  const [sendSell] = useMutation(SELL_SHOPPING_CART);
  const items = useSelector((state) => state.rootReducers.shoop);
  // const shipmentDirectionValues = useSelector(
  // 	(state) => state.rootReducers.shipment
  // );

  const shipmentChoices = useSelector(
    (state) => state.rootReducers.shipmentChoices
  );

  const { MercadoPago } = useScript(
    'https://sdk.mercadopago.com/js/v2',
    'MercadoPago',
    errors
  );

//   useEffect(() => {
// 	if (ShippmentId) {
// 		let keys = Object.keys(ShippmentId);
// 		// keys.map((key) =>( 
// 		// 	ShippmentId[key]
// 		// ))
// 		const arrShipmentsByDistribuidor = []
// 		if (keys) {
// 			const sub = itemsShoppingCart.reduce((accumulator, currentValue) => accumulator + stringToNumber(currentValue.price) * currentValue.qty, 0);
// 			let envio = 0;
// 			keys.map((key) => {
// 				envio = envio + stringToNumber(ShippmentId[key].selectedShipment.costo)
// 				arrShipmentsByDistribuidor.push({
// 					ShippmentId: ShippmentId[key].idShipment,
// 					idUac: ShippmentId[key].filterItems[0].id_uac,
// 					arrItems: ShippmentId[key].filterItems,
// 					selectedShipment: ShippmentId[key].selectedShipment,
// 					labelUrl: ShippmentId[key].labelUrl,
// 					tracking_number: ShippmentId[key].tracking_number,
// 					nombre: ShippmentId[key].nombre,
// 					servicio: ShippmentId[key].servicio,
// 				})
// 			})
// 			setShipmentsByDistribuidor(arrShipmentsByDistribuidor)
// 			const total1 = (sub + envio + 0.000).toFixed(2)
// 			setTotal(total1)
// 		}
// 	}

  useEffect(() => {
    if (ShippmentId) {
      let keys = Object.keys(ShippmentId);
      // keys.map((key) =>(
      // 	ShippmentId[key]
      // ))
      const arrShipmentsByDistribuidor = [];
      if (keys) {
        const sub = itemsShoppingCart.reduce(
          (accumulator, currentValue) =>
            accumulator + stringToNumber(currentValue.price) * currentValue.qty,
          0
        );
        let envio = 0;
        keys.map((key) => {
          envio =
            envio + stringToNumber(ShippmentId[key].selectedShipment.costo);
          arrShipmentsByDistribuidor.push({
            ShippmentId: ShippmentId[key].idShipment,
            idUac: ShippmentId[key].filterItems[0].id_uac,
            arrItems: ShippmentId[key].filterItems,
            selectedShipment: ShippmentId[key].selectedShipment,
            labelUrl: ShippmentId[key].labelUrl,
            tracking_number: ShippmentId[key].tracking_number,
			nombre: ShippmentId[key].nombre,
			servicio: ShippmentId[key].servicio,
          });
        });
        setShipmentsByDistribuidor(arrShipmentsByDistribuidor);
        const total1 = (sub + envio + 0.0).toFixed(2);
        setTotal(total1);
      }
    }
    // return total.toString;
  }, [itemsShoppingCart, ShippmentId]);
  const getTotalAmount = () => {};

  const getVariables = () =>
    items.map((item) => ({
      ID: item.ID,
      id_parte: item.id_parte,
      id_grupo: item.id_grupo,
      id_distribuidor: item.id_distribuidor,
      email: item.email,
      Proveedor: item.Proveedor,
      Precio_de_Venta: item.Precio_de_Venta.toString(),
      cuantity: item.cuantity,
    }));

  const getDateParsed = (date) =>
    `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

  // (async function getIdentificationTypes() {
  // 	try {
  // 		const mp = new MercadoPago(
  // 			process.env.PUBLIC_KEY_MP || 'TEST-be69a611-9be1-4166-bf0a-53458a06ed5e'
  // 		);
  // 		console.log('getIdentificationTypes...........')
  // 		const identificationTypes = await mp.getIdentificationTypes();
  // 		console.log({ identificationTypes })
  // 		const identificationTypeElement = document.getElementById('form-checkout__identificationType');
  // 		console.log({ identificationTypeElement })

  // 		createSelectOptions(identificationTypeElement, identificationTypes);
  // 	} catch (e) {
  // 		return console.error('Error getting identificationTypes: ', e);
  // 	}
  // })();

  // function createSelectOptions(elem, options, labelsAndKeys = { label: "name", value: "id" }) {
  // 	try {
  // 		console.log('createSelectOptions...........')
  // 		console.log({
  // 			elem, options, labelsAndKeys
  // 		})
  // 		const { label, value } = labelsAndKeys;

  // 		elem.options.length = 0;

  // 		const tempOptions = document.createDocumentFragment();

  // 		options.forEach(option => {
  // 			const optValue = option[value];
  // 			const optLabel = option[label];

  // 			const opt = document.createElement('option');
  // 			opt.value = optValue;
  // 			opt.textContent = optLabel;

  // 			tempOptions.appendChild(opt);
  // 		});

  // 		elem.appendChild(tempOptions);
  // 	} catch (err) {
  // 		console.log({ err })
  // 	}

  // }
  const updateInfoPayment = async (res) => {
    await updateStatusPayment(res);
    history.push('/store/cart/resultPayment');
    setLoading(false);
  };
  const [saveProgressMutation] = useMutation(SAVE_SHOPING_CART_PROGRESS, {
    fetchPolicy: 'no-cache',
    onCompleted(saveShoppingCartProgress) {
      const res = JSON.parse(
        saveShoppingCartProgress.saveShoppingCartProgress.response
      );
      // if (res.statusPayment.includes('Succes confirm shipment')) {
      updateInfoPayment(res);
      // }
    },
    onError(err) {
      setLoading(false);
      console.log(err);
    },
  });

  useEffect(() => {
    if (MercadoPago) {
      const mp = new MercadoPago(
        process.env.PUBLIC_KEY_MP || 'TEST-7987c3e4-7677-45f6-808e-030977d49f5e'
      );
      const cardForm = mp.cardForm({
        amount: total,
        autoMount: true,
        form: formConfig,
        callbacks: {
          onFormMounted: (error) => {
            if (error)
              return console.warn('Form Mounted handling error: ', error);
          },

          onSubmit: async (event) => {
            event.preventDefault();

			

	// 			// }
	// const updateInfoPayment = async (res) => {
	// 	await updateStatusPayment(res)
	// 	history.push('/store/cart/resultPayment')
	// 	setLoading(false);
	// }
	// const [saveProgressMutation] = useMutation(SAVE_SHOPING_CART_PROGRESS, {
	// 	fetchPolicy: 'no-cache',
	// 	onCompleted(saveShoppingCartProgress) {
	// 		const res = JSON.parse(saveShoppingCartProgress.saveShoppingCartProgress.response)
	// 		// if (res.statusPayment.includes('Succes confirm shipment')) {
	// 		updateInfoPayment(res)
	// 		// }
	// 	},
	// 	onError(err) {
	// 		setLoading(false)
	// 		console.log(err)
	// 	},
	// });


            const cardNumber = event.target[0].value;
            const fullName = event.target[4].value;

            const {
              paymentMethodId: payment_method_id,
              issuerId: issuer_id,
              cardholderEmail: email,
              amount,
              token,
              installments,
              identificationNumber,
              identificationType,
            } = cardForm.getCardFormData();

            const arrParts = itemsShoppingCart.map((item) => {
              return {
                id: item.id,
                quantity: item.qty,
              };
            });

            const shipmentsId = [
              {
                shipmentId: ShippmentId,
                idParts: arrParts,
              },
            ];
            const data = {
              cardNumber,
              fullName,
              payment_method_id,
              issuer_id,
              email,
              amount,
              token,
              installments,
              identificationNumber,
              identificationType,
              shipmentsId: ShipmentsByDistribuidor,
              fecha: getDateParsed(new Date()),
              fourFinalCardNumbers: cardNumber.trim().slice(-4),
              idCustomer: user.cat_user_marketplace_id,
              // itemsShoppingCart,
            };
            if (amount < 10)
              setErrors((prev) => [...prev, 'El monto minimo son 10 pesos']);
            if (email === '')
              setErrors((prev) => [...prev, 'El email es obligatorio']);
            if (!cardNumber)
              setErrors((prev) => [...prev, 'Seleccione una tarjeta']);
            if (!fullName)
              setErrors((prev) => [...prev, 'El nombre es obligatorio']);

            // Object.keys(shipmentDirectionValues).forEach((element) => {
            // 	if (
            // 		shipmentDirectionValues[element] == '' &&
            // 		element !== 'reference' &&
            // 		element !== 'company'
            // 	) {
            // 		console.log(element);
            // 		setErrors((prev) => [
            // 			...prev,
            // 			`El campo ${errorsParsed[element]} es obligatorio`,
            // 		]);
            // 	}
            // });
            setLoading(true);
            if (errors.length === 0) {
              await saveProgressMutation({
                variables: {
                  step: 'pay',
                  info: JSON.stringify(data),
                },
              });
            }

            // const shipmentChoicesParsed = Object.keys(shipmentChoices).map(
            // 	(choice) => {
            // 		return {
            // 			name: shipmentChoices[choice].provider,
            // 			service: shipmentChoices[choice].service_level_code,
            // 			cuantity: shipmentChoices[choice].cuantity,
            // 			id_parte: choice,
            // 		};
            // 	}
            // );

            // const errorsParsed = {
            // 	street: 'calle',
            // 	number: 'numero de domicilio',
            // 	zip: 'codigo postal',
            // 	city: 'ciudad',
            // 	province: 'estado',
            // 	address2: 'colonia',
            // 	phone: 'celular',
            // };

            // if (amount < 10)
            // 	setErrors((prev) => [...prev, 'El monto minimo son 10 pesos']);
            // if (email == '')
            // 	setErrors((prev) => [...prev, 'El email es obligatorio']);
            // if (!cardNumber)
            // 	setErrors((prev) => [...prev, 'Seleccione una tarjeta']);
            // if (!fullName)
            // 	setErrors((prev) => [...prev, 'El nombre es obligatorio']);

            // Object.keys(shipmentDirectionValues).forEach((element) => {
            // 	if (
            // 		shipmentDirectionValues[element] == '' &&
            // 		element !== 'reference' &&
            // 		element !== 'company'
            // 	) {
            // 		console.log(element);
            // 		setErrors((prev) => [
            // 			...prev,
            // 			`El campo ${errorsParsed[element]} es obligatorio`,
            // 		]);
            // 	}
            // });

            if (errors.length === 0) {
              // const {
              // 	province,
              // 	city,
              // 	zip,
              // 	street,
              // 	number,
              // 	address2,
              // 	phone,
              // 	reference,
              // 	company,
              // } = shipmentDirectionValues;
              // const { data } = await sendSell({
              // 	variables: {
              // 		token,
              // 		issuer_id,
              // 		payment_method_id,
              // 		items: getVariables(),
              // 		userToken: localStorage.getItem('token'),
              // 		installments: installments | 1,
              // 		description: 'Cart products from ballena',
              // 		amount,
              // 		email,
              // 		fullName,
              // 		province,
              // 		city,
              // 		zip,
              // 		street,
              // 		number,
              // 		address2,
              // 		phone,
              // 		reference,
              // 		company,
              // 		identification: {
              // 			type: identificationType,
              // 			number: identificationNumber,
              // 		},
              // 		shipmentChoices: shipmentChoicesParsed,
              // 		fecha: getDateParsed(new Date()),
              // 		fourFinalCardNumbers: cardNumber.trim().slice(-4),
              // 	},
              // 	fetchPolicy: 'no-cache',
              // });
              // setResultPayment(data.setSellShoppingCart);
              // setLoading(false);
            }
          },
        },
      });
    }
  }, [MercadoPago]);

  return [resultPayment, loading, errors];
}
