import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  box-shadow: 4px 4px 15px 0px rgba(13, 53, 100, 0.15);
  color: #fff;
  @media (max-width: 1439px) {
    flex-direction: row-reverse;
  }
`;

export const LogoImg = styled.img`
  width: 193px;
  @media (max-width: 1439px) {
    width: 122.54px;
  }
`;

export const ImagenContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

export const Imagen = styled.img`
  width: 24px;
`;
