import React from 'react';
//styles
import {
  MainContainer,
  Caminon,
  Days,
  ColumnContainer,
  GridTitle,
  ModalBackdrop,
  PaqueteriaName,
  Price,
  Service,
  Title,
  TitleContainer,
  ContaierPaqueteria,
  ButtonCancelar,
  ButtonConfirmar,
  ButtonContainer,
} from './ModalEscogerPaqueteria.style';
import { useState } from 'react';
import useShipmentCart from '../../../../hooks/useShippment';
import { Radio, styled } from '@mui/material';
// import { PAQUETE } from './Paquetes.constant'
import { Flex } from '../../../styles/common.styles';

import { url } from '../../../api';
const camion = `${url}whiteTruck.svg`;

const CustomRadio = styled(Radio)(() => ({
  '&.MuiButtonBase-root': {
    color: '#32B7D7',
  },
}));

export default function ModalEscogerPaqueteria({
  show,
  setShow,
  setValueShipping,
  paqueterias,
  setCostoEnvio,
  setIdEnvio,
  idEnvio,
  filterItems,
  setSelectedByModal,
}) {
  const [valorEnvio, setValorEnvio] = useState({});
  const { addShipmentComplete, shippmentsAvailables } = useShipmentCart();
  const handleOptionChange = (el) => {
    setValorEnvio(el);
  };

  const handleSeleccionar = () => {
    setSelectedByModal(true);
    const shipmentsAvailables = { ...idEnvio };
    shipmentsAvailables[filterItems[0].id_uac] = {
      paqueterias,
      idShipment: valorEnvio.id_shipment,
      selectedShipment: valorEnvio,
      filterItems,
      isAvailable: false,
    };
    setValueShipping(valorEnvio);
    setCostoEnvio(valorEnvio.costo);
    setIdEnvio(shipmentsAvailables);
    setShow(!show);
  };

  return (
    <ModalBackdrop>
      <MainContainer>
        <TitleContainer>
          <Caminon src={camion} />
          <Title>Escoge el servicio de envío para tu producto</Title>
        </TitleContainer>
        <div
          style={{
            width: '100%',
            height: '1px',
            background: '#E9E9E9',
            margin: '16px 0',
          }}
        />
        <ColumnContainer>
          {paqueterias.map((el, idx) => (
            <>
              <Flex direction='row' gap='8px' width='100%'>
                <ContaierPaqueteria>
                  <CustomRadio
                    id={el.id}
                    onChange={() => handleOptionChange(el)}
                    name='paqueteria'
                    checked={el === valorEnvio}
                  />
                </ContaierPaqueteria>
                <Flex
                  direction='row'
                  width='100%'
                  gap='8px'
                  mobile='flex-direction: column;'
                >
                  <Flex direction='column' width='100%' gap='8px'>
                    <PaqueteriaName>{el.nombre}</PaqueteriaName>

                    <Service>{el.servicio}</Service>
                  </Flex>
                  <Flex direction='column' width='100%' gap='8px'>
                    <Price>{el.costo}</Price>
                    <Days>({el.dias_de_entrega} días hábiles)</Days>
                  </Flex>
                </Flex>
              </Flex>
            </>
          ))}
        </ColumnContainer>

        <ButtonContainer>
          <ButtonCancelar onClick={() => setShow(!show)}>
            Cancelar
          </ButtonCancelar>
          <ButtonConfirmar onClick={handleSeleccionar}>
            Confirmar
          </ButtonConfirmar>
        </ButtonContainer>
      </MainContainer>
    </ModalBackdrop>
  );
}
