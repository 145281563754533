import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 20px 54px 0px rgba(13, 53, 100, 0.15);
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Title = styled.h2`
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  color: #0d3564;
  margin: 0;
`;

export const Imagen = styled.img`
  width: 32px;
`;

export const InputRowContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  @media (max-width: 1439px) {
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InputLabel = styled.p`
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  color: #061222;
`;

export const InputDireccion = styled.input`
  display: flex;
  width: 100%;
  height: 48px;
  padding: 16px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #b9cad0;
  background-color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
`;
export const ButtonCointainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

export const ButtonCrear = styled.button`
  display: flex;
  width: 194px;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border-color: #0d3564;
  border-style: solid;
  background-color: #0d3564;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  cursor: pointer;
  &:disabled {
    background: rgb(229, 229, 229);
    color: black;
    border: 1px solid rgb(229, 229, 229);
    cursor: not-allowed;
  }

  @media (max-width: 1439px) {
    width: 100%;
  }
`;
