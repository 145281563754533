import { createSlice } from '@reduxjs/toolkit';

const initialState = JSON.parse(
	localStorage.getItem('redux_shoppingCart') || '[]'
);

export const loadState = () => {
	try {
		return JSON.parse(localStorage.getItem('redux_shoppingCart') || '[]');
	} catch (err) {
		return [];
	}
};

export const saveState = (state) => {
	const serializedState = JSON.stringify(state);
	localStorage.setItem('redux_shoppingCart', serializedState);
};

export const shoppingReducer = createSlice({
	name: 'shopping',
	initialState,
	reducers: {
		deleteShopElement: (state, action) => {
			state.splice(action.payload, 1);
		},
		deleteAllShopElements: (state) => {
			state.length = 0;
		},
		addShopElement: (state, action) => {
			let repeat = false;

			state.forEach((product) => {
				if (product.id_parte === action.payload.id_parte) repeat = true;
			});

			!repeat &&
				state.push({
					...action.payload,
					cuantity: 1,
					Precio_de_Venta: action.payload.Precio_de_Venta,
				});
		},
		cuantityModifyReducer: (state, action) => {
			const item = state[action.payload.index];

			if (
				item.existencias >= item.cuantity + action.payload.value &&
				item.cuantity + action.payload.value > 0
			)
				state[action.payload.index].cuantity += action.payload.value;
		},
		cuantityModifyReducerByPart: (state, action) => {
			for (let i = 0; i < state.length; i++) {
				console.log(state[i])
				console.log(state[i].id_parte)
				if (state[i].id_parte === action.payload.id_parte) {
					console.log('if 1')
					if (
						state[i].existencias >= state[i].cuantity &&
						state[i].cuantity > 0
					) {
						console.log('if 2')
						state[i].cuantity = action.payload.value;
					}
				}
			}
		},
	},
});

export const {
	addShopElement,
	deleteShopElement,
	cuantityModifyReducer,
	deleteAllShopElements,
	cuantityModifyReducerByPart
} = shoppingReducer.actions;

export default shoppingReducer.reducer;
